import { useTheme } from "vuetify";
import DefaultTheme from "Constants/DefaultTheme";

export const useThemeManager = () => {
  const themeStore = useThemeStore();
  const { customisation, darkMode } = storeToRefs(themeStore);

  const theme = useTheme();

  /*
  const themeVars = {
    accent: "#82B1FF",
    background: "#FFFFFF",
    'background-2': "#f2f3f8",
    blue: "#5d78ff",
    border: "#dbdbdb",
    dark: "#333333",
    error: "#fd397a",
    info: "#5d78ff",
    secondary: "#ffa709",
    success: "#0abb87",
    text: "#595d6e"
  }
  */

  const darkVars = {
    background: "#121212",
    "background-2": "#1F1F1F",
    menu: "#121212",
    "menu-dark": "#1F1F1F",
    text: "#FFFFFF",
    border: "#333333",
    "v-theme-surface": "#1a1a1a",
    "v-theme-on-surface": "#1a1a1a",
  };

  const colorsToStyle = (colors = {}) => {
    let style = "";
    Object.keys(colors).map((name) => {
      style += `--${name}: ${colors[name]};`;
    });
    return style;
  };

  const setVuetifyVars = (colors = {}) => {
    Object.keys(colors).map((color) => {
      theme.global.current.value.colors[color] = colors[color];
    });
  };

  const setBodyStyle = async (style) => {
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("inanimated");
    body.style = style || "";
    await nextTick();
    body.classList.remove("inanimated");
  };

  const changeFavIcon = (icon) => {
    const link = document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = icon;
    }
  };

  const setTheme = (colors = {}) => {
    theme.global.name.value = darkMode.value ? "dark" : "light";
    const fullColors = darkMode.value
      ? Object.assign({}, colors, darkVars)
      : colors;
    const style = colorsToStyle(fullColors);
    setVuetifyVars(colors);
    setBodyStyle(style);
    changeFavIcon(customisation.value.icon);
  };

  const isDefault = computed(() => {
    return $isEqual(customisation.value, DefaultTheme, ["domain"]);
  });

  return {
    colorsToStyle,
    setBodyStyle,
    darkVars,
    setTheme,
    isDefault,
  };
};
