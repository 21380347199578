import Emitter from "Plugins/mitt";
import i18n from "Plugins/i18n";
import { useNotification } from "@kyvg/vue3-notification";

export const $stopProp = ($event) => {
  $event.stopImmediatePropagation();
};

export const $gemit = (event, data) => {
  Emitter.emit(event, data);
};

export const $notice = (data) => {
  Emitter.emit("notice", data);
};

export const $confirm = (data) => {
  Emitter.emit("confirm", data);
};

export const $block = () => {
  Emitter.emit("blockUI");
};

export const $release = () => {
  Emitter.emit("releaseUI");
};

export const $notify = (data) => {
  const { notify } = useNotification();
  notify(data);
};

export const $clipboard = (text) => {
  const { notify } = useNotification();
  const { t } = i18n.global;
  navigator.clipboard.writeText(text);
  notify({
    group: "br",
    type: "success",
    text: t("copiedToClipboard"),
  });
};

export const $scrollToError = () => {
  setTimeout(() => {
    let error = document.querySelector(".v-field--error");
    if (error) {
      window.scroll({
        behavior: "smooth",
        left: 0,
        top: error.getBoundingClientRect().top - 50,
      });
    }
  }, 200);
};

export const $scrollTo = (el, offset = 100) => {
  setTimeout(() => {
    let element = document.querySelector(el);
    if (element) {
      window.scroll({
        behavior: "smooth",
        left: 0,
        top: element.getBoundingClientRect().top - offset,
      });
    }
  }, 200);
};

export const $top = async () => {
  await nextTick();
  window.scrollTo(0, 0);
};

export const $downloadBlob = (data) => {
  if (!window.navigator.msSaveOrOpenBlob) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      link.remove();
    }, 300);
  } else {
    const url = window.navigator.msSaveOrOpenBlob(new Blob([data]), name);
  }
};

export const $downloadCsv = (data, name) => {
  let csv = "postcode,specified-branch,automatic-branch\r\n";
  Object.keys(data.map).map((postcode) => {
    let row = data.map[postcode];
    csv =
      csv +
      postcode +
      "," +
      (row[0] ? row[0] : "") +
      "," +
      (row[1] ? row[1] : "") +
      "\r\n";
  });

  var link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("target", "_blank");
  link.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(csv),
  );
  link.setAttribute("download", "locations-" + name + "-mapping.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
