import AppConfig from "Constants/AppConfig";
import REQ from "Api/Requests";

import ExporterConfig from "Models/integrations/exporter/ExporterConfig";
import AutoplayConfig from "Models/integrations/autoplay/AutoplayConfig";
import OauthConfig from "Models/integrations/oauth/OauthConfig";
import Ga4Basic from "Models/integrations/ga/Ga4Basic";
import SlackConfig from "Models/integrations/slack/SlackConfig";
import HubspotConfig from "Models/integrations/hubspot/HubspotConfig";
import MicrosoftConfig from "Models/integrations/microsoft/MicrosoftConfig";
import MicrosoftAccount from "Models/integrations/microsoft/MicrosoftAccount";

export default {
  oauth: {
    async get() {
      try {
        const path = REQ.setPath("oauth2");
        const response = await REQ.get(path);
        return {
          auth: response.data.auth.map((a) =>
            Object.assign(new OauthConfig(), a),
          ),
          providers: response.data.providers,
        };
      } catch (err) {
        throw err;
      }
    },

    async getToken() {
      try {
        const path = REQ.setPath("user/me/expose");
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async share(share, uuid) {
      try {
        const path = REQ.setPath("oauth2/" + uuid);
        const response = await REQ.post(path, { shared: share ? "yes" : "no" });
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async delete(uuid, inUse) {
      try {
        const path = REQ.setPath("oauth2/" + uuid + (inUse ? "/" + inUse : ""));
        const response = await REQ.delete(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async getService(service, account) {
      try {
        const path = REQ.setPath("oauth2/" + service + "/" + account);
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  google: {
    async accounts() {
      try {
        const path = REQ.setPath("integration/google/accounts");
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  exporter: {
    async list() {
      try {
        const path = REQ.setPath("integration/exporter");
        const response = await REQ.get(path);
        return response.data.map((a) => Object.assign(new ExporterConfig(), a));
      } catch (err) {
        throw err;
      }
    },

    async get(uuid) {
      try {
        const path = REQ.setPath("integration/exporter/" + uuid);
        const response = await REQ.get(path);
        return Object.assign(new ExporterConfig(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async create(payload) {
      try {
        const path = REQ.setPath("integration/exporter");
        const response = await REQ.post(path, payload);
        return Object.assign(new ExporterConfig(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async edit(payload) {
      try {
        const path = REQ.setPath("integration/exporter/" + payload.uuid);
        const response = await REQ.post(path, payload);
        return Object.assign(new ExporterConfig(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async delete() {
      try {
        const path = REQ.setPath("integration/exporter");
        const response = await REQ.delete(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async tags() {
      try {
        const path = REQ.setPath("integration/tags?group=EXPORT");
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async generateKey(uuid) {
      try {
        const path = REQ.setPath("integration/exporter/" + uuid);
        const response = await REQ.patch(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  autoplay: {
    async list(account) {
      try {
        const path = REQ.setPath("account/" + account + "/autoplay");
        const response = await REQ.get(path);
        return response.data.map((a) => Object.assign(new AutoplayConfig(), a));
      } catch (err) {
        throw err;
      }
    },

    async get(account, did) {
      try {
        const path = REQ.setPath("account/" + account + "/autoplay/" + did);
        const response = await REQ.get(path);
        return Object.assign(new AutoplayConfig(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async create(account, payload) {
      try {
        const path = REQ.setPath("account/" + account + "/autoplay/");
        const response = await REQ.put(path, payload);
        return Object.assign(new AutoplayConfig(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async edit(account, payload) {
      try {
        const path = REQ.setPath(
          "account/" + account + "/autoplay/" + payload.did,
        );
        const response = await REQ.post(path, payload);
        return Object.assign(new AutoplayConfig(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async delete(account, did) {
      try {
        const path = REQ.setPath("account/" + account + "/autoplay/" + did);
        const response = await REQ.delete(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  ga4: {
    async get(account) {
      try {
        const path = REQ.setPath("account/" + account + "/ga4");
        const response = await REQ.get(path);
        return Object.assign(new Ga4Basic(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async edit(account, payload) {
      try {
        const path = REQ.setPath("account/" + account + "/ga4");
        const response = await REQ.post(path, payload);
        return Object.assign(new Ga4Basic(), response.data);
      } catch (err) {
        throw err;
      }
    },
  },

  slack: {
    async get(account) {
      try {
        const path = REQ.setPath("account/" + account + "/slack");
        const response = await REQ.get(path);
        return Object.assign(new SlackConfig(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async edit(account, payload) {
      try {
        const path = REQ.setPath("account/" + account + "/slack");
        const response = await REQ.post(path, payload);
        return Object.assign(new SlackConfig(), response.data);
      } catch (err) {
        throw err;
      }
    },
  },

  hubspot: {
    async get(account) {
      try {
        const path = REQ.setPath("integration/hubspot/" + account);
        const response = await REQ.get(path);
        return Object.assign(new HubspotConfig(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async create(account, payload) {
      try {
        const path = REQ.setPath("integration/hubspot/");
        const response = await REQ.put(path, payload);
        return Object.assign(new HubspotConfig(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async edit(account, payload) {
      try {
        const path = REQ.setPath("integration/hubspot/" + account);
        const response = await REQ.post(path, payload);
        return Object.assign(new HubspotConfig(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async delete(uuid) {
      try {
        const path = REQ.setPath("/integration/hubspot/" + uuid);
        const response = await REQ.delete(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  microsoft: {
    async list() {
      try {
        const path = REQ.setPath("integration/microsoft/");
        const response = await REQ.get(path);
        return response.data.map((a) =>
          Object.assign(new MicrosoftConfig(), a),
        );
      } catch (err) {
        throw err;
      }
    },

    async get(account) {
      try {
        const path = REQ.setPath("integration/microsoft/" + account);
        const response = await REQ.get(path);
        return Object.assign(new MicrosoftConfig(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async create(payload) {
      try {
        const path = REQ.setPath("integration/microsoft/");
        const response = await REQ.put(path, payload);
        return Object.assign(new MicrosoftConfig(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async edit(payload) {
      try {
        const path = REQ.setPath("integration/microsoft/" + payload.uuid);
        const response = await REQ.post(path, payload);
        return Object.assign(new MicrosoftConfig(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async delete(uuid) {
      try {
        const path = REQ.setPath("/integration/microsoft/" + uuid);
        const response = await REQ.delete(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async accounts() {
      try {
        const path = REQ.setPath("integration/microsoft/accounts");
        const response = await REQ.get(path);
        return response.data.map((a) =>
          Object.assign(new MicrosoftAccount(), a),
        );
      } catch (err) {
        throw err;
      }
    },

    async conversions(params) {
      try {
        const path = REQ.setPath("integration/microsoft/conversions/", params);
        const response = await REQ.get(path);
        return response.data.map((a) =>
          Object.assign(new MicrosoftAccount(), a),
        );
      } catch (err) {
        throw err;
      }
    },
  },
};
