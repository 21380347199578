import CallsHistory from "./CallsHistory";

export default [
  {
    path: "/calls",
    name: "calls",
    components: {
      mobile: () => import("Layouts/menus/mobile/Default"),
      sidebar: () => import("Layouts/menus/sidebar/Default"),
      header: () => import("Components/headers/Default.vue"),
      contentHeader: () =>
        import("Components/contentHeaders/FiltersDateRange.vue"),
      footer: () => import("Components/footers/Default.vue"),
      main: () => import("Views/calls/Calls.vue"),
    },
    meta: {
      filters: true,
      auth: true,
      editable: [],
      readable: [],
      guarded: false,
    },
  },
  CallsHistory,
];
