import GeoData from "Data/GeoData";
import DateFormat from "Assets/js//DateFormat";
import { getFormatDuration, secondsDiff } from "Assets/js/Durations";

const pad = (num) => {
  num = num.toString();
  while (num.length < 2) num = "0" + num;
  return num;
};

export const getDuration = (seconds, format = "hh:mm:ss") => {
  if (!seconds) return null;
  return getFormatDuration(seconds, format);
};

const getLocale = (short = false) => {
  const profileStore = useProfileStore();
  let locale = GeoData.countries[profileStore.user.country].lang;
  locale = locale ? locale.split(",")[0] : "en-AU";
  locale = locale.includes("-") ? locale : "en-AU";
  return !short ? locale : locale.slice(0, 2);
};

const getAppLocale = () => {
  return "en";
};

export const dateValid = (date) => {
  return !isNaN(new Date(date));
};

export const isPast = (date) => {
  return !date ? false : new Date(date) < new Date();
};

export const formatDate = (date, format = "YYYY-MM-DD") => {
  date = date ? new Date(date) : new Date();
  return DateFormat(date, format, { locale: getAppLocale() });
};

export const formatDateValid = (date, format = "YYYY-MM-DD") => {
  if (!date) return null;

  date = new Date(date);
  if (isNaN(new Date(date))) return null;
  return DateFormat(date, format, { locale: getAppLocale() });
};

export const formatDateLocale = (date, format = "YYYY-MM-DD", _locale) => {
  const locale = _locale || getLocale(true);
  return !date ? "" : DateFormat(new Date(date), format, { locale });
};

export const formatUnixDate = (date, format = "YYYY-MM-DD") => {
  return !date
    ? ""
    : DateFormat(new Date(date * 1000), format, { locale: getAppLocale() });
};

export const formatUnixDateLocale = (date, format = "YYYY-MM-DD") => {
  const locale = getLocale(true);
  return !date ? "" : DateFormat(new Date(date * 1000), format, { locale });
};

export const utcUnixTimezone = (date, timeZone, format = "YYYY-MM-DD") => {
  const locale = getLocale(true);
  return !date
    ? ""
    : DateFormat(new Date(date * 1000), format, { timeZone, locale });
};

export const formatTableTimestamp = (
  timestamp,
  timezone,
  format = "YYYY-MM-DD",
) => {
  timestamp = parseInt(timestamp);
  timestamp = timestamp * 1000;
  const locale = getLocale();
  return DateFormat(date, format, { locale });
};

export const utcToLocal = (date, format = "YYYY-MM-DD HH:mm") => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return !date
    ? ""
    : DateFormat(date, format, { timeZone, locale: getAppLocale() });
};

export const milliSecondsToDuration = (ms) => {
  if (!ms) return null;
  const secs = ms / 1000;
  const values = getFormatDuration(secs, "hh:mm:ss");
  if (secs < 3599) {
    return pad(values.minutes) + ":" + pad(values.seconds);
  }
  return (
    pad(values.hours) + ":" + pad(values.minutes) + ":" + pad(values.seconds)
  );
};

export const secondsToDuration = (secs) => {
  if (!secs) return null;
  const values = getFormatDuration(secs, "hh:mm:ss");
  if (secs < 3599) {
    return pad(values.minutes) + ":" + pad(values.seconds);
  }
  return (
    pad(values.hours) + ":" + pad(values.minutes) + ":" + pad(values.seconds)
  );
};

export const secondsToDurationText = (seconds) => {
  if (!seconds) return null;
  if (seconds < 59) {
    return Math.round(seconds) + " sec";
  }
  let values = getFormatDuration(seconds);
  if (!values) return null;
  if (seconds < 3500) {
    return values.minutes + "m " + values.seconds + "s";
  } else if (seconds >= 86400) {
    values = getFormatDuration(seconds, "dd:hh:mm:ss");
    return values
      ? values.days + "d " + values.hours + "h " + values.minutes + "m "
      : null;
  } else {
    return values.hours + "h " + values.minutes + "m ";
  }
};

export const humanizeStamp = (stamp) => {
  if (!stamp) return null;
  const gap = secondsDiff(stamp);
  const values = getFormatDuration(gap, "full");
  const { t } = useI18n();

  let segments = [];
  if (values.months > 0) {
    segments.push(values.months + " " + t("dates.months"));
  }
  if (values.weeks > 0) {
    segments.push(values.weeks + " " + t("dates.weeks"));
  }
  if (values.days > 0) {
    segments.push(values.days + " " + t("dates.days"));
  }
  if (values.hours > 0) {
    segments.push(values.hours + " " + t("dates.hours"));
  }
  if (values.minutes > 0) {
    segments.push(values.minutes + " " + t("dates.minutes"));
  }
  if (values.seconds > 0) {
    segments.push(values.seconds + " " + t("dates.seconds"));
  }
  if (segments.length > 1) {
    const last = segments.pop();
    return segments.join(",") + " and " + last;
  }
  return segments.join(",");
};

export const humanizeDuration = (values) => {
  if (!values) return null;
  const { t } = useI18n();

  let segments = [];
  if (values.months && values.months > 0) {
    segments.push(values.months + " " + t("dates.months"));
  }
  if (values.weeks && values.weeks > 0) {
    segments.push(values.weeks + " " + t("dates.weeks"));
  }
  if (values.days && values.days > 0) {
    segments.push(values.days + " " + t("dates.days"));
  }
  if (values.hours && values.hours > 0) {
    segments.push(values.hours + " " + t("dates.hours"));
  }
  if (values.minutes && values.minutes > 0) {
    segments.push(values.minutes + " " + t("dates.minutes"));
  }
  if (values.seconds && values.seconds > 0) {
    segments.push(values.seconds + " " + t("dates.seconds"));
  }
  if (segments.length > 1) {
    const last = segments.pop();
    return segments.join(",") + " and " + last;
  }
  return segments.join(",");
};

export const addDays = (date, days) => {
  const dateObj = new Date(date);
  dateObj.setDate(result.getDate() + days);
  return dateObj;
};

export const subDays = (date, days) => {
  const dateObj = new Date(date);
  dateObj.setDate(dateObj.getDate() - days);
  return dateObj;
};

export const addMonths = (date, months) => {
  const dateObj = new Date(date);
  dateObj.setMonth(dateObj.getMonth() + months);
  return dateObj;
};

export const subMonths = (date, months) => {
  const dateObj = new Date(date);
  dateObj.setMonth(dateObj.getMonth() - months);
  return dateObj;
};

export const addYears = (date, years) => {
  const dateObj = new Date(date);
  dateObj.setFullYear(dateObj.getFullYear() + years);
  return dateObj;
};

export const subYears = (date, years) => {
  const dateObj = new Date(date);
  dateObj.setFullYear(dateObj.getFullYear() - years);
  return dateObj;
};

export const startOfMonth = (date) => {
  const dateObj = new Date(date);
  return new Date(dateObj.getFullYear(), dateObj.getMonth(), 1);
};

export const endOfMonth = (date) => {
  const dateObj = new Date(date);
  return new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0);
};

export const startOfYear = (date) => {
  const dateObj = new Date(date);
  return new Date(dateObj.getFullYear(), 0, 1);
};

export const endOfYear = (date) => {
  const dateObj = new Date(date);
  return new Date(dateObj.getFullYear(), 11, 31);
};

export const toIsoDate = (date) => {
  return new Date(date).toISOString().slice(0, 10);
};

export const toDateLocale = (date) => {
  return new Date(date).toLocaleDateString();
};

export const toDateTimeLocale = (date) => {
  return new Date(date).toLocaleTimeString();
};

export const diffDays = (start, end) => {
  const startObj = new Date(start);
  const endObj = new Date(end);
  return Math.ceil(Math.abs(startObj - endObj) / (1000 * 60 * 60 * 24));
};

export const $months = () => {
  return [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];
};
