import Dashboard from "Router/includes/views/app/Dashboard";
import Dni from "Router/includes/views/app/integrations/dni";
import Calls from "Router/includes/views/app/calls";
import Settings from "Router/includes/views/app/settings";
import Messages from "Router/includes/views/app/messages";
import Outbound from "Router/includes/views/app/outbound";
import Reports from "Router/includes/views/app/reports";
import Users from "Router/includes/views/app/users";
import Billing from "Router/includes/views/app/billing";
import Clients from "Router/includes/views/app/clients";
import Integrations from "Router/includes/views/app/integrations";
import Powerups from "Router/includes/views/app/powerups";

export default {
  path: "/",
  redirect: "/dashboard",
  component: () => import("App/layouts/Main.vue"),
  children: [
    ...Calls,
    Dashboard,
    Dni,
    Settings,
    Messages,
    Users,
    Billing,
    Outbound,
    Reports,
    Clients,
    Integrations,
    Powerups,
  ],
};
