export default {
  path: "/reset-password",
  name: "auth-reset-password",
  components: {
    main: () => import("Views/auth/ResetPassword.vue"),
  },
  meta: {
    public: true,
    access: true,
  },
};
