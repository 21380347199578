export default class MicrosoftConfig {
  constructor(
    account,
    filters,
    msAccountId,
    msAccountName,
    msAccountNumber,
    msConversionId,
    msConversionName,
    msCustomerId,
    msUserId,
    oAuth,
    status,
    username,
    uuid,
    _created,
    _lastRes,
  ) {
    this.account = account;
    this.filters = filters;
    this.msAccountId = msAccountId;
    this.msAccountName = msAccountName;
    this.msAccountNumber = msAccountNumber;
    this.msConversionId = msConversionId;
    this.msConversionName = msConversionName;
    this.msCustomerId = msCustomerId;
    this.msUserId = msUserId;
    this.oAuth = oAuth;
    this.status = status;
    this.username = username;
    this.uuid = uuid;
    this._created = _created;
    this._lastRes = _lastRes;
  }
}
