export default class PortingItem {
  constructor(
    account,
    address,
    carrier,
    company,
    customer,
    id,
    invoice,
    message,
    name,
    numbers,
    phone,
    request,
    status,
    tax,
    title,
    type,
    user,
  ) {
    this.account = account;
    this.address = address;
    this.carrier = carrier;
    this.company = company;
    this.customer = customer;
    this.id = id;
    this.invoice = invoice;
    this.message = message;
    this.name = name;
    this.numbers = numbers || [];
    this.phone = phone;
    this.request = request;
    this.status = status;
    this.tax = tax;
    this.title = title;
    this.type = type;
    this.user = user;
  }
}
