<template>
  <div class="section-divider" :style="heightStyle" />
</template>

<script>
export default {
  props: ["height"],
  computed: {
    heightStyle() {
      return "height: " + (this.height || 1) + "px";
    },
  },
};
</script>
