<template>
  <div class="d-inline-flex align-center">
    <template v-if="details">
      <slot name="flag" :details="details">
        <img
          :src="details.flag"
          :alt="details.country.name"
          :content="details.country.name"
          class="rounded mr-1"
          width="20"
          height="20"
          v-tippy
          v-bind="$attrs"
        />
      </slot>
      <slot :details="details">
        <div class="pt-05">{{ details.national }}</div>
      </slot>
    </template>
    <template v-else>
      {{ props.num }}
    </template>
  </div>
</template>

<script setup>
import { AsYouType } from "libphonenumber-js";
import GeoData from "Data/GeoData";

const props = defineProps({
  num: {
    type: [Number, String],
    default: "",
  },
});

const details = computed(() => {
  if (!props.num) return null;

  const typed = new AsYouType();
  typed.reset();
  typed.input("+" + props.num.replace("+", ""));
  const parsed = typed.getNumber();
  const country = parsed ? GeoData.countries[parsed.country] : "";

  if (parsed && parsed.isValid()) {
    return {
      value: props.num,
      national: parsed.formatNational(),
      international: parsed.formatInternational(),
      country: country,
      flag: AppConfig.paths.flags + country.flag,
    };
  }
  return null;
});
</script>
