import { defineStore } from "pinia";

export const useFlowStore = defineStore("flow", {
  state: () => ({
    account: null,
    num: null,
    staticPosition: null,
    tooltips: true,
    autoPosition: true,
    lock: false,
    panelOpen: false,
    ivrs: {},
    history: [],
    errorItems: [],
  }),
});
