import AppConfig from "Constants/AppConfig";
import REQ from "Api/Requests";

export default {
  async list(params) {
    try {
      const path = REQ.setPath("web/all", params, ["accounts"]);
      const response = await REQ.get(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async get(id) {
    try {
      const path = REQ.setPath("web/" + id);
      const response = await REQ.get(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async add(payload) {
    try {
      const path = REQ.setPath("web");
      const response = await REQ.put(path, payload);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async edit(payload) {
    try {
      const path = REQ.setPath("web/" + payload.code);
      const response = await REQ.post(path, payload);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async delete(code) {
    try {
      const path = REQ.setPath("web/" + code);
      const response = await REQ.delete(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  pools: {
    async edit(payload, code) {
      try {
        const path = REQ.setPath("web/" + code + "/pool");
        const response = await REQ.post(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },
};
