export default class Locations {
  constructor(
    id,
    IVRattempts,
    IVRdigits,
    IVRmessage,
    account,
    action,
    geoLocation,
    map,
    mobileLocation,
    name,
    nextAction,
    nextActionId,
    postcodePrompt,
  ) {
    this.id = id;
    this.IVRattempts = IVRattempts;
    this.IVRdigits = IVRdigits;
    this.IVRmessage = IVRmessage;
    this.account = account;
    this.action = action;
    this.geoLocation = geoLocation;
    this.map = map;
    this.mobileLocation = mobileLocation;
    this.name = name;
    this.nextAction = nextAction;
    this.nextActionId = nextActionId;
    this.postcodePrompt = postcodePrompt;
  }
}
