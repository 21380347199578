import AppConfig from "Constants/AppConfig";
import REQ from "Api/Requests";

import Organization from "Models/clients/Organization";
import Client from "Models/clients/Client";
import Prospect from "Models/clients/Prospect";
import Invoice from "Models/clients/Invoice";

export default {
  async organizations(params) {
    try {
      const path = REQ.setPath("organisation", params);
      const response = await REQ.get(path);
      return response.data.map((a) => Object.assign(new Organization(), a));
    } catch (err) {
      throw err;
    }
  },

  async invoices(uuid, params) {
    try {
      const path = REQ.setPath("organisation/" + uuid + "/invoices", params);
      const response = await REQ.get(path);
      return response.data.map((a) => Object.assign(new Invoice(), a));
    } catch (err) {
      throw err;
    }
  },

  clients: {
    async list(uuid) {
      try {
        const path = REQ.setPath("organisation/" + uuid + "/client");
        const response = await REQ.get(path);
        return response.data.map((a) => Object.assign(new Client(), a));
      } catch (err) {
        throw err;
      }
    },

    async get(uuid, orgId) {
      try {
        const path = REQ.setPath("organisation/" + orgId + "/client/" + uuid);
        const response = await REQ.get(path);
        return Object.assign(new Client(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async edit(payload, orgId) {
      try {
        const path = REQ.setPath(
          "organisation/" + orgId + "/client/" + payload.uuid,
        );
        const response = await REQ.post(path, payload);
        return Object.assign(new Client(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async delete(orgId, uuid) {
      try {
        const path = REQ.setPath("organisation/" + orgId + "/client/" + uuid);
        const response = await REQ.delete(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  prospects: {
    async list(uuid) {
      try {
        const path = REQ.setPath("organisation/" + uuid + "/prospect");
        const response = await REQ.get(path);
        return response.data.map((a) => Object.assign(new Prospect(), a));
      } catch (err) {
        throw err;
      }
    },

    async get(uuid, orgId) {
      try {
        const path = REQ.setPath("organisation/" + orgId + "/prospect/" + uuid);
        const response = await REQ.get(path);
        return Object.assign(new Prospect(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async create(payload, orgId) {
      try {
        const path = REQ.setPath("organisation/" + orgId + "/prospect");
        const response = await REQ.put(path, payload);
        return Object.assign(new Prospect(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async edit(payload, orgId) {
      try {
        const path = REQ.setPath(
          "organisation/" + orgId + "/prospect/" + payload.uuid,
        );
        const response = await REQ.post(path, payload);
        return Object.assign(new Prospect(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async delete(orgId, uuid) {
      try {
        const path = REQ.setPath("organisation/" + orgId + "/prospect/" + uuid);
        const response = await REQ.delete(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async activate(orgId, uuid) {
      try {
        const path = REQ.setPath("organisation/" + orgId + "/prospect/" + uuid);
        const response = await REQ.patch(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  rates: {
    async list(uuid) {
      try {
        const path = REQ.setPath("client/" + uuid + "/rate");
        const response = await REQ.get(path);
        return response.data.map((a) => Object.assign(new Prospect(), a));
      } catch (err) {
        throw err;
      }
    },

    async create(payload, uuid) {
      try {
        const path = REQ.setPath("client/" + uuid + "/rate");
        const response = await REQ.put(path, payload);
        return Object.assign(new Prospect(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async delete(uuid, id) {
      try {
        const path = REQ.setPath("client/" + uuid + "/rate/" + id);
        const response = await REQ.delete(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },
};
