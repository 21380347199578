// Composables
import { createRouter, createWebHistory } from "vue-router";

import Core from "Router/includes/Core";
import Auth from "Router/includes/Auth";
import App from "Router/includes/App";

const routes = [Auth, App, ...Core];

const routeNames = [];
const AddNames = (route) => {
  if (route.name && !routeNames.includes(route.name)) {
    routeNames.push(route.name);
  }
  if (route.children) {
    route.children.map((child) => {
      if (child.name && !routeNames.includes(child.name)) {
        routeNames.push(child.name);
      }
      if (child.children) {
        AddNames(child);
      }
    });
  }
};
routes.map((route) => {
  AddNames(route);
});

const config = {
  history: createWebHistory(import.meta.env.BASE_URL),
  linkExactActiveClass: "menu-item--active",
  linkActiveClass: "menu-item--open",
  routes,
};

const router = createRouter(config);

import { setGuards } from "Router/guards";
setGuards(router, routeNames);

export default router;
