import AppConfig from "Constants/AppConfig";

export default {
  path: "/login",
  name: "auth-login",
  components: {
    main: () =>
      AppConfig.config.stepLogin
        ? import("Views/auth/2StepLogin.vue")
        : import("Views/auth/Login.vue"),
  },
  meta: {
    public: true,
    access: true,
  },
};
