import AppConfig from "Constants/AppConfig";
import REQ from "Api/Requests";

import NumberModel from "Models/Number";

export default {
  async getNumbers(params) {
    try {
      const path = REQ.setPath("number", params);
      const response = await REQ.get(path);
      return response.data.map((a) => Object.assign(new NumberModel(), a));
    } catch (err) {
      throw err;
    }
  },

  async getAccountsNumbers(params) {
    try {
      const path = REQ.setPath("number", params, ["accounts"]);
      const response = await REQ.get(path);
      return response.data.map((a) => Object.assign(new NumberModel(), a));
    } catch (err) {
      throw err;
    }
  },

  async getCustomAccountsNumbers(params) {
    try {
      const path = REQ.setPath("number", params);
      const response = await REQ.get(path);
      return response.data.map((a) => Object.assign(new NumberModel(), a));
    } catch (err) {
      throw err;
    }
  },
};
