import Messaging from "./Messaging";
import Locations from "./Locations";
import CallFlow from "./CallFlow";
import Routing from "./Routing";
import Details from "./Details";
import Dni from "./Dni";

export default {
  path: "/settings/wizard/:id",
  name: "settings-single-wizard",
  components: {
    mobile: () => import("Layouts/menus/mobile/Default"),
    sidebar: () => import("Layouts/menus/sidebar/Default"),
    header: () => import("Components/headers/Default.vue"),
    contentHeader: () => import("Components/contentHeaders/Default.vue"),
    footer: () => import("Components/footers/Default.vue"),
    main: () => import("Views/settings/Wizard.vue"),
  },
  children: [Messaging, Locations, CallFlow, Routing, Details, Dni],
  meta: {
    auth: true,
    editable: [],
    readable: ["admin", "super", "system", "support"],
    guarded: false,
  },
};
