export default class AutoplayConfig {
  constructor(
    CustomerCompany,
    DealershipId,
    LeadCampaignName,
    LeadNotes,
    LeadSource,
    LeadTitle,
    did,
    filter,
    yardRef,
  ) {
    this.CustomerCompany = CustomerCompany;
    this.DealershipId = DealershipId;
    this.LeadCampaignName = LeadCampaignName;
    this.LeadNotes = LeadNotes;
    this.LeadSource = LeadSource;
    this.LeadTitle = LeadTitle;
    this.did = did;
    this.filter = filter || { tags: [] };
    this.yardRef = yardRef;
  }
}
