import App from "./App.vue";
import { createApp } from "vue";
import { rollbarPlugin } from "./plugins/rollbar";

const app = createApp(App);

app.use(rollbarPlugin);

import { registerPlugins } from "Core/plugins";
registerPlugins(app);

import { registerComponents } from "Core/components";
registerComponents(app);

import { reigsterMethods } from "Core/methods";
reigsterMethods(app);

import { registerDirectives } from "Core/directives";
registerDirectives(app);

app.mount("#app");
