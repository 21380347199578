import Rollbar from "rollbar";
import RollBarConfig from "Constants/RollBarConfig";

function storageSnapshot() {
  var archive = {},
    keys = Object.keys(localStorage),
    i = 0,
    key,
    tmpVal;

  for (; (key = keys[i]); i++) {
    tmpVal = localStorage.getItem(key);
    try {
      archive[key] = JSON.parse(tmpVal);
    } catch (err) {
      archive[key] = `${tmpVal}`;
    }
  }

  return archive;
}

RollBarConfig.payload.first = storageSnapshot();
RollBarConfig.payload.last = null;

RollBarConfig.transform = function () {
  RollBarConfig.payload.last = storageSnapshot();
};

const rollbar = new Rollbar(RollBarConfig);

const rollbarPlugin = {
  install(app) {
    app.config.errorHandler = (error, vm, info) => {
      console.error(error);
      rollbar.error(error, { info });
    };
    app.config.warnHandler = (msg, instance, trace) => {
      console.log("avoiding warnHandler issue");
    };
    app.provide("rollbar", rollbar);
  },
};

export { rollbar, rollbarPlugin };
