import Axios from "axios";
import Nprogress from "nprogress";
import { parseErrors } from "Helpers/Api";

import { useAuthStore } from "@/stores/auth";
import { useFiltersStore } from "@/stores/filters";

const http = Axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
  },
});

const { setInternal } = useNavigationManager();

export default {
  isLogginOut: false,
  defaultSettings: {
    headers: {
      Authorization: null,
    },
  },
  settings: {
    headers: {
      Authorization: null,
    },
  },

  async setAuth() {
    const authStore = useAuthStore();
    const token = await authStore.getToken();
    this.settings = structuredClone(this.defaultSettings);
    this.settings.headers.Authorization = "Bearer " + token;

    const profileStore = useProfileStore();
    const adminStore = useAdminStore();
    const { isAdminSupport } = storeToRefs(profileStore);
    const { identity } = storeToRefs(adminStore);

    if (identity.value && isAdminSupport.value) {
      this.settings.headers["WildScope"] = btoa(
        identity.value.id + "-" + identity.value.account,
      );
    }
  },

  noCache() {
    this.settings.cache = false;
  },

  setFileHeaders() {
    this.settings.headers["Content-Type"] = "multipart/form-data";
  },

  setPath(path, params, filters) {
    const filtersStore = useFiltersStore();

    let query = {};

    if (filters) {
      query = filtersStore.setQueryParams(filters);
    }

    if (params) {
      query = Object.assign(query, params);
    }

    /*
    if(identity.value && isAdminSupport.value) {
      query['_wn'] = identity.value.id+'-'+identity.value.account
    }
    */

    return (
      path +
      (Object.keys(query).length === 0
        ? ""
        : "?" + new URLSearchParams(query).toString())
    );
  },

  parsePayload(payload) {
    const profileStore = useProfileStore();
    const adminStore = useAdminStore();
    const { isAdminSupport } = storeToRefs(profileStore);
    const { identity } = storeToRefs(adminStore);

    if (payload && identity.value && isAdminSupport.value) {
      payload["_wn"] = identity.value.id + "-" + identity.value.account;
    }
    return payload;
  },

  async openGet(path) {
    Nprogress.start();

    try {
      const response = await Axios.get(path);
      return response.data;
    } catch (err) {
      throw err;
    } finally {
      Nprogress.done();
    }
  },

  async publicGet(path) {
    Nprogress.start();

    try {
      const response = await http.get(path);
      return response.data;
    } catch (err) {
      throw parseErrors(err, false, false);
    } finally {
      Nprogress.done();
    }
  },

  async publicPost(path, payload) {
    Nprogress.start();

    try {
      const response = await http.post(path, payload);
      return response.data;
    } catch (err) {
      throw parseErrors(err);
    } finally {
      Nprogress.done();
    }
  },

  async publicPatch(path, payload) {
    Nprogress.start();

    try {
      const response = await http.patch(path, payload);
      return response.data;
    } catch (err) {
      throw parseErrors(err);
    } finally {
      Nprogress.done();
    }
  },

  async publicPut(path, payload) {
    Nprogress.start();

    try {
      const response = await http.put(path, payload);
      return response.data;
    } catch (err) {
      throw parseErrors(err);
    } finally {
      Nprogress.done();
    }
  },

  async publicDelete(path) {
    Nprogress.start();

    try {
      const response = await http.delete(path);
      return response.data;
    } catch (err) {
      throw parseErrors(err);
    } finally {
      Nprogress.done();
    }
  },

  async get(path, callBack, callBackData) {
    await this.setAuth();
    this.settings.onDownloadProgress = function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      if (callBack) {
        callBack(percentCompleted, callBackData);
      }
    };
    Nprogress.start();

    try {
      const response = await http.get(path, this.settings);
      setInternal();
      return response.data;
    } catch (err) {
      throw parseErrors(err);
    } finally {
      Nprogress.done();
    }
  },

  async post(path, payload, callBack, callBackData, controller) {
    await this.setAuth();
    this.settings.onUploadProgress = function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      if (callBack) {
        callBack(percentCompleted, callBackData);
      }
    };
    if (controller) {
      this.settings.signal = controller.signal;
    }
    Nprogress.start();

    //payload = this.parsePayload(payload)

    try {
      const response = await http.post(path, payload, this.settings);
      setInternal();
      return response.data;
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new Error("upload canceled");
      } else {
        throw parseErrors(err);
      }
    } finally {
      Nprogress.done();
    }
  },

  async patch(path, payload) {
    await this.setAuth();
    Nprogress.start();

    //payload = this.parsePayload(payload)

    try {
      const response = await http.patch(path, payload, this.settings);
      setInternal();
      return response.data || true;
    } catch (err) {
      throw parseErrors(err);
    } finally {
      Nprogress.done();
    }
  },

  async put(path, payload, callBack, callBackData, controller) {
    await this.setAuth();
    this.settings.onUploadProgress = function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      if (callBack) {
        callBack(percentCompleted, callBackData);
      }
    };
    if (controller) {
      this.settings.signal = controller.signal;
    }
    Nprogress.start();

    //payload = this.parsePayload(payload)

    try {
      const response = await http.put(path, payload, this.settings);
      setInternal();
      return response.data || true;
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new Error("upload canceled");
      } else {
        throw parseErrors(err);
      }
    } finally {
      Nprogress.done();
    }
  },

  async delete(path) {
    await this.setAuth();
    Nprogress.start();
    try {
      const response = await http.delete(path, this.settings);
      setInternal();
      return response.data || true;
    } catch (err) {
      throw parseErrors(err);
    } finally {
      Nprogress.done();
    }
  },

  async upload(path, payload, callBack, callBackData, controller) {
    let localSettings = JSON.parse(JSON.stringify(this.settings));
    localSettings.headers["Content-Type"] = "multipart/form-data";
    localSettings.onUploadProgress = function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      if (callBack) {
        callBack(percentCompleted, callBackData);
      }
    };
    localSettings["timeout"] = 60000;
    if (controller) {
      localSettings.controller = controller;
    }
    Nprogress.start();

    try {
      const response = await http.post(path, payload, localSettings);
      return response.data;
    } catch (err) {
      if (Axios.isCancel(err)) {
        throw new Error("upload canceled");
      } else {
        throw parseErrors(err);
      }
    } finally {
      Nprogress.done();
    }
  },

  async uploadS3(path, file, callBack, callBackData, controller) {
    let settings = {
      headers: {
        "Content-Type": file.type,
      },
      timeout: 60000,
      signal: controller.signal,
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        if (callBack) {
          callBack(percentCompleted, callBackData);
        }
      },
    };
    Nprogress.start();

    try {
      const response = await http.put(path, file, settings);
      return response;
    } catch (err) {
      if (err.code === "ERR_CANCELED") {
        throw {
          code: 499,
          msg: "Upload canceled",
          info: t("error_codes.UPL01"),
        };
      } else {
        throw parseErrors(err);
      }
    } finally {
      Nprogress.done();
    }
  },

  async downloadFile(path, name, auth) {
    if (auth) {
      await this.setAuth();
    }
    Nprogress.start();

    try {
      const settings = Object.assign(
        {},
        { responseType: "blob" },
        this.settings,
      );
      const response = await http.get(path, settings);
      setInternal();
      if (!window.navigator.msSaveOrOpenBlob) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      } else {
        const url = window.navigator.msSaveOrOpenBlob(
          new Blob([response.data]),
          name,
        );
      }
    } catch (err) {
      console.log(err);
      throw parseErrors(err);
    } finally {
      Nprogress.done();
    }
  },
};
