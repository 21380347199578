<template>
  <div
    class="component-loader"
    :style="{ width: props.width + 'px', height: props.height + 'px' }"
  >
    <div class="mr-3">{{ $t("loaders.loadingComponent") }}</div>
    <v-progress-circular
      indeterminate
      size="20"
      width="2"
      color="primary"
    ></v-progress-circular>
  </div>
</template>

<script setup>
const props = defineProps({
  width: String,
  height: String,
});
</script>
