export default class TrackingNumber {
  constructor(
    id,
    account,
    action,
    actionId,
    customGA,
    did,
    name,
    source,
    status,
    type,
  ) {
    this.id = id;
    this.account = account;
    this.action = action;
    this.actionId = actionId;
    this.customGA = customGA;
    this.did = did;
    this.name = name;
    this.source = source;
    this.status = status;
    this.type = type;
  }
}
