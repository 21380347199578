<template>
  <div class="app-card bg-background">
    <div
      class="app-card-header"
      :class="{ pointer: !props.disabled }"
      @click="toggle()"
    >
      <slot name="header"></slot>
    </div>

    <v-expand-transition>
      <div v-show="isOpen">
        <div
          class="nicescroll app-card-content"
          :class="props.contentClass"
        >
          <slot></slot>
        </div>

        <div
          v-if="props.footer"
          class="app-card-footer"
        >
          <slot name="footer"></slot>
        </div>
      </div>
    </v-expand-transition>

    <slot name="root"></slot>
  </div>
</template>

<script setup>
const props = defineProps({
  header: {
    type: [Boolean, String],
    default: false,
  },
  footer: {
    type: [Boolean, String],
    default: false,
  },
  contentClass: {
    type: String,
    default: "",
  },
  open: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["click", "update:open"]);

const isOpen = ref(props.open);

watch(props.open, (val) => {
  isOpen.value = val;
});

watch(isOpen, (val) => {
  emit("update:open", val);
});

const toggle = (val = null) => {
  if (props.disabled) return;
  isOpen.value = val !== null ? val : !isOpen.value;
};

defineExpose({
  toggle,
});
</script>
