<template>
  <div
    ref="cardEl"
    class="dragger"
    v-draggable="{
      handle: handleEl ? handleEl.$el : null,
      position: pos,
      onDrag: setPos,
    }"
  >
    <app-card
      class="drag-card elevated border border-border-dark animate__animated animate__fadeIn nodrag"
      :header="true"
      :footer="props.footer"
      :content-class="props.contentClass"
      v-bind="$attrs"
      @click="emit('click')"
    >
      <template #header>
        <div
          class="d-flex align-start justify-space-between border-b border-border-dark"
        >
          <v-btn
            variant="text"
            color="primary"
            ref="handleEl"
            class="mr-2 no-min-w px-0 grab border-border-dark border-r border-b rounded-te-0 rounded-bs-0 btn-move"
            iconb
            flat
          >
            <v-icon size="24">mdi-cursor-move</v-icon>
          </v-btn>

          <slot name="header"></slot>

          <close-btn
            size="24"
            color="error"
            class="border-border-dark border-l border-b rounded-ts-0 rounded-be-0 btn-close"
            @click="emit('close')"
          />
        </div>
      </template>

      <template #default>
        <slot></slot>
      </template>

      <template #footer>
        <slot name="footer"></slot>
      </template>

      <template #root>
        <slot name="root"></slot>
      </template>
    </app-card>
  </div>
</template>

<script setup>
import { vDraggable } from "@neodrag/vue";

const flowStore = useFlowStore();
const { staticPosition, lock } = storeToRefs(flowStore);

const props = defineProps({
  context: {
    type: String,
    default: "",
  },
  savePostion: {
    type: Boolean,
    default: false,
  },
  header: {
    type: [Boolean, String],
    default: false,
  },
  footer: {
    type: [Boolean, String],
    default: false,
  },
  contentClass: {
    type: String,
    default: "",
  },
  position: {
    type: Object,
    default: () => {
      return {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      };
    },
  },
});
const emit = defineEmits(["update:position", "center", "close", "click"]);

const handleEl = ref(null);

const cardEl = ref(null);

const pos = ref();
if (lock.value && staticPosition.value) {
  pos.value = $copy(toRaw(staticPosition.value));
} else {
  pos.value = $copy(toRaw(props.position));
}

watch(
  () => props.position,
  () => {
    pos.value =
      lock.value && staticPosition.value
        ? $copy(toRaw(staticPosition.value))
        : $copy(toRaw(props.position));
  },
  { deep: true },
);

watch(
  () => lock.value,
  () => {
    if (lock.value) {
      staticPosition.value = pos.value;
    }
  },
);

const setPos = (data) => {
  pos.value = {
    x: data.offsetX,
    y: data.offsetY,
  };
  if (lock.value) {
    staticPosition.value = pos.value;
  }
};
</script>
