import {
  cloneDeep,
  omit,
  isEqual,
  fromPairs,
  differenceWith,
  toPairs,
  merge,
} from "lodash";

export const $isEqual = (a, b, omitted = []) => {
  omitted = ["id", ...omitted];
  return isEqual(omit(a, omitted), omit(b, omitted));
};

export const $showObjDiff = (a, b) => {
  return fromPairs(differenceWith(toPairs(a), toPairs(b), isEqual));
};

export const $clone = (a) => {
  return cloneDeep(a);
};
export const $merge = (a, b) => {
  return merge(a, b);
};
