<template>
  <div v-if="modal">
    <v-dialog
      v-model="modal"
      content-class="no-border"
      :max-width="modalData.mw"
      min-width="300"
      persistent
      no-click-animation
    >
      <div>
        <div
          class="modal-content bg-background nicescroll"
          :class="'type-' + modalData.type"
        >
          <div class="modal-title" v-if="Boolean(modalData.title)">
            <div class="d-flex align-center w-100">
              <v-icon color="white" size="26" v-if="!modalData.noIcon">{{
                icon
              }}</v-icon>
              <div
                class="w-100"
                :class="{ 'ml-2': !modalData.noIcon }"
                v-html="modalData.title"
              ></div>
            </div>
          </div>
          <div class="modal-body nicescroll" v-html="modalData.text"></div>
          <div class="modal-footer" v-if="!modalData.nofooter">
            <div class="modal-actions border">
              <slot name="extra">
                <v-btn
                  v-if="modalData.extra"
                  variant="outlined"
                  class="mr-sm-6 mb-3 mb-sm-0"
                  flat
                  @click="extra()"
                  >{{ modalData.btnExtra }}</v-btn
                >
              </slot>
              <div :class="modalData.actionsPos + '-actions'">
                <slot name="cancel">
                  <v-btn
                    v-if="modalData.cancel"
                    variant="plain"
                    class="cancel-btn mr-6"
                    flat
                    @click="cancel()"
                    >{{ $capitalize(modalData.btnCancel) }}</v-btn
                  >
                </slot>
                <slot name="confirm">
                  <v-btn
                    :color="modalData.type"
                    autofocus
                    flat
                    @click="close()"
                    >{{ modalData.btnOk }}</v-btn
                  >
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script setup>
import Emitter from "Plugins/mitt";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

let modal = ref(false);
let modalData = ref(false);
let modalDefault = reactive({
  title: "",
  text: "",
  type: "info",
  noIcon: false,
  nofooter: false,
  actionsPos: "spaced",
  route: null,
  btnExtra: null,
  extra: null,
  btnOk: t("ok"),
  btnCancel: t("cancel"),
  ok: null,
  cancel: null,
  mw: 500,
});

const setData = (data) => {
  modalData.value = Object.assign({}, modalDefault, data);
  modal.value = true;
};

const close = () => {
  if (modalData.value.route && route.name != modalData.value.route.name) {
    router.push(modalData.value.route);
  } else if (typeof modalData.value.ok == "function") {
    modalData.value.ok();
  }
  modal.value = false;
};

const cancel = () => {
  if (typeof modalData.value.cancel == "function") {
    modalData.value.cancel();
  }
  modal.value = false;
};

const extra = () => {
  if (typeof modalData.value.extra == "function") {
    modalData.value.extra();
  }
  modal.value = false;
};

const icon = computed(() => {
  let icon = "mdi-information";
  switch (modalData.value.type) {
    case "info":
      icon = "mdi-information";
      break;
    case "warning":
      icon = "mdi-alert";
      break;
    case "error":
      icon = "mdi-alert-circle";
      break;
    case "success":
      icon = "mdi-checkbox-marked-circle";
      break;
  }
  return icon;
});

onMounted(() => {
  Emitter.on("notice", setData);
});

onBeforeUnmount(() => {
  Emitter.off("notice", setData);
});
</script>
