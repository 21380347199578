import REQ from "Api/Requests";

import Biller from "Models/billing/Biller";
import PaymentBiller from "Models/billing/PaymentBiller";

export default {
  async list(params) {
    try {
      const path = REQ.setPath("billing/", params);
      const response = await REQ.get(path);
      return response.data.map((a) => Object.assign(new Biller(), a));
    } catch (err) {
      throw err;
    }
  },

  async billers(params) {
    try {
      const path = REQ.setPath("biller", params);
      const response = await REQ.get(path);
      return response.data.map((a) => Object.assign(new PaymentBiller(), a));
    } catch (err) {
      throw err;
    }
  },

  async invoices(code, params) {
    try {
      const path = REQ.setPath("biller/" + code + "/invoice", params);
      const response = await REQ.get(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async invoice(code, XeroInvoice) {
    try {
      const path = REQ.setPath(
        "biller/" + code + "/invoice/" + XeroInvoice + "/xeroUrl",
      );
      const response = await REQ.get(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  customisation: {
    async get(uid) {
      try {
        const path = REQ.setPath("billing/" + uid + "/customise");
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async getSettings(uid, type) {
      try {
        const path = REQ.setPath("billing/" + uid + "/customise/" + type);
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async edit(payload, uid, type) {
      try {
        const path = REQ.setPath("billing/" + uid + "/customise/" + type);
        const response = await REQ.post(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async preview(payload, type) {
      try {
        const path = REQ.setPath("billing/preview/customise/" + type);
        const response = await REQ.post(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async getTheme(domain) {
      if (domain === "localhost") return;
      try {
        const response = await REQ.publicGet(
          "https://s3-ap-southeast-2.amazonaws.com/trkcall.com/templates/" +
            domain +
            ".json",
        );
        return response && response.v3 ? response.v3 : {};
      } catch (err) {
        throw err;
      }
    },
  },

  stripe: {
    async payments(code, params) {
      try {
        const path = REQ.setPath("biller/" + code + "/payment", params);
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async editPayment(payload, uid) {
      try {
        const path = REQ.setPath("billing/" + uid + "/payment/");
        const response = await REQ.patch(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async addPayment(token, uid) {
      try {
        const payload = { token: token.id, defaultMonthly: "yes" };
        const path = REQ.setPath("billing/" + uid + "/payment/");
        const response = await REQ.put(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async recharge(payload, uid) {
      try {
        const path = REQ.setPath("biller/" + uid + "/payment/recharge/");
        const response = await REQ.put(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },
};
