export default {
  path: "/billing/payments/:id",
  name: "billing-biller-payments",
  components: {
    mobile: () => import("Layouts/menus/mobile/Default"),
    sidebar: () => import("Layouts/menus/sidebar/Default"),
    header: () => import("Components/headers/Default.vue"),
    contentHeader: () => import("Components/contentHeaders/Default.vue"),
    footer: () => import("Components/footers/Default.vue"),
    main: () => import("Views/billing/BillerPayments.vue"),
  },
  meta: {
    auth: true,
    editable: [],
    readable: ["billing", "admin", "super", "system", "support"],
    guarded: false,
  },
};
