export const light = {
  dark: false,
  colors: {
    background: "#FFFFFF",
    surface: "#FFFFFF",
    "surface-variant": "#595d6e",
    "on-surface-variant": "#EEEEEE",
    text: "#505050",
    yellow: "#ffd325",
    green: "#0abb87",
    blue: "#5d78ff",
    error: "#fd397a",
    info: "#5d78ff",
    success: "#0abb87",
    warning: "#ffb822",
    grey: "#a0a0a0",
    orange: "#ffb822",
    red: "#fd397a",
    dark: "#333333",
    white: "#ffffff",
    smoke: "#fbfbfb",
    border: "#dbdbdb",
    accent: "#82B1FF",
    primary: "#5d78ff",
    secondary: "#ffa709",
  },
  variables: {
    "activated-opacity": 0.1,
  },
};

export const dark = {
  dark: true,
  colors: {
    background: "#121212",
    surface: "#121212",
    "surface-variant": "#EEEEEE",
    "on-surface-variant": "#595d6e",
    text: "#505050",
    yellow: "#ffd325",
    green: "#0abb87",
    blue: "#5d78ff",
    error: "#dc3545",
    info: "#5d78ff",
    success: "#0abb87",
    warning: "#ffb822",
    grey: "#a0a0a0",
    orange: "#ffb822",
    red: "#dc3545",
    dark: "#333333",
    white: "#ffffff",
    smoke: "#fbfbfb",
    border: "#dbdbdb",
    accent: "#82B1FF",
    primary: "#5d78ff",
    secondary: "#ffa709",
  },
  variables: {
    "activated-opacity": 0.1,
  },
};
