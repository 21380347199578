export default {
  path: "/index/outbound",
  name: "overview",
  components: {
    mobile: () => import("Layouts/menus/mobile/Default"),
    sidebar: () => import("Layouts/menus/sidebar/Default"),
    header: () => import("Components/headers/Default.vue"),
    contentHeader: () => import("Components/contentHeaders/Dates.vue"),
    footer: () => import("Components/footers/Default.vue"),
    main: () => import("Views/outbound/Overview.vue"),
  },
  meta: {
    auth: true,
    editable: [],
    readable: [],
    guarded: false,
  },
};
