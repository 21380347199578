import Me from "./Me";
import Addressbook from "./Addressbook";
import Reports from "./Reports";
import Logins from "./Logins";
import List from "./List";

export default {
  path: "/users",
  name: "users",
  redirect: "/users/list",
  children: [Me, Addressbook, Reports, Logins, List],
};
