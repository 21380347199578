import { nanoid } from "nanoid";

import ContactNumber from "./ContactNumber";

export default class contact {
  constructor(uuid, account, firstName, lastName, numbers) {
    this.uuid = uuid || nanoid();
    this.account = account || "";
    this.firstName = firstName || "";
    this.lastName = lastName || "";
    this.numbers = numbers || [];
  }

  getFullName() {
    return this.firstName + (this.lastName ? " " + this.lastName : "");
  }

  getNumbers() {
    return this.numbers.map((a) => Object.assin(new ContactNumber(), a));
  }
}
