import AppSectionLoader from "App/components/common/AppSectionLoader";
import AppCard from "App/components/common/cards/AppCard";
import DragCard from "App/components/common/cards/DragCard";
import ToggleCard from "App/components/common/cards/ToggleCard";
import Divider from "App/components/common/Divider";
import TitleLine from "Components/common/TitleLine";
import InfoTooltip from "Components/common/InfoTooltip";
import Label from "Fields/Label";
import Confirm from "Components/common/Confirm";
import ExpansionPanel from "Components/common/ExpansionPanel";
import ComponentLoader from "Components/common/ComponentLoader";
import Ribbon from "Components/common/Ribbon";
import CloseBtn from "Components/common/CloseBtn";
import PhoneNumber from "Components/common/display/PhoneNumber";
import PhoneAutoFormat from "Fields/PhoneAutoFormat";
import AutocompleteSearch from "Fields/AutocompleteSearch";
import SingleAccountSelect from "Fields/pickers/SingleAccountSelect";
import DidSelect from "Fields/pickers/DidSelect";
import SingleList from "Components/common/SingleList";
import SingleVirtualList from "Components/common/SingleVirtualList";
import Money from "Components/common/display/Money";
import Search from "Fields/Search";
import InlineSvg from "vue-inline-svg";

export function registerComponents(app) {
  app
    .component("appSectionLoader", AppSectionLoader)
    .component("appCard", AppCard)
    .component("dragCard", DragCard)
    .component("toggleCard", ToggleCard)
    .component("divider", Divider)
    .component("titleLine", TitleLine)
    .component("InfoTooltip", InfoTooltip)
    .component("fieldLabel", Label)
    .component("confirm", Confirm)
    .component("expansionPanel", ExpansionPanel)
    .component("componentLoader", ComponentLoader)
    .component("inline-svg", InlineSvg)
    .component("ribbon", Ribbon)
    .component("closeBtn", CloseBtn)
    .component("PhoneNumber", PhoneNumber)
    .component("PhoneField", PhoneAutoFormat)
    .component("SelectSearch", AutocompleteSearch)
    .component("SingleAccountSelect", SingleAccountSelect)
    .component("SingleList", SingleList)
    .component("SingleVirtualList", SingleVirtualList)
    .component("DidSelect", DidSelect)
    .component("Search", Search)
    .component("Money", Money);
}
