<template>
  <div></div>
</template>

<script setup>
const themeStore = useThemeStore();
const { customisation, darkMode } = storeToRefs(themeStore);
const { setTheme } = useThemeManager();
import { useHead } from "@unhead/vue";
import Colors from "Data/colors";

if (AppConfig.debug) {
  console.log("Theme", customisation.value);
}

const cssFile = computed(() => {
  return {
    href: customisation.value.cssFile,
    rel: "stylesheet",
  };
});

const cssStyle = computed(() => {
  let style = customisation.value.style || "";
  return style;
});

const themeHead = useHead({
  link: cssFile,
  style: cssStyle,
  title: customisation.value.title,
});

watch(
  () => darkMode.value,
  () => {
    setTheme(customisation.value.colors);
  }
);

watch(
  () => customisation.value,
  () => {
    themeHead.patch({
      link: cssFile,
      style: cssStyle,
      title: customisation.value.title,
    });
  },
  { deep: true }
);

const init = async () => {
  await themeStore.getTheme();
  await nextTick();
  setTheme(customisation.value.colors);
  return true;
};

defineExpose({
  init,
});
</script>
