import AppConfig from "Constants/AppConfig";
import REQ from "Api/Requests";

export default {
  async clientList() {
    try {
      const response = await REQ.get("client/list/?order=name");
      return response.data;
    } catch (err) {
      throw err;
    }
  },
};
