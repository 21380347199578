export default class Account {
  constructor(
    id,
    name,
    status,
    plan,
    subLevel,
    father,
    billerCode,
    type,
    fatherName,
  ) {
    this.id = id;
    this.name = name;
    this.status = status;
    this.plan = plan;
    this.subLevel = subLevel;
    this.father = father;
    this.billerCode = billerCode;
    this.type = type;
    this.fatherName = fatherName;
  }
}
