export default class BulkReport {
  constructor(
    uuid,
    account,
    status,
    name,
    optOutId,
    remoteId,
    scheduled,
    sender,
    content,
    concatenation,
    inList,
    sent,
    delivered,
    blocked,
    processed,
    replied,
    userName,
    list,
    charges,
  ) {
    this.uuid = uuid;
    this.account = account;
    this.status = status;
    this.name = name;
    this.optOutId = optOutId;
    this.remoteId = remoteId;
    this.scheduled = scheduled;
    this.sender = sender;
    this.content = content;
    this.concatenation = concatenation;
    this.inList = inList;
    this.sent = sent;
    this.delivered = delivered;
    this.blocked = blocked;
    this.processed = processed;
    this.replied = replied;
    this.userName = userName;
    this.list = list;
    this.charges = charges;
  }
}
