export default class Num {
  constructor(
    account,
    action,
    actionId,
    activationDate,
    area,
    cancelDate,
    country,
    did,
    name,
    source,
    status,
    type,
    extra,
  ) {
    this.account = account;
    this.action = action || "hangup";
    this.actionId = actionId || 0;
    this.activationDate = activationDate;
    this.area = area;
    this.cancelDate = cancelDate;
    this.country = country;
    this.did = did || "";
    this.name = name || "";
    this.source = source || "";
    this.status = status;
    this.type = type || "";
    this.extra = extra || {};
  }
}
