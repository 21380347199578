let referrer = "";

export const useNavigationManager = () => {
  const setReferrer = (val) => {
    referrer = val;
  };

  const getReferrer = () => {
    return referrer;
  };

  const isInternalLink = () => {
    const newReferrer = document.referrer ? new URL(document.referrer) : null;
    const newReferrerOrigin = newReferrer ? newReferrer.origin : null;
    const origin = window.location.origin;
    return newReferrerOrigin == origin || referrer == origin;
  };

  const setInternal = () => {
    if (!isInternalLink()) {
      setReferrer(window.location.origin);
    }
  };

  return {
    setReferrer,
    getReferrer,
    isInternalLink,
    setInternal,
  };
};
