import { defineStore } from "pinia";

import { useSessionStore } from "./session";

const buildQueryParams = (state, data, fields) => {
  const sessionStore = useSessionStore();

  let query = {};

  fields.map((field) => {
    switch (field) {
      case "date":
        query.datefrom = state.dateStart + "T00:00:00";
        query.dateto = state.dateEnd + "T23:59:59";
        query.dateview = state.dateView;
        query.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; //@TODO getTimezone from profile settings
        break;
      case "accounts":
        if (sessionStore.selectedAccounts.length) {
          query.account = sessionStore.selectedAccounts.map((a) =>
            typeof a == "object" ? a.id : a,
          );
        }
        break;
      case "filters":
        if (data.trackingNumber && data.trackingNumber.length) {
          query.trackingNumber = data.trackingNumber;
        }
        if (data.trackingSource && data.trackingSource.length) {
          query.trackingSource = data.trackingSource;
        }
        if (data.durationMin) {
          query.durationMin = data.durationMin;
        }
        if (data.durationMax) {
          query.durationMax = data.durationMax;
        }
        if (data.status && data.status.length) {
          query.status = data.status;
        }
        break;
      default:
        if (data[field] !== null && typeof data[field] !== "undefined") {
          query[field] = data[field];
        }
    }
  });

  return query;
};

export const useFiltersStore = defineStore("filters", {
  state: () => ({
    filters: [
      "trackingNumber",
      "trackingSource",
      "durationMin",
      "durationMax",
      "status",
    ],
    dateStart: formatDate(subDays(new Date(), 7), "YYYY-MM-DD"),
    dateEnd: formatDate(new Date(), "YYYY-MM-DD"),
    dateLabel: null,
    dateView: "days", //['hours','weekdays','days','months']*
    timezone: null,
    trackingNumber: null,
    trackingSource: null,
    durationMin: null,
    durationMax: null,
    status: [],
    activeFilters: [],
    activeFilterParams: {},
    trigger: 1,
  }),
  getters: {
    dateRange() {
      return {
        start: this.dateStart + "T00:00:00",
        end: this.dateEnd + "T23:59:59",
        label: this.dateLabel,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
    },
    humanRange() {
      return {
        from: new Date(this.dateStart).toLocaleDateString(),
        to: new Date(this.dateEnd).toLocaleDateString(),
      };
    },
    filterParams() {
      let params = {};
      this.activeFilters.map((filter) => {
        params[filter] = this[filter];
      });
      return params;
    },
    filtersChanged() {
      return (
        JSON.stringify(this.filterParams) !=
        JSON.stringify(this.activeFilterParams)
      );
    },
    setQueryParams(state) {
      return (fields) =>
        buildQueryParams(state, state.activeFilterParams, fields);
    },
  },
  actions: {
    setFilterParams() {
      let params = {};
      this.activeFilters.map((filter) => {
        params[filter] = this[filter];
      });
      this.activeFilterParams = params;
      this.trigger = this.trigger + 1;
    },
    cancelFilterParams() {
      if (!this.activeFilterParams) return;

      this.activeFilters = Object.keys(this.activeFilterParams);
      this.activeFilters.map((filter) => {
        this[filter] = this.activeFilterParams[filter];
      });
      this.trigger = this.trigger + 1;
    },
    removeFilters(filters) {
      this.activeFilters = this.activeFilters.filter(
        (a) => !filters.includes(a),
      );
      filters.map((filter) => {
        if (this[filter]) {
          this[filter] = null;
        }
      });
      this.setFilterParams();
    },
    clearFilters() {
      this.removeFilters(this.filters);
    },
    checkAccountBasedFilters() {
      const sessionStore = useSessionStore();

      if (!sessionStore.selectedAccounts.length) return;

      if (this.trackingNumber) {
        const dids = sessionStore.activeNumbers?.map((a) => a.did);
        if (!dids || !dids.includes(this.trackingNumber)) {
          this.trackingNumber = null;
        }
      }

      if (this.trackingSource) {
        const sources = sessionStore.activeNumbers?.map((a) => a.source);
        this.trackingSource = sources
          ? this.trackingSource.filter((a) => sources.includes(a))
          : null;
      }

      this.setFilterParams();
    },
    reset() {
      this.trackingNumber = null;
      this.trackingSource = null;
      this.durationMin = null;
      this.durationMax = null;
      this.status = [];
      this.activeFilters = [];
      this.activeFilterParams = {};
      this.dateStart = formatDate(subDays(new Date(), 7), "YYYY-MM-DD");
      this.dateEnd = formatDate(new Date(), "YYYY-MM-DD");
      this.trigger = 1;
    },
  },
  persist: true,
});
