<template>
  <v-btn
    :class="{ visible: visible }"
    class="back-to-top shadow-spread opacity-hover"
    icon="mdi-arrow-up"
    rounded="default"
    density="default"
    size="x-small"
    color="primary"
    squared
    @click="goUp()"
  ></v-btn>
</template>

<script>
export default {
  data() {
    return {
      windowTop: window.scrollY,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll(e) {
      this.windowTop = window.scrollY;
    },
    goUp() {
      window.scroll({
        behavior: "smooth",
        left: 0,
        top: 0,
      });
    },
  },
  computed: {
    visible() {
      return this.windowTop > 600;
    },
  },
};
</script>
