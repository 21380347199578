export default class Invoice {
  constructor(uuid, clientId, currency, email, name, status, total) {
    this.uuid = uuid;
    this.clientId = clientId;
    this.currency = currency;
    this.email = email;
    this.name = name;
    this.status = status;
    this.total = total;
  }
}
