export default {
  path: "/calls/outbound",
  name: "outbound-calls",
  components: {
    mobile: () => import("Layouts/menus/mobile/Default"),
    sidebar: () => import("Layouts/menus/sidebar/Default"),
    header: () => import("Components/headers/Default.vue"),
    contentHeader: () =>
      import("Components/contentHeaders/FiltersDateRange.vue"),
    footer: () => import("Components/footers/Default.vue"),
    main: () => import("Views/outbound/OutboundCalls.vue"),
  },
  meta: {
    filters: true,
    auth: true,
    editable: [],
    readable: [],
    guarded: false,
  },
};
