import ForgotPassword from "Router/includes/views/auth/ForgotPassword";
import ResetPassword from "Router/includes/views/auth/ResetPassword";
import Login from "Router/includes/views/auth/Login";
import Signup from "Router/includes/views/auth/Signup";

export default {
  path: "/",
  redirect: "/login",
  component: () => import("App/layouts/Auth.vue"),
  children: [ForgotPassword, ResetPassword, Login, Signup],
};
