<template>
  <span class="fs-14 fw-600">
    {{ moneyFormat(props.amount, false) }}
    <span class="ml-1">{{ GeoData.currencies[props.currency].symbol }}</span>
  </span>
</template>

<script setup>
import GeoData from "Data/GeoData";

const props = defineProps({
  amount: {
    type: [String, Number],
    default: 0,
  },
  currency: {
    type: String,
    default: "AUD",
  },
});
</script>
