export default class Msg {
  constructor(account, content, did, direction, msisdn, stamp, userName) {
    this.account = account;
    this.content = content;
    this.did = did;
    this.direction = direction;
    this.msisdn = msisdn;
    this.stamp = stamp;
    this.userName = userName;
  }
}
