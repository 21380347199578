export default {
  path: "/forgot-password",
  name: "auth-forgot-password",
  components: {
    main: () => import("Views/auth/ForgotPassword.vue"),
  },
  meta: {
    public: true,
    access: true,
  },
};
