import { defineStore } from "pinia";
import Languages from "Data/languages";

import Account from "Models/Account";

export const useSessionStore = defineStore("session", {
  state: () => ({
    language: Languages[0],
    accounts: [],
    selectedAccounts: [],
    numbers: [],
    tags: {},
    contacts: [],
  }),
  getters: {
    activeAccounts() {
      return this.accounts
        .filter((a) => a.status == "active")
        .map((a) => Object.assign(new Account(), a))
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
    },
    accountNumbers() {
      let accounts = this.selectedAccounts.length
        ? this.selectedAccounts.filter((a) =>
            Boolean(this.activeAccounts.find((b) => b.id == a)),
          )
        : this.activeAccounts.map((a) => a.id);
      return this.numbers.filter((a) => accounts.includes(a.account));
    },
    singleAccountNumbers: (state) => {
      return (accountId) => state.numbers.filter((a) => a.account == accountId);
    },
    activeAccountsTree() {
      const active = this.accounts
        .filter((a) => a.status == "active")
        .map((a) => Object.assign(new Account(), a))
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      return $listToTree(active, "id", "father");
    },
    isFatherAccount(state) {
      return (id) =>
        Boolean(
          state.accounts
            .filter((a) => a.status == "active")
            .find((a) => a.father == id),
        );
    },
    singleAccount(state) {
      return (accountId) => state.accounts.find((a) => a.id == accountId);
    },
    accountChildren(state) {
      return (accountId) =>
        state.accounts.filter((a) => a.father && a.father == accountId);
    },
    accountAndChildren(state) {
      return (accountId) =>
        [
          state.accounts.find((a) => a.id == accountId),
          ...state.accounts.filter((a) => a.father && a.father == accountId),
        ].filter(Boolean);
    },
  },
  actions: {
    async getAccounts() {
      try {
        const response = await API.Accounts.getAccounts();
        this.accounts = response;
      } catch (err) {}
    },
    async getNumbers() {
      try {
        const response = await API.Numbers.getNumbers();
        this.numbers = response;
      } catch (err) {}
    },
    async getTags() {
      try {
        const response = await API.Call.getTags();
        this.tags = response;
      } catch (err) {}
    },
    async getContacts() {
      try {
        const response = await API.Addressbook.list();
        this.contacts = response.sort((a, b) => {
          const aVal = $concatVals([a.firstName, a.lastName]);
          const bVal = $concatVals([b.firstName, b.lastName]);
          return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
        });
      } catch (err) {}
    },
  },
  persist: true,
});
