export default class Call {
  constructor(
    DTMF,
    DTMFname,
    IVR,
    account,
    accountName,
    area,
    audio,
    audioId,
    caller,
    country,
    dateStartGMT,
    dateStartLocal,
    dateStartUser,
    dateStopGMT,
    dateStopLocal,
    dateStopUser,
    direction,
    duration,
    firstTimeCaller,
    id,
    intelligence,
    localTimezone,
    location,
    network,
    notes,
    score,
    state,
    status,
    tag,
    tags,
    talkTime,
    target,
    timestamp,
    trackingName,
    trackingNumber,
    trackingSource,
    userTimezone,
    uuid,
    web,
  ) {
    this.id = id;
    this.uuid = uuid;
    this.DTMF = DTMF;
    this.DTMFname = DTMFname;
    this.IVR = IVR;
    this.account = account;
    this.accountName = accountName;
    this.area = area;
    this.audio = audio;
    this.audioId = audioId;
    this.caller = caller;
    this.country = country;
    this.dateStartGMT = dateStartGMT;
    this.dateStartLocal = dateStartLocal;
    this.dateStartUser = dateStartUser;
    this.dateStopGMT = dateStopGMT;
    this.dateStopLocal = dateStopLocal;
    this.dateStopUser = dateStopUser;
    this.direction = direction;
    this.duration = duration;
    this.firstTimeCaller = firstTimeCaller;
    this.intelligence = intelligence;
    this.localTimezone = localTimezone;
    this.location = location;
    this.network = network;
    this.notes = notes;
    this.score = score;
    this.state = state;
    this.status = status;
    this.tag = tag;
    this.tags = tags;
    this.talkTime = talkTime;
    this.target = target;
    this.timestamp = timestamp;
    this.trackingName = trackingName;
    this.trackingNumber = trackingNumber;
    this.trackingSource = trackingSource;
    this.userTimezone = userTimezone;
    this.web = web;
  }
}
