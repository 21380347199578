import vuetify from "Plugins/vuetify";
import pinia from "@/stores";
import router from "@/router";
import i18n from "Plugins/i18n";
import Notifications from "@kyvg/vue3-notification";
import Nprogress from "nprogress";
import VueDragScroller from "vue-drag-scroller";
import { createHead } from "@unhead/vue";

const head = createHead();

Nprogress.configure({ showSpinner: false });

import VueTippy from "vue-tippy";
const tippyConfig = {
  directive: "tippy", // => v-tippy
  component: "tippy", // => <tippy/>
  componentSingleton: "tippy-singleton",
  defaultProps: {
    theme: "light",
    touch: "hold",
    arrow: true,
  },
  onShow: (options) => {
    return !!options.props.content;
  },
};

export function registerPlugins(app) {
  app
    .use(pinia)
    .use(vuetify)
    .use(router)
    .use(i18n)
    .use(Notifications)
    .use(VueTippy, tippyConfig)
    .use(VueDragScroller)
    .use(head);
}
