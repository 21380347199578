import Auth from "./includes/Auth";
import Users from "./includes/Users";
import Accounts from "./includes/Accounts";
import Numbers from "./includes/Numbers";
import Dashboard from "./includes/Dashboard";
import Reports from "./includes/Reports";
import Call from "./includes/Call";
import Settings from "./includes/Settings";
import Addressbook from "./includes/Addressbook";
import Billing from "./includes/Billing";
import Admin from "./includes/Admin";
import Msgs from "./includes/Msgs";
import Dni from "./includes/Dni";
import Clients from "./includes/Clients";
import Powerups from "./includes/Powerups";
import Integrations from "./includes/Integrations";

export default {
  Auth,
  Users,
  Accounts,
  Numbers,
  Dashboard,
  Reports,
  Call,
  Settings,
  Addressbook,
  Billing,
  Admin,
  Msgs,
  Dni,
  Clients,
  Powerups,
  Integrations,
};
