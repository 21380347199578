import Attribution from "./Attribution";
import Followup from "./Followup";
import Intelligence from "./Intelligence";
import List from "./List";
import Tags from "./Tags";

export default {
  path: "/powerups",
  name: "powerups",
  redirect: "/powerups/list",
  children: [Attribution, Followup, Intelligence, List, Tags],
};
