import DateFormat from "Assets/js//DateFormat";

export function reigsterMethods(app) {
  app.config.globalProperties.$dlog = (...args) => {
    if (AppConfig.debug) {
      console.trace(false, ...args);
    }
  };

  app.config.globalProperties.$clog = (...args) => {
    console.log(...args);
  };

  app.config.globalProperties.$stopProp = ($event) => {
    $event.stopImmediatePropagation();
  };

  app.config.globalProperties.$capitalize = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
  };

  app.config.globalProperties.$formatDate = (date, format = "YYYY-MM-DD") => {
    date = date ? new Date(date) : new Date();
    return DateFormat(date, format, { locale: "en" });
  };

  app.config.globalProperties.$formatDateLocale = (date) => {
    try {
      const dateObj = new Date(date);
      return dateObj.toLocaleDateString();
    } catch (e) {
      return date;
    }
  };

  app.config.globalProperties.$isEven = (num) => {
    return num % 2 == 0;
  };

  app.config.globalProperties.$textTruncate = (str, length, ending) => {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  app.config.globalProperties.$slugify = (str) => {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }
    str = str
      .replace(/[^a-z0-9 -]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");
    return str;
  };

  app.config.globalProperties.$getColorByBgColor = (bgColor) => {
    if (!bgColor) return "var(--text)";
    var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    return r * 0.299 + g * 0.587 + b * 0.114 > 186
      ? "var(--text)"
      : "var(--white)";
  };

  app.config.globalProperties.$copyToClipboard = (text) => {
    var input = document.body.appendChild(document.createElement("input"));
    input.value = text;
    input.select();
    document.execCommand("copy");
    input.parentNode.removeChild(input);
    app.notify({
      group: "top",
      type: "info",
      text: "copiato negli appunti",
    });
  };

  app.config.globalProperties.$scrollToError = () => {
    setTimeout(() => {
      let error = document.querySelector(".error--text");
      if (error.length) {
        window.scroll({
          behavior: "smooth",
          left: 0,
          top: element.getBoundingClientRect().top - 50,
        });
      }
    }, 200);
  };

  app.config.globalProperties.$scrollTo = (el) => {
    setTimeout(() => {
      let element = document.querySelector(el);
      if (element.length) {
        window.scroll({
          behavior: "smooth",
          left: 0,
          top: element.getBoundingClientRect().top - 100,
        });
      }
    }, 200);
  };

  app.config.globalProperties.$top = () => {
    app.nextTick(() => {
      window.scrollTo(0, 0);
    });
  };
}
