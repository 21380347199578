import Nprogress from "nprogress";
import Emitter from "Plugins/mitt";
import AppConfig from "Constants/AppConfig";

import { useAppStore } from "Stores/app";
import { useAuthStore } from "Stores/auth";
import { useLayoutStore } from "Stores/layout";

import i18n from "Plugins/i18n";
const { t } = i18n.global;

const checkRouteProceed = (to, from, routeNames, loggedIn) => {
  if (to.path == "/") {
    if (!loggedIn) {
      return { path: "/login" };
    } else {
      return { path: "/dashboard" };
    }
  }

  if (to.meta.access && loggedIn && to.path != "/dashboard") {
    return { path: "/dashboard" };
  }
};

export const setGuards = (router, routeNames) => {
  router.beforeEach(async (to, from) => {
    const authStore = useAuthStore();
    const { loggedIn } = storeToRefs(authStore);
    const profileStore = useProfileStore();
    const { user } = storeToRefs(profileStore);

    await authStore.tokenRefresh();

    Nprogress.start();
    Emitter.emit("blockUI", true);

    if (["/"].includes(to.path)) {
      //  /not-found
      return;
    }

    if (!routeNames.includes(to.name) && !to.path.startsWith("assets")) {
      return { path: "/" }; //  /not-found
    }

    if (!loggedIn.value && !to.meta.public) {
      return { path: "/login", query: { redirect: btoa(to.fullPath) } };
    }

    if (to.meta && to.meta.readable && to.meta.readable.length > 0) {
      let allow = false;
      to.meta.readable.map((role) => {
        if (user.value && user.value.allow[role]) {
          allow = true;
        }
      });
      if (!allow) {
        return { path: "/" }; //  /not-found
      }
    }

    return checkRouteProceed(to, from, routeNames, loggedIn.value);
  });

  // navigation guard after each
  router.afterEach((to, from) => {
    const layout = useLayoutStore();
    const sessionStore = useSessionStore();
    const themeStore = useThemeStore();
    const { customisation } = storeToRefs(themeStore);
    const appStore = useAppStore();
    const { locked, bypass } = storeToRefs(appStore);

    if (to.query.account) {
      sessionStore.selectedAccounts = [to.query.account];
    }

    locked.value = false;
    bypass.value = false;
    Nprogress.done();
    Emitter.emit("blockUI", false);

    document.title =
      customisation.value.title + " " + t("pageNames." + to.name);
    window.scrollTo(0, 0);

    layout.asideMobile = false;
    layout.headerMenuMobile = false;
    layout.headerMobile = false;

    /* Appcues page change */
    if (window.Appcues) {
      window.Appcues.page();
    }
  });
};
