import { useSessionStore } from "Stores/session";

export default class Profile {
  constructor(
    id,
    account,
    allow,
    country,
    dash,
    email,
    expires,
    firstName,
    lastName,
    md5,
    mobile,
    secret2FA,
    sip,
    tz,
    locale,
    username,
  ) {
    this.id = id;
    this.account = account;
    this.allow = allow || {};
    this.country = country;
    this.dash = dash;
    this.email = email;
    this.expires = expires;
    this.firstName = firstName;
    this.lastName = lastName;
    this.md5 = md5;
    this.mobile = mobile;
    this.secret2FA = secret2FA;
    this.sip = sip;
    this.tz = tz;
    this.locale = locale;
    this.username = username;
  }

  getFullName() {
    return this.firstName + (this.lastName ? " " + this.lastName : "");
  }

  getAccount() {
    const { accounts } = useSessionStore();
    return accounts.find((a) => a.id == this.account);
  }
}
