<template>
  <div class="d-inline-flex relative align-center">
    <autocomplete-tree
      v-if="items.length"
      v-model="value"
      :items="items"
      :tree="{ itemKey: 'id', parentKey: 'father' }"
      :props="attrs"
      class="animate__animated animate__fadeIn flex-wrap w-100 ac-tree"
      @full="(v) => emit('full', v)"
    />

    <component-loader width="100%" height="50" v-else />
  </div>
</template>

<script setup>
const AutocompleteTree = defineAsyncComponent(
  () => import("Fields/AutocompleteTree"),
);

const { t } = useI18n();

const sessionStore = useSessionStore();
const { activeAccounts, selectedAccounts } = storeToRefs(sessionStore);

const props = defineProps({
  modelValue: {
    type: [Number, String, Array],
    default: null,
  },
  compact: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  rules: {
    type: Array,
    default: () => [],
  },
  omit: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(["update:modelValue", "full"]);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(newVal) {
    emit("update:modelValue", Array.isArray(newVal) ? newVal[0] : newVal);
  },
});

const attrs = {
  placeholder: t("headers.allAccounts"),
  "item-title": "name",
  "item-value": "id",
  "item-search": "search",
  "return-object": true,
  compact: props.compact,
  rules: props.rules,
  readonly: props.readonly,
  omit: props.omit,
};

const items = computed(() => {
  const items =
    props.omit && props.omit.length
      ? activeAccounts.value.filter((a) => !props.omit.includes(a.id))
      : activeAccounts.value;
  return items.map((a) => {
    a.search = $concatVals([a.name, a.id]);
    return a;
  });
});

watch(
  () => selectedAccounts.value,
  () => {
    if (
      selectedAccounts.value.length == 1 &&
      selectedAccounts.value[0] != value.value
    ) {
      value.value = selectedAccounts.value[0];
    }
  },
);

onMounted(() => {
  if (items.value.length == 1) {
    value.value = items.value[0].id;
  } else if (selectedAccounts.value.length == 1) {
    value.value = selectedAccounts.value[0];
  }
});
</script>
