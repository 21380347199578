export default {
  path: "/messages/lists",
  name: "messages-campaign-lists",
  components: {
    mobile: () => import("Layouts/menus/mobile/Default"),
    sidebar: () => import("Layouts/menus/sidebar/Default"),
    header: () => import("Components/headers/Default.vue"),
    contentHeader: () => import("Components/contentHeaders/Default.vue"),
    footer: () => import("Components/footers/Default.vue"),
    main: () => import("Views/messages/CampaignLists.vue"),
  },
  meta: {
    auth: true,
    editable: [],
    readable: [],
    guarded: false,
    inlineSubheader: true,
  },
};
