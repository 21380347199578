export default class PaymentBiller {
  constructor(
    uuid,
    OrganisationID,
    clientId,
    credit,
    currency,
    email,
    mode,
    name,
    plan,
    type,
  ) {
    this.uuid = uuid;
    this.OrganisationID = OrganisationID;
    this.clientId = clientId;
    this.credit = credit;
    this.currency = currency;
    this.email = email;
    this.mode = mode;
    this.name = name;
    this.plan = plan;
    this.type = type;
  }
}
