<template>
  <span>
    <v-text-field
      v-model="parsedSearch"
      :label="label"
      :loading="loading"
      class="search-input no-lp min-175"
      data-lpignore="true"
      autocomplete="off"
      type="search"
      hide-details
      v-bind="$attrs"
      @keyup="trySearch()"
      @keydown="clearTimer()"
    >
      <template
        v-if="slots.prepend"
        v-slot:prepend-inner
      >
        <slot name="prepend" />
      </template>
      <template v-slot:append-inner>
        <inline-svg
          v-if="!searchString?.length"
          class="kt-svg-icon text-primary"
          src="/assets/media/icons/svg/General/Search.svg"
        />
        <inline-svg
          v-else
          class="pointer kt-svg-icon text-primary"
          src="/assets/media/icons/svg/Navigation/Close.svg"
          @click="clear()"
        />
      </template>
    </v-text-field>
  </span>
</template>

<script setup>
const { t } = useI18n();
const slots = useSlots();

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: "",
  },
  label: {
    type: [Boolean, String],
    default: "",
  },
  loading: {
    type: Boolean,
    default: false,
  },
  direct: {
    type: Boolean,
    default: false,
  },
  isString: {
    type: Boolean,
    default: false,
  },
  props: {
    type: Object,
    default: null,
  },
});
const emit = defineEmits(["update:modelValue"]);

let searchString = ref("");
let searchInput = ref("");
let searchTimer = ref(null);
const debounceTime = 300;

const parsedSearch = computed({
  get() {
    return searchInput.value;
  },
  set(value) {
    let parsed =
      !props.isString && /^[^a-zA-Z]*$/.test(value)
        ? value.replace(/(^[^0-9]0|^0|[^0-9])/g, "")
        : value;
    searchInput.value = value;
    searchString.value = parsed;
  },
});

const clearTimer = () => {
  if (searchTimer) {
    clearTimeout(searchTimer);
  }
};

const trySearch = () => {
  if (!props.direct) {
    clearTimeout(searchTimer);
    searchTimer = setTimeout(search, debounceTime);
  } else {
    search();
  }
};

const clear = () => {
  searchInput.value = "";
  searchString.value = "";
  search();
};

const search = () => {
  emit("update:modelValue", searchString.value);
};

watch(
  () => props.modelValue,
  () => {
    searchInput.value = props.modelValue;
    searchString.value = props.modelValue;
  }
);

const label = computed(() => {
  if (Boolean(props.label) && typeof props.label == "string")
    return props.label;
  return props.label === false ? null : t("search");
});
</script>
