<template>
  <v-text-field
    v-model="value"
    :rules="rules"
    v-bind="$attrs"
    ref="inputRef"
    v-phone-input
  >
    <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </v-text-field>
</template>

<script setup>
import {
  AsYouType,
  isValidPhoneNumber,
  parsePhoneNumberFromString,
} from "libphonenumber-js";

const { t } = useI18n();

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: null,
  },
  rules: {
    type: Array,
    default: () => [],
  },
  country: {
    type: String,
    required: false,
    default: "AU",
  },
});
const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get() {
    if (!props.modelValue) {
      return props.modelValue;
    }

    const info = parsePhoneNumberFromString(
      ("+" + props.modelValue).replace("++", "+"),
      props.country,
    );
    return info && info.isValid()
      ? info.formatInternational()
      : new AsYouType().input(props.modelValue);
  },
  set(newVal) {
    if (newVal) {
      const info = parsePhoneNumberFromString(newVal, props.country);
      newVal = info ? info.number : newVal;
    }
    emit("update:modelValue", newVal);
  },
});

const rules = computed(() => {
  return [
    ...[
      (v) =>
        (Boolean(v) && isValidPhoneNumber(v, props.country)) ||
        t("validations.phoneNotValid"),
    ],
    ...props.rules,
  ];
});
</script>
