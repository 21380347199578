<template>
  <div v-if="modal">
    <v-dialog
      v-model="modal"
      content-class="no-border"
      :max-width="modalData.mw"
      min-width="300"
      persistent
      no-click-animation
    >
      <div>
        <div
          class="modal-content bg-background nicescroll"
          :class="'type-' + modalData.type"
        >
          <div class="modal-title">
            <slot name="title">{{ $capitalize(modalData.title) }}</slot>
          </div>
          <div class="modal-body nicescroll">
            <slot>
              <div v-html="modalData.text"></div>
            </slot>
          </div>
          <div class="modal-footer">
            <div class="modal-actions border">
              <div class="spaced-actions">
                <slot name="cancel">
                  <v-btn
                    variant="plain"
                    class="cancel-btn mr-6"
                    @click="cancel()"
                    >{{ $capitalize(modalData.btnCancel) }}</v-btn
                  >
                </slot>
                <slot name="confirm">
                  <v-btn
                    :color="modalData.type"
                    autofocus
                    flat
                    @click="close()"
                    >{{ modalData.btnOk }}</v-btn
                  >
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script setup>
import Emitter from "Plugins/mitt";

const { t } = useI18n();

let modal = ref(false);
let modalData = ref(false);
let modalDefault = reactive({
  title: "",
  text: "",
  type: "info",
  mw: 500,
  btnOk: t("ok"),
  btnCancel: t("cancel"),
  ok: null,
  cancel: null,
});

const setData = (data) => {
  modalData.value = Object.assign({}, modalDefault, data);
  modal.value = true;
};

const close = () => {
  if (typeof modalData.value.ok == "function") {
    modalData.value.ok();
  }
  modal.value = false;
};

const cancel = () => {
  if (typeof modalData.value.cancel == "function") {
    modalData.value.cancel();
  }
  modal.value = false;
};

onMounted(() => {
  Emitter.on("confirm", setData);
});

onBeforeUnmount(() => {
  Emitter.off("confirm", setData);
});

defineExpose({
  setData,
  close,
  cancel,
});
</script>
