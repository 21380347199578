export default class HubspotConfig {
  constructor(
    account,
    dialerCallerId,
    filters,
    hubId,
    hubName,
    oAuth,
    shareContacts,
    skipAnonymous,
    username,
    uuid,
    webDialer,
  ) {
    this.account = account;
    this.dialerCallerId = dialerCallerId || "any";
    this.filters = filters || { dids: [], tags: [] };
    this.hubId = hubId;
    this.hubName = hubName;
    this.oAuth = oAuth;
    this.shareContacts = shareContacts || "yes";
    this.skipAnonymous = skipAnonymous || "yes";
    this.username = username;
    this.uuid = uuid;
    this.webDialer = webDialer || "yes";
  }
}
