export default class MicrosoftAccount {
  constructor(info, oAuth, remoteAccount, remoteAccountName) {
    this.oAuth = oAuth;
    this.remoteAccount = remoteAccount;
    this.remoteAccountName = remoteAccountName;
    this.info = info || {
      msAccountId: "",
      msAccountName: "",
      msAccountNumber: "",
      msCustomerId: "",
    };
  }
}
