<template>
  <autocomplete-search
    v-model="value"
    :items="filtered"
    :rules="props.rules"
    :placeholder="$t('chooseNumber')"
    :class="props.inputClass"
    item-title="did"
    item-value="did"
    :search-fields="['did', 'name']"
    color="primary"
    single-line
  >
    <template #selection="data">
      <slot
        name="selection"
        :props="data.props"
        :item="data.item"
        :index="data.index"
      >
        <div class="d-flex align-center nowrap">
          {{ data.item.did }}
          <span class="text-grey fs-12 ml-2">({{ data.item.name }})</span>
        </div>
      </slot>
    </template>

    <template #item="{ item, props }">
      <v-list-item v-bind="props">
        <template #title>
          <div class="d-flex align-center nowrap">
            {{ item.did }}
            <span class="text-grey fs-12 ml-2">({{ item.name }})</span>
          </div>
        </template>
      </v-list-item>
    </template>

    <template #append>
      <slot name="append" />
    </template>
  </autocomplete-search>
</template>

<script setup>
const AutocompleteSearch = defineAsyncComponent(
  () => import("Fields/AutocompleteSearch"),
);

const { t } = useI18n();

const sessionStore = useSessionStore();
const { singleAccountNumbers, accountNumbers, numbers } =
  storeToRefs(sessionStore);

const props = defineProps({
  modelValue: {
    type: [String, Array],
    default: null,
  },
  account: {
    type: [Boolean, String],
    default: null,
  },
  exclude: {
    type: Array,
    default: () => [],
  },
  include: {
    type: Array,
    default: () => [],
  },
  type: {
    type: String,
    default: null,
  },
  items: {
    type: Array,
    default: null,
  },
});
const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const items = computed(() => {
  if (props.items) return props.items;

  let nums =
    props.account === true
      ? accountNumbers.value
      : props.account
        ? singleAccountNumbers.value(props.account)
        : numbers.value;
  nums = [...nums, ...props.include];
  return props.type ? nums.filter((a) => a.type == props.type) : nums;
});

const filtered = computed(() => {
  return items.value
    .filter((a) => !props.exclude.includes(a.did) || a.did == value.value)
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
});
</script>
