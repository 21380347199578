import Autoplay from "./Autoplay";
import Exporter from "./Exporter";
import Ga4 from "./Ga4";
import Hubspot from "./Hubspot";
import List from "./List";
import Microsoft from "./Microsoft";
import Slack from "./Slack";
import Zapier from "./Zapier";

export default {
  path: "/integrations",
  name: "integrations",
  redirect: "/integrations/list",
  children: [Autoplay, Exporter, Ga4, Hubspot, List, Microsoft, Slack, Zapier],
};
