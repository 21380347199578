import axios from "axios";
import REQ from "Api/Requests";
import Nprogress from "nprogress";
import { parseErrors } from "Helpers/Api";
import { useAuthStore } from "@/stores/auth";

const baseURL = import.meta.env.VITE_API_URL;
const baseURLv3 = baseURL.replace("/v2", "/v3");

export default {
  async login(payload) {
    Nprogress.start();
    try {
      const response = await axios.post(
        baseURL + "token/dashboard/",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Basic " + btoa(payload.email + ":" + payload.password),
          },
        },
      );
      return response.data;
    } catch (err) {
      throw await parseErrors(err, true);
    } finally {
      Nprogress.done();
    }
  },

  async refresh(payload) {
    Nprogress.start();
    try {
      const response = await axios.post(baseURL + "token/dashboard/", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err) {
      throw await parseErrors(err, true);
    } finally {
      Nprogress.done();
    }
  },

  async jwt(payload) {
    Nprogress.start();
    try {
      const response = await axios.post(
        baseURL + "token/dashboard/jwt",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Basic " + btoa(payload.email + ":" + payload.password),
          },
        },
      );
      return response.data;
    } catch (err) {
      throw await parseErrors(err, true);
    } finally {
      Nprogress.done();
    }
  },

  async logout() {
    const authStore = useAuthStore();
    Nprogress.start();
    try {
      const response = await axios.post(
        baseURL + "logout/dashboard/",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authStore.token,
          },
        },
      );
      return response;
    } catch (err) {
      throw err;
    } finally {
      Nprogress.done();
    }
  },

  async register(payload) {
    Nprogress.start();
    try {
      const response = await axios.post(baseURL + "users/register", {
        profile: payload,
      });
      return response.data.data;
    } catch (err) {
      throw await parseErrors(err, true);
    } finally {
      Nprogress.done();
    }
  },

  async verifyEmail(payload) {
    Nprogress.start();
    let queryString = new URLSearchParams(payload).toString();
    try {
      const response = await axios.get(
        baseURL + "users/confirm-email?" + queryString,
      );
      return response.data.data;
    } catch (err) {
      throw await parseErrors(err, true);
    } finally {
      Nprogress.done();
    }
  },

  async recoverPassword(payload) {
    Nprogress.start();
    try {
      const response = await axios.post(baseURLv3 + "public/forgot/forgot", payload);
      return response.data.data;
    } catch (err) {
      console.log(err);
      throw await parseErrors(err, true);
    } finally {
      Nprogress.done();
    }
  },

  async resetPassword(payload, token) {
    Nprogress.start();
    try {
      const response = await axios.put(baseURLv3 + "public/forgot/"+ token, payload);
      return response.data.data;
    } catch (err) {
      throw await parseErrors(err, true);
    } finally {
      Nprogress.done();
    }
  },

  async invitationDetails(token) {
    Nprogress.start();
    try {
      const response = await axios.get(baseURL + "public/invite/" + token);
      return response.data.data;
    } catch (err) {
      throw await parseErrors(err, true);
    } finally {
      Nprogress.done();
    }
  },

  async acceptInvitation(token, payload) {
    Nprogress.start();
    try {
      const response = await axios.post(
        baseURL + "public/invite/" + token,
        payload,
      );
      return response.data.data;
    } catch (err) {
      throw await parseErrors(err, true);
    } finally {
      Nprogress.done();
    }
  },

  otp: {
    async sendSmsCode() {
      try {
        const path = REQ.setPath("user/me/sms2FA");
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async sendMode(mode) {
      try {
        const path = REQ.setPath("user/me/" + mode);
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async add2faNumber(payload) {
      try {
        const path = REQ.setPath("user/me");
        const response = await REQ.post(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async checkCode(code) {
      try {
        const path = REQ.setPath("user/me/2fa");
        const response = await REQ.post(path, { code: code });
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async checkModeCode(code, mode) {
      try {
        const path = REQ.setPath("user/me/" + mode);
        const response = await REQ.patch(path, { code: code });
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  signup: {
    async submitBusinessData(payload, uuid) {
      Nprogress.start();
      try {
        const path = REQ.setPath("signup/" + uuid);
        const response = await REQ.post(path, payload);
        return response.data;
      } catch (err) {
        throw await parseErrors(err, true);
      } finally {
        Nprogress.done();
      }
    },

    async addBusinessCard(payload, uuid) {
      Nprogress.start();
      try {
        const path = REQ.setPath("signup/" + uuid);
        const response = await REQ.patch(path, payload);
        return response.data;
      } catch (err) {
        throw await parseErrors(err, true);
      } finally {
        Nprogress.done();
      }
    },

    async decodeToken(token) {
      Nprogress.start();
      try {
        const path = REQ.setPath("signup/" + token);
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        console.log("ee", err);
        throw await parseErrors(err, true);
      } finally {
        Nprogress.done();
      }
    },
  },
};
