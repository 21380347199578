import AppConfig from "Constants/AppConfig";
import REQ from "Api/Requests";

export default {
  async timeday2(params) {
    try {
      const path = REQ.setPath("reports/timeday2/", params, [
        "accounts",
        "date",
        "filters",
      ]);
      const response = await REQ.get(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async webSummary(params) {
    try {
      const path = REQ.setPath("reports/webSummary/", params, [
        "accounts",
        "date",
      ]);
      const response = await REQ.get(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async webKeywords(params) {
    try {
      const path = REQ.setPath("reports/webKeywords/", params, [
        "accounts",
        "date",
      ]);
      const response = await REQ.get(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async landing(params) {
    try {
      const path = REQ.setPath("reports/webTable2/landing/", params, [
        "accounts",
        "date",
      ]);
      const response = await REQ.get(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async conversion(params) {
    try {
      const path = REQ.setPath("reports/webTable2/conversion/", params, [
        "accounts",
        "date",
      ]);
      const response = await REQ.get(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async source(params) {
    try {
      const path = REQ.setPath("reports/source/", params, [
        "accounts",
        "date",
        "filters",
      ]);
      const response = await REQ.get(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async duration(params) {
    try {
      const path = REQ.setPath("reports/duration/", params, [
        "accounts",
        "date",
        "filters",
      ]);
      const response = await REQ.get(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async missed(type, params) {
    try {
      const path = REQ.setPath("reports/missed/" + type, params, [
        "accounts",
        "date",
        "filters",
      ]);
      const response = await REQ.get(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async insight(account, month, params) {
    try {
      const path = REQ.setPath(
        "account/" + account + "/insight/" + month,
        params,
      );
      const response = await REQ.get(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async outDashOverview(params) {
    try {
      const path = REQ.setPath("reports/outdash/", params, [
        "accounts",
        "date",
      ]);
      const response = await REQ.get(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async outDashAgent(grouping, params) {
    try {
      const path = REQ.setPath("reports/outAgent/" + grouping, params, [
        "accounts",
        "date",
      ]);
      const response = await REQ.get(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async ivr(params) {
    try {
      const path = REQ.setPath("reports/ivr/", params, [
        "accounts",
        "date",
        "filters",
      ]);
      const response = await REQ.get(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },
};
