export default [
  {
    name: "English (us)",
    icon: "en",
    locale: "en",
    currency: "usd",
    symbol: "$",
  },
  {
    name: "English (uk)",
    icon: "uk",
    locale: "en",
    currency: "gbp",
    symbol: "£",
  },
  {
    name: "Italiano",
    icon: "it",
    locale: "it",
    currency: "eur",
    symbol: "€",
  },
];
