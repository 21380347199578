export default {
  path: "/clients/list",
  name: "clients-list",
  components: {
    mobile: () => import("Layouts/menus/mobile/Default"),
    sidebar: () => import("Layouts/menus/sidebar/Default"),
    header: () => import("Components/headers/Default.vue"),
    contentHeader: () => import("Components/contentHeaders/Default.vue"),
    footer: () => import("Components/footers/Default.vue"),
    main: () => import("Views/clients/Clients.vue"),
    clients: () => import("Views/clients/ClientsList.vue"),
  },
  meta: {
    auth: true,
    editable: [],
    readable: ["super", "system", "support"],
    guarded: false,
  },
};
