import AppConfig from "Constants/AppConfig";
import REQ from "Api/Requests";

export default {
  followup: {
    async createNum(account, payload) {
      try {
        const path = REQ.setPath("number/" + account + "/followup");
        const response = await REQ.put(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async get(account) {
      try {
        const path = REQ.setPath("account/" + account + "/followup");
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async create(account, payload) {
      try {
        const path = REQ.setPath("account/" + account + "/followup");
        const response = await REQ.put(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async edit(account, payload) {
      try {
        const path = REQ.setPath("account/" + account + "/followup");
        const response = await REQ.post(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async delete(account) {
      try {
        const path = REQ.setPath("account/" + account + "/followup");
        const response = await REQ.delete(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  tags: {
    async list(account) {
      try {
        const path = REQ.setPath("account/" + account + "/autotag");
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async create(account, payload) {
      try {
        const path = REQ.setPath("account/" + account + "/autotag");
        const response = await REQ.put(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async edit(account, payload) {
      try {
        const path = REQ.setPath(
          "account/" + account + "/autotag/" + payload.uuid,
        );
        const response = await REQ.post(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async delete(account, uuid) {
      try {
        const path = REQ.setPath("account/" + account + "/autotag/" + uuid);
        const response = await REQ.delete(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  intelligence: {
    async numbersList(account) {
      try {
        const path = REQ.setPath(
          "account/" + account + "/intelligence/numbers",
        );
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async numbersEdit(account, payload) {
      try {
        const path = REQ.setPath(
          "account/" + account + "/intelligence/numbers",
        );
        const response = await REQ.post(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async list(account) {
      try {
        const path = REQ.setPath("account/" + account + "/intelligence");
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async get(account) {
      try {
        const path = REQ.setPath("account/" + account + "/intelligence");
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async create(account, payload) {
      try {
        const path = REQ.setPath("account/" + account + "/intelligence");
        const response = await REQ.put(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async edit(account, payload) {
      try {
        const path = REQ.setPath(
          "account/" + account + "/intelligence/" + payload.uuid,
        );
        const response = await REQ.post(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async delete(account, uuid) {
      try {
        const path = REQ.setPath(
          "account/" + account + "/intelligence/" + uuid,
        );
        const response = await REQ.delete(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async quoteReprocess(account, startDate) {
      try {
        const path = REQ.setPath(
          "account/" +
            account +
            "/intelligence/reprocess?dateStart=" +
            startDate,
        );
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async reProcess(payload, account) {
      try {
        const path = REQ.setPath(
          "account/" + account + "/intelligence/reprocess",
        );
        const response = await REQ.patch(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  attribution: {
    async init(account) {
      try {
        const path = REQ.setPath("account/" + account + "/salesAttribution");
        const response = await REQ.put(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async edit(account, payload) {
      try {
        const path = REQ.setPath("report/salesAttribution/" + account);
        const response = await REQ.put(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },
};
