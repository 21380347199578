export default class Prospect {
  constructor(
    uuid,
    orgId,
    status,
    plan,
    country,
    currency,
    clientId,
    billerCode,
    account,
    contactName,
    contactEmail,
    contactPhone,
    businessName,
    businessAddress,
    businessTax,
    businessEmail,
    businessCategory,
    stripeCustomer,
    stripeCard,
    masterClient,
    confirmLink,
    last,
  ) {
    this.uuid = uuid;
    this.orgId = orgId;
    this.status = status;
    this.plan = plan;
    this.country = country;
    this.currency = currency;
    this.clientId = clientId;
    this.billerCode = billerCode;
    this.account = account;
    this.contactName = contactName;
    this.contactEmail = contactEmail;
    this.contactPhone = contactPhone;
    this.businessName = businessName;
    this.businessTax = businessTax;
    this.businessEmail = businessEmail;
    this.businessCategory = businessCategory;
    this.stripeCustomer = stripeCustomer;
    this.stripeCard = stripeCard;
    this.masterClient = masterClient;
    this.confirmLink = confirmLink;
    this.businessAddress = businessAddress; /*|| {
      unit: '',
      number: '',
      street: '',
      country: '',
      city: '',
      postCode: '',
      address: '',
      full: ''
    }
    */
    this.last = last; /*|| {
      date: '',
      timezone_type: '',
      timezone: ''
    }
    */
  }
}
