export default class ReportGroupItem {
  constructor(
    account,
    actions,
    answered,
    avgDuration,
    missed,
    name,
    newCallers,
    revenue,
    total,
  ) {
    this.account = account;
    this.actions = actions;
    this.answered = answered;
    this.avgDuration = avgDuration;
    this.missed = missed;
    this.name = name;
    this.newCallers = newCallers;
    this.revenue = revenue;
    this.total = total;
  }
}
