export default {
  path: "/signup/:token",
  name: "auth-signup",
  components: {
    main: () => import("Views/auth/Signup.vue"),
  },
  meta: {
    public: true,
    containerClass: "pt-5 max-1000",
  },
};
