export default class Account {
  constructor(id, billerCode, category, country, name, plan, status, timezone) {
    this.id = id || 0;
    this.billerCode = billerCode || "";
    this.category = category || "";
    this.country = country;
    this.name = name || "";
    this.plan = plan;
    this.status = status || "";
    this.timezone = timezone || "";
  }
}
