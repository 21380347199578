const curyear = new Date().getFullYear();

export default {
  debug: import.meta.env.VITE_ENVIRONMENT === "production" ? false : true,
  environment: import.meta.env.VITE_ENVIRONMENT,
  version: import.meta.env.VITE_VERSION,
  websockets: false,
  enableUserTour: false,
  days: [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ],
  branding: {
    appLogo: "/assets/brand/wildjar-logo.png",
    appLogoWhite: "/assets/brand/wildjar-logo.png",
    icon: "/assets/brand/wildjar-logo.png",
    brand: "Wildjar",
    copyrightText: "Wildjar © " + curyear + " all rights reserved.",
  },
  api: {
    baseURL: import.meta.env.VITE_API_URL,
    stripeKey: import.meta.env.VITE_STRIPE_KEY,
    wssApp: import.meta.env.VITE_WSS_APP_URL,
    webdialer: import.meta.env.VITE_WEBDIALER_URL,
  },
  stripe: {
    pk: import.meta.env.VITE_STRIPE_PK,
  },
  google: {
    apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
  },
  services: {
    webdialer: import.meta.env.VITE_WEBDIALER,
    rollbar: {
      enabled: import.meta.env.VITE_ROLLBAR,
      token: import.meta.env.VITE_ROLLBAR_TOKEN,
    },
    zapier: {
      clientId: "97Dg5bWUMHTzK8QmVJtzzFPC6L5HARoM3P5o7t6g",
    },
    appcues: {
      enabled: import.meta.env.VITE_APPCUES,
      script: import.meta.env.VITE_APPCUES_SCRIPT,
    },
  },
  links: {
    terms: "https://www.wildjar.com/terms-and-conditions",
    privacy: "https://www.wildjar.com/privacy-policy",
    support: "https://support.wildjar.com/hc/en-us/",
    signup: window.location.origin + "/signup/",
  },
  config: {
    stepLogin: false,
    tokenMode: "COOKIE", //COOKIE or STORAGE
    axiosCache: {
      enabled: import.meta.env.VITE_AXIOS_CACHE,
      ttl: import.meta.env.VITE_AXIOS_CACHE_TTL,
    },
    pwa: {
      enabled: import.meta.env.VITE_PWA,
    },
  },
  paths: {
    flags: "/assets/media/flags/",
    media: "/assets/media/",
  },
  filters: {
    dateViews: {
      icon: "",
      name: "dateViews",
      menu: false,
      values: ["hours", "weekdays", "days", "months"],
    },
    trackingNumber: {
      icon: "mdi-inbox-outline",
      name: "trackingNumber",
      keys: ["trackingNumber"],
      menu: true,
    },
    status: {
      icon: "mdi-list-status",
      name: "status",
      menu: true,
      keys: ["status"],
      values: ["answered", "missed", "abandoned"],
    },
    trackingSource: {
      icon: "mdi-package-variant",
      name: "trackingSource",
      keys: ["trackingSource"],
      menu: true,
    },
    callDuration: {
      icon: "mdi-camera-timer",
      name: "callDuration",
      keys: ["durationMin", "durationMax"],
      menu: true,
    },
  },
  exports: {
    call: [
      "uuid",
      "dateStartUser",
      "caller",
      "area",
      "firstTimeCaller",
      "trackingNumber",
      "trackingSource",
      "status",
      "duration",
      "talkTime",
      "target",
      "audio",
      "web_source",
      "web_medium",
      "tags",
      "IVR",
      "score",
      "notes",
      "account",
      "accountName",
    ],
    billing: [
      "accountName",
      "total",
      "rental",
      "dids",
      "inboundAmount",
      "inboundMinutes",
      "inboundCalls",
      "outboundAmount",
      "outboundMinutes",
      "outboundCalls",
      "transcript",
      "sms",
      "bulk",
    ],
    messageReport: ["number", "status"],
    top10: [
      "name",
      "total",
      "newCallers",
      "answered",
      "missed",
      "abandoned",
      "avgDuration",
      "revenue",
    ],
    timeAndDay: ["label", "answer", "missed", "first", "returning"],
    callAttributionReport: [
      "source",
      "total",
      "first",
      "returning",
      "answer",
      "missed",
      "abandoned",
      "avgDuration",
    ],
    callDuration: ["label", "num", "percent"],
    missedCalls: ["label", "answered", "abandoned", "missed"],
    ivr: [
      "name",
      "digits",
      "tot",
      "answer",
      "missed",
      "abandoned",
      "avgDuration",
    ],
    outboundTop10: ["name", "total", "answered", "avgTalk", "avgDuration"],
    smsBulkReport: [
      "sender",
      "name",
      "scheduled",
      "sent",
      "delivered",
      "bounced",
      "blocked",
      "replied",
    ],
  },
  colors: {
    answered: "success",
    missed: "error",
    abandoned: "warning",
  },
  tables: {
    itemsPerPageOptions: [10, 25, 50, 100],
  },
  topCountries: ["AU", "NZ", "GB", "SG", "US", "MY"],
  networks: ["geo", "mobile", "smart", "tollfree"],
  voicemailNotificationTriggers: ["missed", "always", "no"],
  user: {
    allowances: ["reports", "conf", "users", "billing", "admin", "super"],
  },
  billingVoices: [
    "NUMBER-RENTAL",
    "MONTHLY-FEE",
    "VOICE-USAGE",
    "VOICE-OUTBOUND",
    "TRANSCRIPT",
    "SMS-OUTBOUND",
    "SMS-BULK",
    "OTHER",
  ],
  dni: {
    options: {
      mediums: ["any", "(none)", "organic", "paid", "social"],
      sources: ["any", "google", "bing", "facebook"],
      replacements: ["text", "image", "custom", "js"],
    },
  },
  prospects: {
    options: {
      plans: ["starter", "agency", "custom"],
      payments: ["stripe", "skip"],
    },
  },
  rates: {
    items: [
      {
        key: "NUMBER-RENTAL",
        static: false,
        multiCountry: true,
        multiType: true,
        name: "Number Rental",
        group: "numbers",
      },
      {
        key: "VOICE-USAGE",
        static: false,
        multiCountry: true,
        multiType: true,
        name: "Inbound Minutes",
        group: "usage",
      },
      {
        key: "VOICE-OUTBOUND",
        static: false,
        multiCountry: true,
        multiType: true,
        name: "Outbound Minutes",
        group: "usage",
      },
      {
        key: "TRANSCRIPT",
        static: false,
        multiCountry: false,
        multiType: false,
        name: "Call Intelligence",
        group: "usage",
      },
      {
        key: "MONTHLY-FEE",
        static: false,
        multiCountry: false,
        multiType: false,
        name: "Monthly Technology Fee",
        group: "fixedRates",
      },
      {
        key: "ACCOUNT",
        static: false,
        multiCountry: false,
        multiType: false,
        name: "Flat Cost Per Account",
        group: "fixedRates",
      },
      {
        key: "CHATBOT",
        static: false,
        multiCountry: false,
        multiType: false,
        name: "Web Chat Bot",
        group: "fixedRates",
      },
      {
        key: "SMS-OUTBOUND",
        static: false,
        multiCountry: true,
        multiType: false,
        name: "Outbound SMS",
        group: "sms",
      },
      {
        key: "SMS-BULK",
        static: false,
        multiCountry: true,
        multiType: false,
        name: "SMS Recharge/Campaigns",
        group: "sms",
      },
      {
        key: "SUPPORT-FEE",
        static: true,
        multiCountry: false,
        multiType: false,
        name: "Support Assistance Fee",
        group: "custom",
      },
      {
        key: "FREE-MONTH",
        static: true,
        multiCountry: false,
        multiType: false,
        charge: "1",
        name: "Waive Invoice",
        group: "custom",
      },
    ],
    types: [
      { value: "--", title: "any" },
      { value: "tollfree", title: "tollfree" },
      { value: "smart", title: "smart" },
      { value: "mobile", title: "mobile" },
      { value: "geo", title: "geo" },
    ],
  },
  customisations: {
    emails: {
      types: [
        "Call-Notification",
        "Client-activation",
        "Scheduled-reports",
        "Signup-confirmation",
        "SMS2email",
        "User-forgot-password",
        "User-invite",
      ],
    },
  },
  calls: {
    statuses: ["all", "missed", "answered"],
    conditions: ["adSource", "status", "duration", "talk", "caller", "lead"],
  },
};
