import { createI18n } from "vue-i18n";
import messages from "@/lang";

export default createI18n({
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  globalInjection: true,
  warnHtmlMessage: false,
  messages,
});
