import Currency from "currency.js";
import GeoData from "Data/GeoData";

export const currencyToFloat = (value) => {
  value = String(value).trim();
  var result = value.replace(/[^0-9]/g, "");
  if (/[,\.]\d{2}$/.test(value)) {
    result = result.replace(/(\d{2})$/, ".$1");
  }
  return parseFloat(result);
};

export const moneyFormat = (value, symbol) => {
  const profileStore = useProfileStore();
  symbol =
    symbol !== false
      ? symbol ||
        GeoData.currencies[
          GeoData.countries[profileStore.user.country].currency
        ].symbol
      : "";
  return Currency(parseFloat(value), {
    separator: ".",
    decimal: ",",
    symbol: symbol,
    formatWithSymbol: false,
  }).format();
};

export const getCurrencySymbol = (currency, native = false) => {
  return native
    ? GeoData.currencies[currency].symbol_native
    : GeoData.currencies[currency].symbol;
};
