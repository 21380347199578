export default class OauthConfig {
  constructor(
    account,
    expiresIn,
    expiresOn,
    provider,
    remoteAccount,
    shared,
    usedBy,
    user,
    username,
    uuid,
    valid,
  ) {
    this.account = account;
    this.expiresIn = expiresIn;
    this.expiresOn = expiresOn;
    this.provider = provider;
    this.remoteAccount = remoteAccount;
    this.shared = shared;
    this.usedBy = usedBy;
    this.user = user;
    this.username = username;
    this.uuid = uuid;
    this.valid = valid || false;
  }
}
