import i18n from "Plugins/i18n";

export const $copy = (data, json = false) => {
  if (json) {
    return JSON.parse(JSON.stringify(data));
  }
  return Array.isArray(data) ? [...data] : structuredClone(toRaw(data));
};

export const $capitalize = (string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
};

export const $includesAny = (arr, values) => {
  return values.some((v) => arr.includes(v));
};

export const $doesArrayIntersect = (arr, target) => {
  return target.every((v) => arr.includes(v));
};

export const $isEven = (num) => {
  return num % 2 == 0;
};

export const $isUrl = (value) => {
  return value && value.startsWith("http");
};

export const $same = (a, b) => {
  return JSON.stringify(toRaw(a)) == JSON.stringify(toRaw(b));
};

export function $textTruncate(str, length, ending) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}

export const $percentageChange = (p2, p1, inverted = false) => {
  let res = 0;
  p1 = parseFloat(p1);
  p2 = parseFloat(p2);
  if (p1 < p2) {
    res = ((p2 - p1) / (p1 || 1)) * 100;
  } else if (p1 > p2) {
    res = ((p1 - p2) / (p1 || 1)) * 100 * -1;
  }
  return res != 0 && inverted ? res * -1 : res;
};

export const $textFromBg = (bgColor) => {
  if (!bgColor) return "var(--text)";
  var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186
    ? "var(--text)"
    : "var(--white)";
};

export const $range = (min, max, string = false, reverse = false) => {
  var len = max - min + 1;
  var arr = new Array(len);
  for (var i = 0; i < len; i++) {
    let val = string ? (min + i).toString() : min + i;
    arr[i] = val;
  }
  arr = reverse ? arr.reverse() : arr;
  return arr;
};

export const $isEmpty = (x) => {
  if (x === undefined || x === null) {
    return true;
  }
  if (typeof x === "object") {
    for (var key in x) {
      if (x && x.hasOwnProperty(key)) return false;
    }
    return true;
  }
  if (Array.isArray(x) && !x.length) {
    return true;
  }
  return false;
};

export const $isJson = function (str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const $wrapText = (s, w) => {
  return s.replace(
    new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, "g"),
    "$1\n",
  );
};

export const $concatVals = (array) => {
  return array.flat(10).join("-").toLowerCase();
};

export const $slugify = (str) => {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }
  str = str
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");
  return str;
};

export const $listToTree = (dataset, itemKey, parentKey) => {
  const hashTable = Object.create(null);
  dataset.forEach(
    (aData) => (hashTable[aData[itemKey]] = { ...aData, childNodes: [] }),
  );
  const dataTree = [];
  dataset.forEach((aData) => {
    if (aData[parentKey] && hashTable[aData[parentKey]]) {
      hashTable[aData[parentKey]].childNodes.push(hashTable[aData[itemKey]]);
    } else {
      dataTree.push(hashTable[aData[itemKey]]);
    }
  });
  return dataTree;
};

export const $formatExportData = (data, details, type) => {
  const { t } = i18n.global;

  return data.map((item) => {
    let newItem = {};
    details.params.map((param) => {
      switch (true) {
        case param == "tags":
          newItem[t("exportData." + type + ".tags")] =
            item.tags && item.tags.length
              ? item.tags
                  .map((a) => {
                    let tag = details.tags[a];
                    return tag ? tag.name : "";
                  })
                  .join(", ")
              : "";
          break;
        case param == "audio":
          newItem[t("exportData." + type + ".audio")] =
            item.audio && item.audio.length ? "Yes" : "No";
          break;
        case param == "IVR":
          newItem[t("exportData." + type + ".IVR")] = item.IVR
            ? item.IVR.option + (item.IVR.name ? " " + item.IVR.name : "")
            : "";
          break;
        case param == "web":
          newItem[t("exportData." + type + ".web_source")] =
            item.web && item.web.source ? item.web.source : "";
          newItem[t("exportData." + type + ".web_medium")] =
            item.web && item.web.medium ? item.web.medium : "";
          break;
        case details.currencies &&
          details.currencies.includes(param) &&
          Boolean(item[param]):
          newItem[t("exportData." + type + "." + param)] = moneyFormat(
            item[param],
          );
          break;
        case details.durations &&
          details.durations.includes(param) &&
          Boolean(item[param]):
          newItem[t("exportData." + type + "." + param)] = secondsToDuration(
            item[param],
          );
          break;
        default:
          newItem[t("exportData." + type + "." + param)] = item[param] || "";
      }
    });
    return newItem;
  });
};

export const $toFormData = (data) => {
  let form_data = new FormData();

  for (let key in data) {
    form_data.append(key, data[key]);
  }

  return form_data;
};

export const $toSearchParams = (data) => {
  let search_params = new URLSearchParams();

  for (let key in data) {
    search_params.append(key, data[key]);
  }

  return search_params;
};

export const $getOrigin = (url) => {
  const { origin } = new URL(url);
  return origin;
};

export const $groupBy = (data, key, def = "_") => {
  let groups = {};
  data.map((item) => {
    let group = item[key] || def;
    if (!groups[group]) {
      groups[group] = [];
    }
    groups[group].push(item);
  });
  return groups;
};

export const $levenshteinDistance = (str1 = "", str2 = "") => {
  const track = Array(str2.length + 1)
    .fill(null)
    .map(() => Array(str1.length + 1).fill(null));
  for (let i = 0; i <= str1.length; i += 1) {
    track[0][i] = i;
  }
  for (let j = 0; j <= str2.length; j += 1) {
    track[j][0] = j;
  }
  for (let j = 1; j <= str2.length; j += 1) {
    for (let i = 1; i <= str1.length; i += 1) {
      const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
      track[j][i] = Math.min(
        track[j][i - 1] + 1, // deletion
        track[j - 1][i] + 1, // insertion
        track[j - 1][i - 1] + indicator, // substitution
      );
    }
  }
  return track[str2.length][str1.length];
};
