export default {
  countries: {
    TW: {
      name: "Taiwan",
      currency: null,
      lang: "zh-TW,zh,nan,hak",
      units: null,
      prefix: "886",
      tz: ["Asia/Taipei"],
      flag: "202-taiwan.svg",
    },
    AF: {
      name: "Afghanistan",
      currency: "AFN",
      lang: "fa-AF,ps,uz-AF,tk",
      units: "2",
      prefix: "93",
      tz: ["Asia/Kabul"],
      flag: "111-afghanistan.svg",
    },
    AL: {
      name: "Albania",
      currency: "ALL",
      lang: "sq,el",
      units: "2",
      prefix: "355",
      tz: ["Europe/Tirane"],
      flag: "099-albania.svg",
    },
    DZ: {
      name: "Algeria",
      currency: "DZD",
      lang: "ar-DZ",
      units: "2",
      prefix: "213",
      tz: ["Africa/Algiers"],
      flag: "144-algeria.svg",
    },
    AS: {
      name: "American Samoa",
      currency: "USD",
      lang: "en-AS,sm,to",
      units: "2",
      prefix: "1-684",
      tz: ["Pacific/Pago_Pago"],
    },
    AD: {
      name: "Andorra",
      currency: "EUR",
      lang: "ca",
      units: "2",
      prefix: "376",
      tz: ["Europe/Andorra"],
      flag: "045-andorra.svg",
    },
    AO: {
      name: "Angola",
      currency: "AOA",
      lang: "pt-AO",
      units: "2",
      prefix: "244",
      tz: ["Africa/Lagos"],
      flag: "117-angola.svg",
    },
    AI: {
      name: "Anguilla",
      currency: "XCD",
      lang: "en-AI",
      units: "2",
      prefix: "1-264",
      tz: ["America/Port_of_Spain"],
      flag: "025-anguilla.svg",
    },
    AQ: {
      name: "Antarctica",
      currency: null,
      lang: null,
      units: null,
      prefix: "672",
      tz: [
        "Antarctica/Rothera",
        "Antarctica/Palmer",
        "Antarctica/Mawson",
        "Antarctica/Davis",
        "Antarctica/Casey",
        "Antarctica/Vostok",
        "Antarctica/DumontDUrville",
        "Antarctica/Syowa",
        "Antarctica/Troll",
        "Pacific/Auckland",
      ],
    },
    AG: {
      name: "Antigua & Barbuda",
      currency: "XCD",
      lang: "en-AG",
      units: "2",
      prefix: "1-268",
      tz: ["America/Port_of_Spain"],
    },
    AR: {
      name: "Argentina",
      currency: "ARS",
      lang: "es-AR,en,it,de,fr,gn",
      units: "2",
      prefix: "54",
      tz: [
        "America/Argentina/Buenos_Aires",
        "America/Argentina/Cordoba",
        "America/Argentina/Salta",
        "America/Argentina/Jujuy",
        "America/Argentina/Tucuman",
        "America/Argentina/Catamarca",
        "America/Argentina/La_Rioja",
        "America/Argentina/San_Juan",
        "America/Argentina/Mendoza",
        "America/Argentina/San_Luis",
        "America/Argentina/Rio_Gallegos",
        "America/Argentina/Ushuaia",
      ],
      flag: "198-argentina.svg",
    },
    AM: {
      name: "Armenia",
      currency: "AMD",
      lang: "hy",
      units: "2",
      prefix: "374",
      tz: ["Asia/Yerevan"],
      flag: "108-armenia.svg",
    },
    AW: {
      name: "Aruba",
      currency: "AWG",
      lang: "nl-AW,es,en",
      units: "2",
      prefix: "297",
      tz: ["America/Curacao"],
      flag: "042-aruba.svg",
    },
    AU: {
      name: "Australia",
      currency: "AUD",
      lang: "en-AU",
      units: "2",
      prefix: "61",
      tz: [
        "Australia/Lord_Howe",
        "Antarctica/Macquarie",
        "Australia/Hobart",
        "Australia/Currie",
        "Australia/Melbourne",
        "Australia/Sydney",
        "Australia/Broken_Hill",
        "Australia/Brisbane",
        "Australia/Lindeman",
        "Australia/Adelaide",
        "Australia/Darwin",
        "Australia/Perth",
        "Australia/Eucla",
      ],
      flag: "234-australia.svg",
    },
    AT: {
      name: "Austria",
      currency: "EUR",
      lang: "de-AT,hr,hu,sl",
      units: "2",
      prefix: "43",
      tz: ["Europe/Vienna"],
      flag: "003-austria.svg\r\n",
    },
    AZ: {
      name: "Azerbaijan",
      currency: "AZN",
      lang: "az,ru,hy",
      units: "2",
      prefix: "994",
      tz: ["Asia/Baku"],
      flag: "141-azerbaijan.svg",
    },
    BS: {
      name: "Bahamas",
      currency: "BSD",
      lang: "en-BS",
      units: "2",
      prefix: "1-242",
      tz: ["America/Nassau"],
      flag: "120-bahamas.svg",
    },
    BH: {
      name: "Bahrain",
      currency: "BHD",
      lang: "ar-BH,en,fa,ur",
      units: "3",
      prefix: "973",
      tz: ["Asia/Qatar"],
      flag: "138-bahrain.svg",
    },
    BD: {
      name: "Bangladesh",
      currency: "BDT",
      lang: "bn-BD,en",
      units: "2",
      prefix: "880",
      tz: ["Asia/Dhaka"],
      flag: "147-bangladesh.svg",
    },
    BB: {
      name: "Barbados",
      currency: "BBD",
      lang: "en-BB",
      units: "2",
      prefix: "1-246",
      tz: ["America/Barbados"],
      flag: "084-barbados.svg",
    },
    BY: {
      name: "Belarus",
      currency: "BYN",
      lang: "be,ru",
      units: "2",
      prefix: "375",
      tz: ["Europe/Minsk"],
      flag: "135-belarus.svg",
    },
    BE: {
      name: "Belgium",
      currency: "EUR",
      lang: "nl-BE,fr-BE,de-BE",
      units: "2",
      prefix: "32",
      tz: ["Europe/Brussels"],
      flag: "165-belgium.svg",
    },
    BZ: {
      name: "Belize",
      currency: "BZD",
      lang: "en-BZ,es",
      units: "2",
      prefix: "501",
      tz: ["America/Belize"],
      flag: "078-belize.svg",
    },
    BJ: {
      name: "Benin",
      currency: "XOF",
      lang: "fr-BJ",
      units: "0",
      prefix: "229",
      tz: ["Africa/Lagos"],
      flag: "060-benin.svg",
    },
    BM: {
      name: "Bermuda",
      currency: "BMD",
      lang: "en-BM,pt",
      units: "2",
      prefix: "1-441",
      tz: ["Atlantic/Bermuda"],
      flag: "081-bermuda.svg",
    },
    BT: {
      name: "Bhutan",
      currency: "INR,BTN",
      lang: "dz",
      units: "2,2",
      prefix: "975",
      tz: ["Asia/Thimphu"],
      flag: "040-bhutan.svg",
    },
    BO: {
      name: "Bolivia",
      currency: "BOB",
      lang: "es-BO,qu,ay",
      units: "2",
      prefix: "591",
      tz: ["America/La_Paz"],
      flag: "150-bolivia.svg",
    },
    BQ: {
      name: "Caribbean Netherlands",
      currency: "USD",
      lang: "nl,pap,en",
      units: "2",
      prefix: "599",
      tz: ["America/Curacao"],
      flag: "237-netherlands.svg",
    },
    BA: {
      name: "Bosnia",
      currency: "BAM",
      lang: "bs,hr-BA,sr-BA",
      units: "2",
      prefix: "387",
      tz: ["Europe/Belgrade"],
      flag: "132-bosnia-and-herzegovina.svg",
    },
    BW: {
      name: "Botswana",
      currency: "BWP",
      lang: "en-BW,tn-BW",
      units: "2",
      prefix: "267",
      tz: ["Africa/Maputo"],
      flag: "126-botswana.svg",
    },
    BV: {
      name: "Bouvet Island",
      currency: "NOK",
      lang: null,
      units: "2",
      prefix: "47",
      tz: null,
      flag: "143-norway.svg",
    },
    BR: {
      name: "Brazil",
      currency: "BRL",
      lang: "pt-BR,es,en,fr",
      units: "2",
      prefix: "55",
      tz: [
        "America/Noronha",
        "America/Belem",
        "America/Fortaleza",
        "America/Recife",
        "America/Araguaina",
        "America/Maceio",
        "America/Bahia",
        "America/Sao_Paulo",
        "America/Campo_Grande",
        "America/Cuiaba",
        "America/Santarem",
        "America/Porto_Velho",
        "America/Boa_Vista",
        "America/Manaus",
        "America/Eirunepe",
        "America/Rio_Branco",
      ],
      flag: "255-brazil.svg",
    },
    IO: {
      name: "British Indian Ocean Territory",
      currency: "USD",
      lang: "en-IO",
      units: "2",
      prefix: "246",
      tz: ["Indian/Chagos"],
    },
    VG: {
      name: "British Virgin Islands",
      currency: "USD",
      lang: "en-VG",
      units: "2",
      prefix: "1-284",
      tz: ["America/Port_of_Spain"],
    },
    BN: {
      name: "Brunei",
      currency: "BND",
      lang: "ms-BN,en-BN",
      units: "2",
      prefix: "673",
      tz: ["Asia/Brunei"],
      flag: "119-brunei.svg",
    },
    BG: {
      name: "Bulgaria",
      currency: "BGN",
      lang: "bg,tr-BG,rom",
      units: "2",
      prefix: "359",
      tz: ["Europe/Sofia"],
      flag: "168-bulgaria.svg",
    },
    BF: {
      name: "Burkina Faso",
      currency: "XOF",
      lang: "fr-BF",
      units: "0",
      prefix: "226",
      tz: ["Africa/Abidjan"],
      flag: "090-burkina-faso.svg",
    },
    BI: {
      name: "Burundi",
      currency: "BIF",
      lang: "fr-BI,rn",
      units: "0",
      prefix: "257",
      tz: ["Africa/Maputo"],
      flag: "057-burundi.svg",
    },
    CV: {
      name: "Cape Verde",
      currency: "CVE",
      lang: "pt-CV",
      units: "2",
      prefix: "238",
      tz: ["Atlantic/Cape_Verde"],
      flag: "038-cape-verde.svg",
    },
    KH: {
      name: "Cambodia",
      currency: "KHR",
      lang: "km,fr,en",
      units: "2",
      prefix: "855",
      tz: ["Asia/Bangkok"],
      flag: "159-cambodia.svg",
    },
    CM: {
      name: "Cameroon",
      currency: "XAF",
      lang: "en-CM,fr-CM",
      units: "0",
      prefix: "237",
      tz: ["Africa/Lagos"],
      flag: "105-cameroon.svg",
    },
    CA: {
      name: "Canada",
      currency: "CAD",
      lang: "en-CA,fr-CA,iu",
      units: "2",
      prefix: "1",
      tz: [
        "America/St_Johns",
        "America/Halifax",
        "America/Glace_Bay",
        "America/Moncton",
        "America/Goose_Bay",
        "America/Blanc-Sablon",
        "America/Toronto",
        "America/Nipigon",
        "America/Thunder_Bay",
        "America/Iqaluit",
        "America/Pangnirtung",
        "America/Resolute",
        "America/Atikokan",
        "America/Rankin_Inlet",
        "America/Winnipeg",
        "America/Rainy_River",
        "America/Regina",
        "America/Swift_Current",
        "America/Edmonton",
        "America/Cambridge_Bay",
        "America/Yellowknife",
        "America/Inuvik",
        "America/Creston",
        "America/Dawson_Creek",
        "America/Fort_Nelson",
        "America/Vancouver",
        "America/Whitehorse",
        "America/Dawson",
      ],
      flag: "226-united-states.svg",
    },
    KY: {
      name: "Cayman Islands",
      currency: "KYD",
      lang: "en-KY",
      units: "2",
      prefix: "1-345",
      tz: ["America/Cayman"],
    },
    CF: {
      name: "Central African Republic",
      currency: "XAF",
      lang: "fr-CF,sg,ln,kg",
      units: "0",
      prefix: "236",
      tz: ["Africa/Lagos"],
      flag: "036-central-african-republic.svg",
    },
    TD: {
      name: "Chad",
      currency: "XAF",
      lang: "fr-TD,ar-TD,sre",
      units: "0",
      prefix: "235",
      tz: ["Africa/Ndjamena"],
      flag: "066-chad.svg",
    },
    CL: {
      name: "Chile",
      currency: "CLP",
      lang: "es-CL",
      units: "0",
      prefix: "56",
      tz: ["America/Santiago", "Pacific/Easter"],
      flag: "131-chile.svg",
    },
    CN: {
      name: "China",
      currency: "CNY",
      lang: "zh-CN,yue,wuu,dta,ug,za",
      units: "2",
      prefix: "86",
      tz: ["Asia/Shanghai", "Asia/Urumqi"],
      flag: "034-china.svg\r\n",
    },
    HK: {
      name: "Hong Kong",
      currency: "HKD",
      lang: "zh-HK,yue,zh,en",
      units: "2",
      prefix: "852",
      tz: ["Asia/Hong_Kong"],
      flag: "183-hong-kong.svg",
    },
    MO: {
      name: "Macau",
      currency: "MOP",
      lang: "zh,zh-MO,pt",
      units: "2",
      prefix: "853",
      tz: ["Asia/Macau"],
      flag: "167-macao.svg",
    },
    CX: {
      name: "Christmas Island",
      currency: "AUD",
      lang: "en,zh,ms-CC",
      units: "2",
      prefix: "61",
      tz: ["Indian/Christmas"],
      flag: "234-australia.svg",
    },
    CC: {
      name: "Cocos (Keeling) Islands",
      currency: "AUD",
      lang: "ms-CC,en",
      units: "2",
      prefix: "61",
      tz: ["Indian/Cocos"],
      flag: "234-australia.svg",
    },
    CO: {
      name: "Colombia",
      currency: "COP",
      lang: "es-CO",
      units: "2",
      prefix: "57",
      tz: ["America/Bogota"],
      flag: "177-colombia.svg",
    },
    KM: {
      name: "Comoros",
      currency: "KMF",
      lang: "ar,fr-KM",
      units: "0",
      prefix: "269",
      tz: ["Africa/Nairobi"],
      flag: "029-comoros.svg",
    },
    CG: {
      name: "Congo - Brazzaville",
      currency: "XAF",
      lang: "fr-CG,kg,ln-CG",
      units: "0",
      prefix: "242",
      tz: ["Africa/Lagos"],
    },
    CK: {
      name: "Cook Islands",
      currency: "NZD",
      lang: "en-CK,mi",
      units: "2",
      prefix: "682",
      tz: ["Pacific/Rarotonga"],
      flag: "021-cook-islands.svg",
    },
    CR: {
      name: "Costa Rica",
      currency: "CRC",
      lang: "es-CR,en",
      units: "2",
      prefix: "506",
      tz: ["America/Costa_Rica"],
      flag: "156-costa-rica.svg",
    },
    HR: {
      name: "Croatia",
      currency: "HRK",
      lang: "hr-HR,sr",
      units: "2",
      prefix: "385",
      tz: ["Europe/Belgrade"],
      flag: "164-croatia.svg\r\n",
    },
    CU: {
      name: "Cuba",
      currency: "CUP,CUC",
      lang: "es-CU",
      units: "2,2",
      prefix: "53",
      tz: ["America/Havana"],
      flag: "153-cuba.svg",
    },
    CW: {
      name: "Curaçao",
      currency: "ANG",
      lang: "nl,pap",
      units: "2",
      prefix: "599",
      tz: ["America/Curacao"],
      flag: "237-netherlands.svg",
    },
    CY: {
      name: "Cyprus",
      currency: "EUR",
      lang: "el-CY,tr-CY,en",
      units: "2",
      prefix: "357",
      tz: ["Asia/Nicosia"],
      flag: "101-northern-cyprus.svg",
    },
    CZ: {
      name: "Czechia",
      currency: "CZK",
      lang: "cs,sk",
      units: "2",
      prefix: "420",
      tz: ["Europe/Prague"],
      flag: "149-czech-republic.svg",
    },
    CI: {
      name: "Côte d’Ivoire",
      currency: "XOF",
      lang: "fr-CI",
      units: "0",
      prefix: "225",
      tz: ["Africa/Abidjan"],
      flag: "161-ivory-coast.svg",
    },
    KP: {
      name: "North Korea",
      currency: "KPW",
      lang: "ko-KP",
      units: "2",
      prefix: "850",
      tz: ["Asia/Pyongyang"],
    },
    CD: {
      name: "Congo - Kinshasa",
      currency: "CDF",
      lang: "fr-CD,ln,kg",
      units: "2",
      prefix: "243",
      tz: ["Africa/Maputo", "Africa/Lagos"],
      flag: "249-democratic-republic-of-congo.svg",
    },
    DK: {
      name: "Denmark",
      currency: "DKK",
      lang: "da-DK,en,fo,de-DK",
      units: "2",
      prefix: "45",
      tz: ["Europe/Copenhagen"],
      flag: "174-denmark.svg",
    },
    DJ: {
      name: "Djibouti",
      currency: "DJF",
      lang: "fr-DJ,ar,so-DJ,aa",
      units: "0",
      prefix: "253",
      tz: ["Africa/Nairobi"],
      flag: "068-djibouti.svg",
    },
    DM: {
      name: "Dominica",
      currency: "XCD",
      lang: "en-DM",
      units: "2",
      prefix: "1-767",
      tz: ["America/Port_of_Spain"],
      flag: "186-dominica.svg",
    },
    DO: {
      name: "Dominican Republic",
      currency: "DOP",
      lang: "es-DO",
      units: "2",
      prefix: "1-809,1-829,1-849",
      tz: ["America/Santo_Domingo"],
    },
    EC: {
      name: "Ecuador",
      currency: "USD",
      lang: "es-EC",
      units: "2",
      prefix: "593",
      tz: ["America/Guayaquil", "Pacific/Galapagos"],
      flag: "104-ecuador.svg",
    },
    EG: {
      name: "Egypt",
      currency: "EGP",
      lang: "ar-EG,en,fr",
      units: "2",
      prefix: "20",
      tz: ["Africa/Cairo"],
      flag: "158-egypt.svg",
    },
    SV: {
      name: "El Salvador",
      currency: "SVC,USD",
      lang: "es-SV",
      units: "2,2",
      prefix: "503",
      tz: ["America/El_Salvador"],
      flag: "015-el-salvador.svg",
    },
    GQ: {
      name: "Equatorial Guinea",
      currency: "XAF",
      lang: "es-GQ,fr",
      units: "0",
      prefix: "240",
      tz: ["Africa/Lagos"],
      flag: "189-equatorial-guinea.svg",
    },
    ER: {
      name: "Eritrea",
      currency: "ERN",
      lang: "aa-ER,ar,tig,kun,ti-ER",
      units: "2",
      prefix: "291",
      tz: ["Africa/Nairobi"],
      flag: "065-eritrea.svg",
    },
    EE: {
      name: "Estonia",
      currency: "EUR",
      lang: "et,ru",
      units: "2",
      prefix: "372",
      tz: ["Europe/Tallinn"],
      flag: "008-estonia.svg",
    },
    ET: {
      name: "Ethiopia",
      currency: "ETB",
      lang: "am,en-ET,om-ET,ti-ET,so-ET,sid",
      units: "2",
      prefix: "251",
      tz: ["Africa/Nairobi"],
      flag: "005-ethiopia.svg",
    },
    FK: {
      name: "Falkland Islands",
      currency: null,
      lang: "en-FK",
      units: null,
      prefix: "500",
      tz: ["Atlantic/Stanley"],
      flag: "215-falkland-islands.svg",
    },
    FO: {
      name: "Faroe Islands",
      currency: "DKK",
      lang: "fo,da-FO",
      units: "2",
      prefix: "298",
      tz: ["Atlantic/Faroe"],
    },
    FJ: {
      name: "Fiji",
      currency: "FJD",
      lang: "en-FJ,fj",
      units: "2",
      prefix: "679",
      tz: ["Pacific/Fiji"],
      flag: "137-fiji.svg",
    },
    FI: {
      name: "Finland",
      currency: "EUR",
      lang: "fi-FI,sv-FI,smn",
      units: "2",
      prefix: "358",
      tz: ["Europe/Helsinki"],
      flag: "125-finland.svg",
    },
    FR: {
      name: "France",
      currency: "EUR",
      lang: "fr-FR,frp,br,co,ca,eu,oc",
      units: "2",
      prefix: "33",
      tz: ["Europe/Paris"],
      flag: "195-france.svg",
    },
    GF: {
      name: "French Guiana",
      currency: "EUR",
      lang: "fr-GF",
      units: "2",
      prefix: "594",
      tz: ["America/Cayenne"],
    },
    PF: {
      name: "French Polynesia",
      currency: "XPF",
      lang: "fr-PF,ty",
      units: "0",
      prefix: "689",
      tz: ["Pacific/Tahiti", "Pacific/Marquesas", "Pacific/Gambier"],
      flag: "180-french-polynesia.svg",
    },
    TF: {
      name: "French Southern Territories",
      currency: "EUR",
      lang: "fr",
      units: "2",
      prefix: "262",
      tz: ["Indian/Reunion", "Indian/Kerguelen"],
    },
    GA: {
      name: "Gabon",
      currency: "XAF",
      lang: "fr-GA",
      units: "0",
      prefix: "241",
      tz: ["Africa/Lagos"],
      flag: "059-gabon.svg",
    },
    GM: {
      name: "Gambia",
      currency: "GMD",
      lang: "en-GM,mnk,wof,wo,ff",
      units: "2",
      prefix: "220",
      tz: ["Africa/Abidjan"],
      flag: "146-gambia.svg",
    },
    GE: {
      name: "Georgia",
      currency: "GEL",
      lang: "ka,ru,hy,az",
      units: "2",
      prefix: "995",
      tz: ["Asia/Tbilisi"],
      flag: "256-georgia.svg",
    },
    DE: {
      name: "Germany",
      currency: "EUR",
      lang: "de",
      units: "2",
      prefix: "49",
      tz: ["Europe/Zurich", "Europe/Berlin"],
      flag: "162-germany.svg",
    },
    GH: {
      name: "Ghana",
      currency: "GHS",
      lang: "en-GH,ak,ee,tw",
      units: "2",
      prefix: "233",
      tz: ["Africa/Accra"],
      flag: "053-ghana.svg",
    },
    GI: {
      name: "Gibraltar",
      currency: "GIP",
      lang: "en-GI,es,it,pt",
      units: "2",
      prefix: "350",
      tz: ["Europe/Gibraltar"],
      flag: "213-gibraltar.svg",
    },
    GR: {
      name: "Greece",
      currency: "EUR",
      lang: "el-GR,en,fr",
      units: "2",
      prefix: "30",
      tz: ["Europe/Athens"],
      flag: "170-greece.svg",
    },
    GL: {
      name: "Greenland",
      currency: "DKK",
      lang: "kl,da-GL,en",
      units: "2",
      prefix: "299",
      tz: [
        "America/Godthab",
        "America/Danmarkshavn",
        "America/Scoresbysund",
        "America/Thule",
      ],
      flag: "113-greenland.svg",
    },
    GD: {
      name: "Grenada",
      currency: "XCD",
      lang: "en-GD",
      units: "2",
      prefix: "1-473",
      tz: ["America/Port_of_Spain"],
      flag: "210-grenada.svg",
    },
    GP: {
      name: "Guadeloupe",
      currency: "EUR",
      lang: "fr-GP",
      units: "2",
      prefix: "590",
      tz: ["America/Port_of_Spain"],
    },
    GU: {
      name: "Guam",
      currency: "USD",
      lang: "en-GU,ch-GU",
      units: "2",
      prefix: "1-671",
      tz: ["Pacific/Guam"],
      flag: "207-guam.svg",
    },
    GT: {
      name: "Guatemala",
      currency: "GTQ",
      lang: "es-GT",
      units: "2",
      prefix: "502",
      tz: ["America/Guatemala"],
      flag: "098-guatemala.svg",
    },
    GG: {
      name: "Guernsey",
      currency: "GBP",
      lang: "en,fr",
      units: "2",
      prefix: "44",
      tz: ["Europe/London"],
      flag: "260-united-kingdom.svg",
    },
    GN: {
      name: "Guinea",
      currency: "GNF",
      lang: "fr-GN",
      units: "0",
      prefix: "224",
      tz: ["Africa/Abidjan"],
      flag: "110-guinea.svg",
    },
    GW: {
      name: "Guinea-Bissau",
      currency: "XOF",
      lang: "pt-GW,pov",
      units: "0",
      prefix: "245",
      tz: ["Africa/Bissau"],
      flag: "056-guinea-bissau.svg",
    },
    GY: {
      name: "Guyana",
      currency: "GYD",
      lang: "en-GY",
      units: "2",
      prefix: "592",
      tz: ["America/Guyana"],
    },
    HT: {
      name: "Haiti",
      currency: "HTG,USD",
      lang: "ht,fr-HT",
      units: "2,2",
      prefix: "509",
      tz: ["America/Port-au-Prince"],
      flag: "185-haiti.svg",
    },
    HM: {
      name: "Heard & McDonald Islands",
      currency: "AUD",
      lang: null,
      units: "2",
      prefix: "672",
      tz: null,
    },
    VA: {
      name: "Vatican City",
      currency: "EUR",
      lang: "la,it,fr",
      units: "2",
      prefix: "39-06",
      tz: ["Europe/Rome"],
    },
    HN: {
      name: "Honduras",
      currency: "HNL",
      lang: "es-HN",
      units: "2",
      prefix: "504",
      tz: ["America/Tegucigalpa"],
      flag: "024-honduras.svg",
    },
    HU: {
      name: "Hungary",
      currency: "HUF",
      lang: "hu-HU",
      units: "2",
      prefix: "36",
      tz: ["Europe/Budapest"],
      flag: "115-hungary.svg",
    },
    IS: {
      name: "Iceland",
      currency: "ISK",
      lang: "is,en,de,da,sv,no",
      units: "0",
      prefix: "354",
      tz: ["Atlantic/Reykjavik"],
      flag: "080-iceland.svg",
    },
    IN: {
      name: "India",
      currency: "INR",
      lang: "en-IN,hi,bn,te,mr,ta,ur,gu,kn,ml,or,pa,as,bh,sat,ks,ne,sd,kok,doi,mni,sit,sa,fr,lus,inc",
      units: "2",
      prefix: "91",
      tz: ["Asia/Kolkata"],
      flag: "246-india.svg",
    },
    ID: {
      name: "Indonesia",
      currency: "IDR",
      lang: "id,en,nl,jv",
      units: "2",
      prefix: "62",
      tz: ["Asia/Jakarta", "Asia/Pontianak", "Asia/Makassar", "Asia/Jayapura"],
      flag: "209-indonesia.svg",
    },
    IR: {
      name: "Iran",
      currency: "IRR",
      lang: "fa-IR,ku",
      units: "2",
      prefix: "98",
      tz: ["Asia/Tehran"],
      flag: "136-iran.svg",
    },
    IQ: {
      name: "Iraq",
      currency: "IQD",
      lang: "ar-IQ,ku,hy",
      units: "3",
      prefix: "964",
      tz: ["Asia/Baghdad"],
      flag: "020-iraq.svg",
    },
    IE: {
      name: "Ireland",
      currency: "EUR",
      lang: "en-IE,ga-IE",
      units: "2",
      prefix: "353",
      tz: ["Europe/Dublin"],
      flag: "179-ireland.svg",
    },
    IM: {
      name: "Isle of Man",
      currency: "GBP",
      lang: "en,gv",
      units: "2",
      prefix: "44",
      tz: ["Europe/London"],
      flag: "260-united-kingdom.svg",
    },
    IL: {
      name: "Israel",
      currency: "ILS",
      lang: "he,ar-IL,en-IL,",
      units: "2",
      prefix: "972",
      tz: ["Asia/Jerusalem"],
      flag: "155-israel.svg",
    },
    IT: {
      name: "Italy",
      currency: "EUR",
      lang: "it-IT,de-IT,fr-IT,sc,ca,co,sl",
      units: "2",
      prefix: "39",
      tz: ["Europe/Rome"],
      flag: "013-italy.svg",
    },
    JM: {
      name: "Jamaica",
      currency: "JMD",
      lang: "en-JM",
      units: "2",
      prefix: "1-876",
      tz: ["America/Jamaica"],
      flag: "037-jamaica.svg",
    },
    JP: {
      name: "Japan",
      currency: "JPY",
      lang: "ja",
      units: "0",
      prefix: "81",
      tz: ["Asia/Tokyo"],
      flag: "063-japan.svg",
    },
    JE: {
      name: "Jersey",
      currency: "GBP",
      lang: "en,pt",
      units: "2",
      prefix: "44",
      tz: ["Europe/London"],
      flag: "260-united-kingdom.svg",
    },
    JO: {
      name: "Jordan",
      currency: "JOD",
      lang: "ar-JO,en",
      units: "3",
      prefix: "962",
      tz: ["Asia/Amman"],
      flag: "077-jordan.svg",
    },
    KZ: {
      name: "Kazakhstan",
      currency: "KZT",
      lang: "kk,ru",
      units: "2",
      prefix: "7",
      tz: [
        "Asia/Almaty",
        "Asia/Qyzylorda",
        "Asia/Aqtobe",
        "Asia/Aqtau",
        "Asia/Oral",
      ],
      flag: "248-russia.svg",
    },
    KE: {
      name: "Kenya",
      currency: "KES",
      lang: "en-KE,sw-KE",
      units: "2",
      prefix: "254",
      tz: ["Africa/Nairobi"],
      flag: "067-kenya.svg",
    },
    KI: {
      name: "Kiribati",
      currency: "AUD",
      lang: "en-KI,gil",
      units: "2",
      prefix: "686",
      tz: ["Pacific/Tarawa", "Pacific/Enderbury", "Pacific/Kiritimati"],
      flag: "261-kiribati.svg",
    },
    KW: {
      name: "Kuwait",
      currency: "KWD",
      lang: "ar-KW,en",
      units: "3",
      prefix: "965",
      tz: ["Asia/Riyadh"],
      flag: "107-kwait.svg",
    },
    KG: {
      name: "Kyrgyzstan",
      currency: "KGS",
      lang: "ky,uz,ru",
      units: "2",
      prefix: "996",
      tz: ["Asia/Bishkek"],
      flag: "152-kyrgyzstan.svg",
    },
    LA: {
      name: "Laos",
      currency: "LAK",
      lang: "lo,fr,en",
      units: "2",
      prefix: "856",
      tz: ["Asia/Bangkok"],
      flag: "112-laos.svg",
    },
    LV: {
      name: "Latvia",
      currency: "EUR",
      lang: "lv,ru,lt",
      units: "2",
      prefix: "371",
      tz: ["Europe/Riga"],
      flag: "044-latvia.svg",
    },
    LB: {
      name: "Lebanon",
      currency: "LBP",
      lang: "ar-LB,fr-LB,en,hy",
      units: "2",
      prefix: "961",
      tz: ["Asia/Beirut"],
      flag: "018-lebanon.svg",
    },
    LS: {
      name: "Lesotho",
      currency: "LSL,ZAR",
      lang: "en-LS,st,zu,xh",
      units: "2,2",
      prefix: "266",
      tz: ["Africa/Johannesburg"],
      flag: "176-lesotho.svg",
    },
    LR: {
      name: "Liberia",
      currency: "LRD",
      lang: "en-LR",
      units: "2",
      prefix: "231",
      tz: ["Africa/Monrovia"],
      flag: "169-liberia.svg",
    },
    LY: {
      name: "Libya",
      currency: "LYD",
      lang: "ar-LY,it,en",
      units: "3",
      prefix: "218",
      tz: ["Africa/Tripoli"],
      flag: "231-libya.svg",
    },
    LI: {
      name: "Liechtenstein",
      currency: "CHF",
      lang: "de-LI",
      units: "2",
      prefix: "423",
      tz: ["Europe/Zurich"],
      flag: "134-liechtenstein.svg",
    },
    LT: {
      name: "Lithuania",
      currency: "EUR",
      lang: "lt,ru,pl",
      units: "2",
      prefix: "370",
      tz: ["Europe/Vilnius"],
      flag: "064-lithuania.svg",
    },
    LU: {
      name: "Luxembourg",
      currency: "EUR",
      lang: "lb,de-LU,fr-LU",
      units: "2",
      prefix: "352",
      tz: ["Europe/Luxembourg"],
      flag: "035-luxembourg.svg",
    },
    MG: {
      name: "Madagascar",
      currency: "MGA",
      lang: "fr-MG,mg",
      units: "2",
      prefix: "261",
      tz: ["Africa/Nairobi"],
      flag: "242-madagascar.svg",
    },
    MW: {
      name: "Malawi",
      currency: "MWK",
      lang: "ny,yao,tum,swk",
      units: "2",
      prefix: "265",
      tz: ["Africa/Maputo"],
      flag: "214-malawi.svg",
    },
    MY: {
      name: "Malaysia",
      currency: "MYR",
      flag: "118-malasya.svg",
      lang: "ms-MY,en,zh,ta,te,ml,pa,th",
      units: "2",
      prefix: "60",
      tz: ["Asia/Kuala_Lumpur", "Asia/Kuching"],
    },
    MV: {
      name: "Maldives",
      currency: "MVR",
      lang: "dv,en",
      units: "2",
      prefix: "960",
      tz: ["Indian/Maldives"],
      flag: "225-maldives.svg",
    },
    ML: {
      name: "Mali",
      currency: "XOF",
      lang: "fr-ML,bm",
      units: "0",
      prefix: "223",
      tz: ["Africa/Abidjan"],
      flag: "173-mali.svg",
    },
    MT: {
      name: "Malta",
      currency: "EUR",
      lang: "mt,en-MT",
      units: "2",
      prefix: "356",
      tz: ["Europe/Malta"],
      flag: "194-malta.svg",
    },
    MH: {
      name: "Marshall Islands",
      currency: "USD",
      lang: "mh,en-MH",
      units: "2",
      prefix: "692",
      tz: ["Pacific/Majuro", "Pacific/Kwajalein"],
      flag: "103-marshall-island.svg",
    },
    MQ: {
      name: "Martinique",
      currency: "EUR",
      lang: "fr-MQ",
      units: "2",
      prefix: "596",
      tz: ["America/Martinique"],
      flag: "201-martinique.svg",
    },
    MR: {
      name: "Mauritania",
      currency: "MRO",
      lang: "ar-MR,fuc,snk,fr,mey,wo",
      units: "2",
      prefix: "222",
      tz: ["Africa/Abidjan"],
      flag: "050-mauritania.svg",
    },
    MU: {
      name: "Mauritius",
      currency: "MUR",
      lang: "en-MU,bho,fr",
      units: "2",
      prefix: "230",
      tz: ["Indian/Mauritius"],
      flag: "001-mauritius.svg",
    },
    YT: {
      name: "Mayotte",
      currency: "EUR",
      lang: "fr-YT",
      units: "2",
      prefix: "262",
      tz: ["Africa/Nairobi"],
    },
    MX: {
      name: "Mexico",
      currency: "MXN",
      lang: "es-MX",
      units: "2",
      prefix: "52",
      tz: [
        "America/Mexico_City",
        "America/Cancun",
        "America/Merida",
        "America/Monterrey",
        "America/Matamoros",
        "America/Mazatlan",
        "America/Chihuahua",
        "America/Ojinaga",
        "America/Hermosillo",
        "America/Tijuana",
        "America/Santa_Isabel",
        "America/Bahia_Banderas",
      ],
      flag: "252-mexico.svg",
    },
    FM: {
      name: "Micronesia",
      currency: "USD",
      lang: "en-FM,chk,pon,yap,kos,uli,woe,nkr,kpg",
      units: "2",
      prefix: "691",
      tz: ["Pacific/Chuuk", "Pacific/Pohnpei", "Pacific/Kosrae"],
      flag: "046-micronesia.svg",
    },
    MC: {
      name: "Monaco",
      currency: "EUR",
      lang: "fr-MC,en,it",
      units: "2",
      prefix: "377",
      tz: ["Europe/Monaco"],
      flag: "039-monaco.svg",
    },
    MN: {
      name: "Mongolia",
      currency: "MNT",
      lang: "mn,ru",
      units: "2",
      prefix: "976",
      tz: ["Asia/Ulaanbaatar", "Asia/Hovd", "Asia/Choibalsan"],
      flag: "258-mongolia.svg",
    },
    ME: {
      name: "Montenegro",
      currency: "EUR",
      lang: "sr,hu,bs,sq,hr,rom",
      units: "2",
      prefix: "382",
      tz: ["Europe/Belgrade"],
      flag: "239-montenegro.svg",
    },
    MS: {
      name: "Montserrat",
      currency: "XCD",
      lang: "en-MS",
      units: "2",
      prefix: "1-664",
      tz: ["America/Port_of_Spain"],
      flag: "043-montserrat.svg",
    },
    MA: {
      name: "Morocco",
      currency: "MAD",
      lang: "ar-MA,ber,fr",
      units: "2",
      prefix: "212",
      tz: ["Africa/Casablanca"],
      flag: "166-morocco.svg",
    },
    MZ: {
      name: "Mozambique",
      currency: "MZN",
      lang: "pt-MZ,vmw",
      units: "2",
      prefix: "258",
      tz: ["Africa/Maputo"],
      flag: "096-mozambique.svg",
    },
    MM: {
      name: "Myanmar",
      currency: "MMK",
      lang: "my",
      units: "2",
      prefix: "95",
      tz: ["Asia/Rangoon"],
      flag: "058-myanmar.svg",
    },
    NA: {
      name: "Namibia",
      currency: "NAD,ZAR",
      lang: "en-NA,af,de,hz,naq",
      units: "2,2",
      prefix: "264",
      tz: ["Africa/Windhoek"],
      flag: "062-namibia.svg",
    },
    NR: {
      name: "Nauru",
      currency: "AUD",
      lang: "na,en-NR",
      units: "2",
      prefix: "674",
      tz: ["Pacific/Nauru"],
      flag: "228-nauru.svg",
    },
    NP: {
      name: "Nepal",
      currency: "NPR",
      lang: "ne,en",
      units: "2",
      prefix: "977",
      tz: ["Asia/Kathmandu"],
      flag: "016-nepal.svg",
    },
    NL: {
      name: "Netherlands",
      currency: "EUR",
      lang: "nl-NL,fy-NL",
      units: "2",
      prefix: "31",
      tz: ["Europe/Amsterdam"],
      flag: "237-netherlands.svg",
    },
    NC: {
      name: "New Caledonia",
      currency: "XPF",
      lang: "fr-NC",
      units: "0",
      prefix: "687",
      tz: ["Pacific/Noumea"],
    },
    NZ: {
      name: "New Zealand",
      currency: "NZD",
      lang: "en-NZ,mi",
      units: "2",
      prefix: "64",
      tz: ["Pacific/Auckland", "Pacific/Chatham"],
      flag: "121-new-zealand.svg",
    },
    NI: {
      name: "Nicaragua",
      currency: "NIO",
      lang: "es-NI,en",
      units: "2",
      prefix: "505",
      tz: ["America/Managua"],
      flag: "007-nicaragua.svg",
    },
    NE: {
      name: "Niger",
      currency: "XOF",
      lang: "fr-NE,ha,kr,dje",
      units: "0",
      prefix: "227",
      tz: ["Africa/Lagos"],
      flag: "222-niger.svg",
    },
    NG: {
      name: "Nigeria",
      currency: "NGN",
      lang: "en-NG,ha,yo,ig,ff",
      units: "2",
      prefix: "234",
      tz: ["Africa/Lagos"],
      flag: "086-nigeria.svg",
    },
    NU: {
      name: "Niue",
      currency: "NZD",
      lang: "niu,en-NU",
      units: "2",
      prefix: "683",
      tz: ["Pacific/Niue"],
      flag: "182-niue.svg",
    },
    NF: {
      name: "Norfolk Island",
      currency: "AUD",
      lang: "en-NF",
      units: "2",
      prefix: "672",
      tz: ["Pacific/Norfolk"],
    },
    MP: {
      name: "Northern Mariana Islands",
      currency: "USD",
      lang: "fil,tl,zh,ch-MP,en-MP",
      units: "2",
      prefix: "1-670",
      tz: ["Pacific/Guam"],
    },
    NO: {
      name: "Norway",
      currency: "NOK",
      lang: "no,nb,nn,se,fi",
      units: "2",
      prefix: "47",
      tz: ["Europe/Oslo"],
      flag: "143-norway.svg",
    },
    OM: {
      name: "Oman",
      currency: "OMR",
      lang: "ar-OM,en,bal,ur",
      units: "3",
      prefix: "968",
      tz: ["Asia/Dubai"],
      flag: "004-oman.svg",
    },
    PK: {
      name: "Pakistan",
      currency: "PKR",
      lang: "ur-PK,en-PK,pa,sd,ps,brh",
      units: "2",
      prefix: "92",
      tz: ["Asia/Karachi"],
      flag: "100-pakistan.svg",
    },
    PW: {
      name: "Palau",
      currency: "USD",
      lang: "pau,sov,en-PW,tox,ja,fil,zh",
      units: "2",
      prefix: "680",
      tz: ["Pacific/Palau"],
      flag: "178-palau.svg",
    },
    PA: {
      name: "Panama",
      currency: "PAB,USD",
      lang: "es-PA,en",
      units: "2,2",
      prefix: "507",
      tz: ["America/Panama"],
      flag: "106-panama.svg",
    },
    PG: {
      name: "Papua New Guinea",
      currency: "PGK",
      lang: "en-PG,ho,meu,tpi",
      units: "2",
      prefix: "675",
      tz: ["Pacific/Port_Moresby", "Pacific/Bougainville"],
      flag: "163-papua-new-guinea.svg",
    },
    PY: {
      name: "Paraguay",
      currency: "PYG",
      lang: "es-PY,gn",
      units: "0",
      prefix: "595",
      tz: ["America/Asuncion"],
      flag: "041-paraguay.svg",
    },
    PE: {
      name: "Peru",
      currency: "PEN",
      lang: "es-PE,qu,ay",
      units: "2",
      prefix: "51",
      tz: ["America/Lima"],
      flag: "188-peru.svg",
    },
    PH: {
      name: "Philippines",
      currency: "PHP",
      lang: "tl,en-PH,fil",
      units: "2",
      prefix: "63",
      tz: ["Asia/Manila"],
      flag: "192-philippines.svg",
    },
    PN: {
      name: "Pitcairn Islands",
      currency: "NZD",
      lang: "en-PN",
      units: "2",
      prefix: "870",
      tz: ["Pacific/Pitcairn"],
    },
    PL: {
      name: "Poland",
      currency: "PLN",
      lang: "pl",
      units: "2",
      prefix: "48",
      tz: ["Europe/Warsaw"],
      flag: "211-poland.svg",
    },
    PT: {
      name: "Portugal",
      currency: "EUR",
      lang: "pt-PT,mwl",
      units: "2",
      prefix: "351",
      tz: ["Europe/Lisbon", "Atlantic/Madeira", "Atlantic/Azores"],
      flag: "224-portugal.svg",
    },
    PR: {
      name: "Puerto Rico",
      currency: "USD",
      lang: "en-PR,es-PR",
      units: "2",
      prefix: "1",
      tz: ["America/Puerto_Rico"],
      flag: "226-united-states.svg",
    },
    QA: {
      name: "Qatar",
      currency: "QAR",
      lang: "ar-QA,es",
      units: "2",
      prefix: "974",
      tz: ["Asia/Qatar"],
      flag: "026-qatar.svg",
    },
    KR: {
      name: "South Korea",
      currency: "KRW",
      lang: "ko-KR,en",
      units: "0",
      prefix: "82",
      tz: ["Asia/Seoul"],
      flag: "094-south-korea.svg",
    },
    MD: {
      name: "Moldova",
      currency: "MDL",
      lang: "ro,ru,gag,tr",
      units: "2",
      prefix: "373",
      tz: ["Europe/Chisinau"],
      flag: "212-moldova.svg",
    },
    RO: {
      name: "Romania",
      currency: "RON",
      lang: "ro,hu,rom",
      units: "2",
      prefix: "40",
      tz: ["Europe/Bucharest"],
      flag: "109-romania.svg",
    },
    RU: {
      name: "Russia",
      currency: "RUB",
      lang: "ru,tt,xal,cau,ady,kv,ce,tyv,cv,udm,tut,mns,bua,myv,mdf,chm,ba,inh,tut,kbd,krc,av,sah,nog",
      units: "2",
      prefix: "7",
      tz: [
        "Europe/Kaliningrad",
        "Europe/Moscow",
        "Europe/Simferopol",
        "Europe/Volgograd",
        "Europe/Samara",
        "Asia/Yekaterinburg",
        "Asia/Omsk",
        "Asia/Novosibirsk",
        "Asia/Novokuznetsk",
        "Asia/Krasnoyarsk",
        "Asia/Irkutsk",
        "Asia/Chita",
        "Asia/Yakutsk",
        "Asia/Khandyga",
        "Asia/Vladivostok",
        "Asia/Sakhalin",
        "Asia/Ust-Nera",
        "Asia/Magadan",
        "Asia/Srednekolymsk",
        "Asia/Kamchatka",
        "Asia/Anadyr",
      ],
      flag: "248-russia.svg",
    },
    RW: {
      name: "Rwanda",
      currency: "RWF",
      lang: "rw,en-RW,fr-RW,sw",
      units: "0",
      prefix: "250",
      tz: ["Africa/Maputo"],
      flag: "206-rwanda.svg",
    },
    RE: {
      name: "Réunion",
      currency: "EUR",
      lang: "fr-RE",
      units: "2",
      prefix: "262",
      tz: ["Indian/Reunion"],
    },
    BL: {
      name: "St. Barthélemy",
      currency: "EUR",
      lang: "fr",
      units: "2",
      prefix: "590",
      tz: ["America/Port_of_Spain"],
    },
    SH: {
      name: "St. Helena",
      currency: "SHP",
      lang: "en-SH",
      units: "2",
      prefix: "290",
      tz: ["Africa/Abidjan"],
    },
    KN: {
      name: "St. Kitts & Nevis",
      currency: "XCD",
      lang: "en-KN",
      units: "2",
      prefix: "1-869",
      tz: ["America/Port_of_Spain"],
    },
    LC: {
      name: "St. Lucia",
      currency: "XCD",
      lang: "en-LC",
      units: "2",
      prefix: "1-758",
      tz: ["America/Port_of_Spain"],
    },
    MF: {
      name: "St. Martin",
      currency: "EUR",
      lang: "fr",
      units: "2",
      prefix: "590",
      tz: ["America/Port_of_Spain"],
    },
    PM: {
      name: "St. Pierre & Miquelon",
      currency: "EUR",
      lang: "fr-PM",
      units: "2",
      prefix: "508",
      tz: ["America/Miquelon"],
    },
    VC: {
      name: "St. Vincent & Grenadines",
      currency: "XCD",
      lang: "en-VC,fr",
      units: "2",
      prefix: "1-784",
      tz: ["America/Port_of_Spain"],
    },
    WS: {
      name: "Samoa",
      currency: "WST",
      lang: "sm,en-WS",
      units: "2",
      prefix: "685",
      tz: ["Pacific/Apia"],
      flag: "251-samoa.svg",
    },
    SM: {
      name: "San Marino",
      currency: "EUR",
      lang: "it-SM",
      units: "2",
      prefix: "378",
      tz: ["Europe/Rome"],
      flag: "097-san-marino.svg",
    },
    ST: {
      name: "São Tomé & Príncipe",
      currency: "STD",
      lang: "pt-ST",
      units: "2",
      prefix: "239",
      tz: ["Africa/Abidjan"],
      flag: "012-sao-tome-and-prince.svg",
    },
    SA: {
      name: "Saudi Arabia",
      currency: "SAR",
      lang: "ar-SA",
      units: "2",
      prefix: "966",
      tz: ["Asia/Riyadh"],
      flag: "133-saudi-arabia.svg",
    },
    SN: {
      name: "Senegal",
      currency: "XOF",
      lang: "fr-SN,wo,fuc,mnk",
      units: "0",
      prefix: "221",
      tz: ["Africa/Abidjan"],
      flag: "227-senegal.svg",
    },
    RS: {
      name: "Serbia",
      currency: "RSD",
      lang: "sr,hu,bs,rom",
      units: "2",
      prefix: "381",
      tz: ["Europe/Belgrade"],
      flag: "071-serbia.svg",
    },
    SC: {
      name: "Seychelles",
      currency: "SCR",
      lang: "en-SC,fr-SC",
      units: "2",
      prefix: "248",
      tz: ["Indian/Mahe"],
      flag: "253-seychelles.svg",
    },
    SL: {
      name: "Sierra Leone",
      currency: "SLL",
      lang: "en-SL,men,tem",
      units: "2",
      prefix: "232",
      tz: ["Africa/Abidjan"],
      flag: "092-sierra-leone.svg",
    },
    SG: {
      name: "Singapore",
      currency: "SGD",
      lang: "cmn,en-SG,ms-SG,ta-SG,zh-SG",
      units: "2",
      prefix: "65",
      tz: ["Asia/Singapore"],
      flag: "230-singapore.svg",
    },
    SX: {
      name: "Sint Maarten",
      currency: "ANG",
      lang: "nl,en",
      units: "2",
      prefix: "1-721",
      tz: ["America/Curacao"],
    },
    SK: {
      name: "Slovakia",
      currency: "EUR",
      lang: "sk,hu",
      units: "2",
      prefix: "421",
      tz: ["Europe/Prague"],
      flag: "091-slovakia.svg",
    },
    SI: {
      name: "Slovenia",
      currency: "EUR",
      lang: "sl,sh",
      units: "2",
      prefix: "386",
      tz: ["Europe/Belgrade"],
      flag: "010-slovenia.svg",
    },
    SB: {
      name: "Solomon Islands",
      currency: "SBD",
      lang: "en-SB,tpi",
      units: "2",
      prefix: "677",
      tz: ["Pacific/Guadalcanal"],
      flag: "085-solomon-islands.svg",
    },
    SO: {
      name: "Somalia",
      currency: "SOS",
      lang: "so-SO,ar-SO,it,en-SO",
      units: "2",
      prefix: "252",
      tz: ["Africa/Nairobi"],
      flag: "083-somalia.svg",
    },
    ZA: {
      name: "South Africa",
      currency: "ZAR",
      lang: "zu,xh,af,nso,en-ZA,tn,st,ts,ss,ve,nr",
      units: "2",
      prefix: "27",
      tz: ["Africa/Johannesburg"],
      flag: "200-south-africa.svg",
    },
    GS: {
      name: "South Georgia & South Sandwich Islands",
      currency: null,
      lang: "en",
      units: null,
      prefix: "500",
      tz: ["Atlantic/South_Georgia"],
      flag: "215-falkland-islands.svg",
    },
    SS: {
      name: "South Sudan",
      currency: "SSP",
      lang: "en",
      units: "2",
      prefix: "211",
      tz: ["Africa/Khartoum"],
      flag: "244-south-sudan.svg",
    },
    ES: {
      name: "Spain",
      currency: "EUR",
      lang: "es-ES,ca,gl,eu,oc",
      units: "2",
      prefix: "34",
      tz: ["Europe/Madrid", "Africa/Ceuta", "Atlantic/Canary"],
      flag: "128-spain.svg",
    },
    LK: {
      name: "Sri Lanka",
      currency: "LKR",
      lang: "si,ta,en",
      units: "2",
      prefix: "94",
      tz: ["Asia/Colombo"],
      flag: "127-sri-lanka.svg",
    },
    PS: {
      name: "Palestine",
      currency: null,
      lang: "ar-PS",
      units: null,
      prefix: "970",
      tz: ["Asia/Gaza", "Asia/Hebron"],
      flag: "208-palestine.svg",
    },
    SD: {
      name: "Sudan",
      currency: "SDG",
      lang: "ar-SD,en,fia",
      units: "2",
      prefix: "249",
      tz: ["Africa/Khartoum"],
      flag: "199-sudan.svg",
    },
    SR: {
      name: "Suriname",
      currency: "SRD",
      lang: "nl-SR,en,srn,hns,jv",
      units: "2",
      prefix: "597",
      tz: ["America/Paramaribo"],
      flag: "076-suriname.svg",
    },
    SJ: {
      name: "Svalbard & Jan Mayen",
      currency: "NOK",
      lang: "no,ru",
      units: "2",
      prefix: "47",
      tz: ["Europe/Oslo"],
      flag: "143-norway.svg",
    },
    SZ: {
      name: "Swaziland",
      currency: "SZL",
      lang: "en-SZ,ss-SZ",
      units: "2",
      prefix: "268",
      tz: ["Africa/Johannesburg"],
      flag: "154-swaziland.svg",
    },
    SE: {
      name: "Sweden",
      currency: "SEK",
      lang: "sv-SE,se,sma,fi-SE",
      units: "2",
      prefix: "46",
      tz: ["Europe/Stockholm"],
      flag: "184-sweden.svg",
    },
    CH: {
      name: "Switzerland",
      currency: "CHF",
      lang: "de-CH,fr-CH,it-CH,rm",
      units: "2",
      prefix: "41",
      tz: ["Europe/Zurich"],
      flag: "205-switzerland.svg",
    },
    SY: {
      name: "Syria",
      currency: "SYP",
      lang: "ar-SY,ku,hy,arc,fr,en",
      units: "2",
      prefix: "963",
      tz: ["Asia/Damascus"],
      flag: "022-syria.svg",
    },
    TJ: {
      name: "Tajikistan",
      currency: "TJS",
      lang: "tg,ru",
      units: "2",
      prefix: "992",
      tz: ["Asia/Dushanbe"],
      flag: "196-tajikistan.svg",
    },
    TH: {
      name: "Thailand",
      currency: "THB",
      lang: "th,en",
      units: "2",
      prefix: "66",
      tz: ["Asia/Bangkok"],
      flag: "238-thailand.svg",
    },
    MK: {
      name: "Macedonia",
      currency: "MKD",
      lang: "mk,sq,tr,rmm,sr",
      units: "2",
      prefix: "389",
      tz: ["Europe/Belgrade"],
      flag: "236-republic-of-macedonia.svg",
    },
    TL: {
      name: "Timor-Leste",
      currency: "USD",
      lang: "tet,pt-TL,id,en",
      units: "2",
      prefix: "670",
      tz: ["Asia/Dili"],
      flag: "140-east-timor.svg",
    },
    TG: {
      name: "Togo",
      currency: "XOF",
      lang: "fr-TG,ee,hna,kbp,dag,ha",
      units: "0",
      prefix: "228",
      tz: ["Africa/Abidjan"],
      flag: "073-togo.svg",
    },
    TK: {
      name: "Tokelau",
      currency: "NZD",
      lang: "tkl,en-TK",
      units: "2",
      prefix: "690",
      tz: ["Pacific/Fakaofo"],
      flag: "235-tokelau.svg",
    },
    TO: {
      name: "Tonga",
      currency: "TOP",
      lang: "to,en-TO",
      units: "2",
      prefix: "676",
      tz: ["Pacific/Tongatapu"],
      flag: "191-tonga.svg",
    },
    TT: {
      name: "Trinidad & Tobago",
      currency: "TTD",
      lang: "en-TT,hns,fr,es,zh",
      units: "2",
      prefix: "1-868",
      tz: ["America/Port_of_Spain"],
    },
    TN: {
      name: "Tunisia",
      currency: "TND",
      lang: "ar-TN,fr",
      units: "3",
      prefix: "216",
      tz: ["Africa/Tunis"],
      flag: "049-tunisia.svg",
    },
    TR: {
      name: "Turkey",
      currency: "TRY",
      lang: "tr-TR,ku,diq,az,av",
      units: "2",
      prefix: "90",
      tz: ["Europe/Istanbul"],
      flag: "218-turkey.svg",
    },
    TM: {
      name: "Turkmenistan",
      currency: "TMT",
      lang: "tk,ru,uz",
      units: "2",
      prefix: "993",
      tz: ["Asia/Ashgabat"],
      flag: "229-turkmenistan.svg",
    },
    TC: {
      name: "Turks & Caicos Islands",
      currency: "USD",
      lang: "en-TC",
      units: "2",
      prefix: "1-649",
      tz: ["America/Grand_Turk"],
    },
    TV: {
      name: "Tuvalu",
      currency: "AUD",
      lang: "tvl,en,sm,gil",
      units: "2",
      prefix: "688",
      tz: ["Pacific/Funafuti"],
      flag: "070-tuvalu-1.svg",
    },
    UG: {
      name: "Uganda",
      currency: "UGX",
      lang: "en-UG,lg,sw,ar",
      units: "0",
      prefix: "256",
      tz: ["Africa/Nairobi"],
      flag: "009-uganda.svg",
    },
    UA: {
      name: "Ukraine",
      currency: "UAH",
      lang: "uk,ru-UA,rom,pl,hu",
      units: "2",
      prefix: "380",
      tz: ["Europe/Kiev", "Europe/Uzhgorod", "Europe/Zaporozhye"],
      flag: "145-ukraine.svg",
    },
    AE: {
      name: "United Arab Emirates",
      currency: "AED",
      lang: "ar-AE,fa,en,hi,ur",
      units: "2",
      prefix: "971",
      tz: ["Asia/Dubai"],
      flag: "151-united-arab-emirates.svg",
    },
    GB: {
      name: "UK",
      currency: "GBP",
      lang: "en-GB,cy-GB,gd",
      units: "2",
      prefix: "44",
      tz: ["Europe/London"],
      flag: "260-united-kingdom.svg",
    },
    TZ: {
      name: "Tanzania",
      currency: "TZS",
      lang: "sw-TZ,en,ar",
      units: "2",
      prefix: "255",
      tz: ["Africa/Nairobi"],
      flag: "006-tanzania.svg",
    },
    UM: {
      name: "U.S. Outlying Islands",
      currency: "USD",
      lang: "en-UM",
      units: "2",
      prefix: null,
      tz: ["Pacific/Pago_Pago", "Pacific/Wake", "Pacific/Honolulu"],
    },
    VI: {
      name: "U.S. Virgin Islands",
      currency: "USD",
      lang: "en-VI",
      units: "2",
      prefix: "1-340",
      tz: ["America/Port_of_Spain"],
    },
    US: {
      name: "US",
      currency: "USD",
      lang: "en-US,es-US,haw,fr",
      units: "2",
      prefix: "1",
      tz: [
        "America/New_York",
        "America/Detroit",
        "America/Kentucky/Louisville",
        "America/Kentucky/Monticello",
        "America/Indiana/Indianapolis",
        "America/Indiana/Vincennes",
        "America/Indiana/Winamac",
        "America/Indiana/Marengo",
        "America/Indiana/Petersburg",
        "America/Indiana/Vevay",
        "America/Chicago",
        "America/Indiana/Tell_City",
        "America/Indiana/Knox",
        "America/Menominee",
        "America/North_Dakota/Center",
        "America/North_Dakota/New_Salem",
        "America/North_Dakota/Beulah",
        "America/Denver",
        "America/Boise",
        "America/Phoenix",
        "America/Los_Angeles",
        "America/Metlakatla",
        "America/Anchorage",
        "America/Juneau",
        "America/Sitka",
        "America/Yakutat",
        "America/Nome",
        "America/Adak",
        "Pacific/Honolulu",
      ],
      flag: "226-united-states.svg",
    },
    UY: {
      name: "Uruguay",
      currency: "UYU",
      lang: "es-UY",
      units: "2",
      prefix: "598",
      tz: ["America/Montevideo"],
      flag: "088-uruguay.svg",
    },
    UZ: {
      name: "Uzbekistan",
      currency: "UZS",
      lang: "uz,ru,tg",
      units: "2",
      prefix: "998",
      tz: ["Asia/Samarkand", "Asia/Tashkent"],
      flag: "190-uzbekistn.svg",
    },
    VU: {
      name: "Vanuatu",
      currency: "VUV",
      lang: "bi,en-VU,fr-VU",
      units: "0",
      prefix: "678",
      tz: ["Pacific/Efate"],
      flag: "187-vanuatu.svg",
    },
    VE: {
      name: "Venezuela",
      currency: "VEF",
      lang: "es-VE",
      units: "2",
      prefix: "58",
      tz: ["America/Caracas"],
      flag: "139-venezuela.svg",
    },
    VN: {
      name: "Vietnam",
      currency: "VND",
      lang: "vi,en,fr,zh,km",
      units: "0",
      prefix: "84",
      tz: ["Asia/Bangkok", "Asia/Ho_Chi_Minh"],
      flag: "220-vietnam.svg",
    },
    WF: {
      name: "Wallis & Futuna",
      currency: "XPF",
      lang: "wls,fud,fr-WF",
      units: "0",
      prefix: "681",
      tz: ["Pacific/Wallis"],
    },
    EH: {
      name: "Western Sahara",
      currency: "MAD",
      lang: "ar,mey",
      units: "2",
      prefix: "212",
      tz: ["Africa/El_Aaiun"],
      flag: "166-morocco.svg",
    },
    YE: {
      name: "Yemen",
      currency: "YER",
      lang: "ar-YE",
      units: "2",
      prefix: "967",
      tz: ["Asia/Riyadh"],
      flag: "232-yemen.svg",
    },
    ZM: {
      name: "Zambia",
      currency: "ZMW",
      lang: "en-ZM,bem,loz,lun,lue,ny,toi",
      units: "2",
      prefix: "260",
      tz: ["Africa/Maputo"],
      flag: "032-zambia.svg",
    },
    ZW: {
      name: "Zimbabwe",
      currency: "ZWL",
      lang: "en-ZW,sn,nr,nd",
      units: "2",
      prefix: "263",
      tz: ["Africa/Maputo"],
      flag: "011-zimbabwe.svg",
    },
    AX: {
      name: "Åland Islands",
      currency: "EUR",
      lang: "sv-AX",
      units: "2",
      prefix: "358",
      tz: ["Europe/Helsinki"],
      flag: "125-finland.svg",
    },
  },
  tzs: {
    "Europe/Andorra": {
      name: "Europe/Andorra",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["AD"],
    },
    "Asia/Dubai": {
      name: "Asia/Dubai",
      utcOffset: 240,
      offsetStr: "+04:00",
      countries: ["AE", "OM"],
    },
    "Asia/Kabul": {
      name: "Asia/Kabul",
      utcOffset: 270,
      offsetStr: "+04:30",
      countries: ["AF"],
    },
    "Europe/Tirane": {
      name: "Europe/Tirane",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["AL"],
    },
    "Asia/Yerevan": {
      name: "Asia/Yerevan",
      utcOffset: 240,
      offsetStr: "+04:00",
      countries: ["AM"],
    },
    "Antarctica/Rothera": {
      name: "Antarctica/Rothera",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["AQ"],
    },
    "Antarctica/Palmer": {
      name: "Antarctica/Palmer",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["AQ"],
    },
    "Antarctica/Mawson": {
      name: "Antarctica/Mawson",
      utcOffset: 300,
      offsetStr: "+05:00",
      countries: ["AQ"],
    },
    "Antarctica/Davis": {
      name: "Antarctica/Davis",
      utcOffset: 420,
      offsetStr: "+07:00",
      countries: ["AQ"],
    },
    "Antarctica/Casey": {
      name: "Antarctica/Casey",
      utcOffset: 480,
      offsetStr: "+08:00",
      countries: ["AQ"],
    },
    "Antarctica/Vostok": {
      name: "Antarctica/Vostok",
      utcOffset: 360,
      offsetStr: "+06:00",
      countries: ["AQ"],
    },
    "Antarctica/DumontDUrville": {
      name: "Antarctica/DumontDUrville",
      utcOffset: 600,
      offsetStr: "+10:00",
      countries: ["AQ"],
    },
    "Antarctica/Syowa": {
      name: "Antarctica/Syowa",
      utcOffset: 180,
      offsetStr: "+03:00",
      countries: ["AQ"],
    },
    "Antarctica/Troll": {
      name: "Antarctica/Troll",
      utcOffset: 0,
      offsetStr: "+00:00",
      countries: ["AQ"],
    },
    "America/Argentina/Buenos_Aires": {
      name: "America/Argentina/Buenos_Aires",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["AR"],
    },
    "America/Argentina/Cordoba": {
      name: "America/Argentina/Cordoba",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["AR"],
    },
    "America/Argentina/Salta": {
      name: "America/Argentina/Salta",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["AR"],
    },
    "America/Argentina/Jujuy": {
      name: "America/Argentina/Jujuy",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["AR"],
    },
    "America/Argentina/Tucuman": {
      name: "America/Argentina/Tucuman",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["AR"],
    },
    "America/Argentina/Catamarca": {
      name: "America/Argentina/Catamarca",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["AR"],
    },
    "America/Argentina/La_Rioja": {
      name: "America/Argentina/La_Rioja",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["AR"],
    },
    "America/Argentina/San_Juan": {
      name: "America/Argentina/San_Juan",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["AR"],
    },
    "America/Argentina/Mendoza": {
      name: "America/Argentina/Mendoza",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["AR"],
    },
    "America/Argentina/San_Luis": {
      name: "America/Argentina/San_Luis",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["AR"],
    },
    "America/Argentina/Rio_Gallegos": {
      name: "America/Argentina/Rio_Gallegos",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["AR"],
    },
    "America/Argentina/Ushuaia": {
      name: "America/Argentina/Ushuaia",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["AR"],
    },
    "Pacific/Pago_Pago": {
      name: "Pacific/Pago_Pago",
      utcOffset: -660,
      offsetStr: "-11:00",
      countries: ["AS", "UM"],
    },
    "Europe/Vienna": {
      name: "Europe/Vienna",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["AT"],
    },
    "Australia/Lord_Howe": {
      name: "Australia/Lord_Howe",
      utcOffset: 660,
      offsetStr: "+11:00",
      countries: ["AU"],
    },
    "Antarctica/Macquarie": {
      name: "Antarctica/Macquarie",
      utcOffset: 660,
      offsetStr: "+11:00",
      countries: ["AU"],
    },
    "Australia/Hobart": {
      name: "Australia/Hobart",
      utcOffset: 660,
      offsetStr: "+11:00",
      countries: ["AU"],
    },
    "Australia/Currie": {
      name: "Australia/Currie",
      utcOffset: 660,
      offsetStr: "+11:00",
      countries: ["AU"],
    },
    "Australia/Melbourne": {
      name: "Australia/Melbourne",
      utcOffset: 660,
      offsetStr: "+11:00",
      countries: ["AU"],
    },
    "Australia/Sydney": {
      name: "Australia/Sydney",
      utcOffset: 660,
      offsetStr: "+11:00",
      countries: ["AU"],
    },
    "Australia/Broken_Hill": {
      name: "Australia/Broken_Hill",
      utcOffset: 630,
      offsetStr: "+10:30",
      countries: ["AU"],
    },
    "Australia/Brisbane": {
      name: "Australia/Brisbane",
      utcOffset: 600,
      offsetStr: "+10:00",
      countries: ["AU"],
    },
    "Australia/Lindeman": {
      name: "Australia/Lindeman",
      utcOffset: 600,
      offsetStr: "+10:00",
      countries: ["AU"],
    },
    "Australia/Adelaide": {
      name: "Australia/Adelaide",
      utcOffset: 630,
      offsetStr: "+10:30",
      countries: ["AU"],
    },
    "Australia/Darwin": {
      name: "Australia/Darwin",
      utcOffset: 570,
      offsetStr: "+09:30",
      countries: ["AU"],
    },
    "Australia/Perth": {
      name: "Australia/Perth",
      utcOffset: 480,
      offsetStr: "+08:00",
      countries: ["AU"],
    },
    "Australia/Eucla": {
      name: "Australia/Eucla",
      utcOffset: 525,
      offsetStr: "+08:45",
      countries: ["AU"],
    },
    "Asia/Baku": {
      name: "Asia/Baku",
      utcOffset: 240,
      offsetStr: "+04:00",
      countries: ["AZ"],
    },
    "America/Barbados": {
      name: "America/Barbados",
      utcOffset: -240,
      offsetStr: "-04:00",
      countries: ["BB"],
    },
    "Asia/Dhaka": {
      name: "Asia/Dhaka",
      utcOffset: 360,
      offsetStr: "+06:00",
      countries: ["BD"],
    },
    "Europe/Brussels": {
      name: "Europe/Brussels",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["BE"],
    },
    "Europe/Sofia": {
      name: "Europe/Sofia",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["BG"],
    },
    "Atlantic/Bermuda": {
      name: "Atlantic/Bermuda",
      utcOffset: -240,
      offsetStr: "-04:00",
      countries: ["BM"],
    },
    "Asia/Brunei": {
      name: "Asia/Brunei",
      utcOffset: 480,
      offsetStr: "+08:00",
      countries: ["BN"],
    },
    "America/La_Paz": {
      name: "America/La_Paz",
      utcOffset: -240,
      offsetStr: "-04:00",
      countries: ["BO"],
    },
    "America/Noronha": {
      name: "America/Noronha",
      utcOffset: -120,
      offsetStr: "-02:00",
      countries: ["BR"],
    },
    "America/Belem": {
      name: "America/Belem",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["BR"],
    },
    "America/Fortaleza": {
      name: "America/Fortaleza",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["BR"],
    },
    "America/Recife": {
      name: "America/Recife",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["BR"],
    },
    "America/Araguaina": {
      name: "America/Araguaina",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["BR"],
    },
    "America/Maceio": {
      name: "America/Maceio",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["BR"],
    },
    "America/Bahia": {
      name: "America/Bahia",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["BR"],
    },
    "America/Sao_Paulo": {
      name: "America/Sao_Paulo",
      utcOffset: -120,
      offsetStr: "-02:00",
      countries: ["BR"],
    },
    "America/Campo_Grande": {
      name: "America/Campo_Grande",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["BR"],
    },
    "America/Cuiaba": {
      name: "America/Cuiaba",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["BR"],
    },
    "America/Santarem": {
      name: "America/Santarem",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["BR"],
    },
    "America/Porto_Velho": {
      name: "America/Porto_Velho",
      utcOffset: -240,
      offsetStr: "-04:00",
      countries: ["BR"],
    },
    "America/Boa_Vista": {
      name: "America/Boa_Vista",
      utcOffset: -240,
      offsetStr: "-04:00",
      countries: ["BR"],
    },
    "America/Manaus": {
      name: "America/Manaus",
      utcOffset: -240,
      offsetStr: "-04:00",
      countries: ["BR"],
    },
    "America/Eirunepe": {
      name: "America/Eirunepe",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["BR"],
    },
    "America/Rio_Branco": {
      name: "America/Rio_Branco",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["BR"],
    },
    "America/Nassau": {
      name: "America/Nassau",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["BS"],
    },
    "Asia/Thimphu": {
      name: "Asia/Thimphu",
      utcOffset: 360,
      offsetStr: "+06:00",
      countries: ["BT"],
    },
    "Europe/Minsk": {
      name: "Europe/Minsk",
      utcOffset: 180,
      offsetStr: "+03:00",
      countries: ["BY"],
    },
    "America/Belize": {
      name: "America/Belize",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["BZ"],
    },
    "America/St_Johns": {
      name: "America/St_Johns",
      utcOffset: -210,
      offsetStr: "-03:30",
      countries: ["CA"],
    },
    "America/Halifax": {
      name: "America/Halifax",
      utcOffset: -240,
      offsetStr: "-04:00",
      countries: ["CA"],
    },
    "America/Glace_Bay": {
      name: "America/Glace_Bay",
      utcOffset: -240,
      offsetStr: "-04:00",
      countries: ["CA"],
    },
    "America/Moncton": {
      name: "America/Moncton",
      utcOffset: -240,
      offsetStr: "-04:00",
      countries: ["CA"],
    },
    "America/Goose_Bay": {
      name: "America/Goose_Bay",
      utcOffset: -240,
      offsetStr: "-04:00",
      countries: ["CA"],
    },
    "America/Blanc-Sablon": {
      name: "America/Blanc-Sablon",
      utcOffset: -240,
      offsetStr: "-04:00",
      countries: ["CA"],
    },
    "America/Toronto": {
      name: "America/Toronto",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["CA"],
    },
    "America/Nipigon": {
      name: "America/Nipigon",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["CA"],
    },
    "America/Thunder_Bay": {
      name: "America/Thunder_Bay",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["CA"],
    },
    "America/Iqaluit": {
      name: "America/Iqaluit",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["CA"],
    },
    "America/Pangnirtung": {
      name: "America/Pangnirtung",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["CA"],
    },
    "America/Resolute": {
      name: "America/Resolute",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["CA"],
    },
    "America/Atikokan": {
      name: "America/Atikokan",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["CA"],
    },
    "America/Rankin_Inlet": {
      name: "America/Rankin_Inlet",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["CA"],
    },
    "America/Winnipeg": {
      name: "America/Winnipeg",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["CA"],
    },
    "America/Rainy_River": {
      name: "America/Rainy_River",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["CA"],
    },
    "America/Regina": {
      name: "America/Regina",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["CA"],
    },
    "America/Swift_Current": {
      name: "America/Swift_Current",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["CA"],
    },
    "America/Edmonton": {
      name: "America/Edmonton",
      utcOffset: -420,
      offsetStr: "-07:00",
      countries: ["CA"],
    },
    "America/Cambridge_Bay": {
      name: "America/Cambridge_Bay",
      utcOffset: -420,
      offsetStr: "-07:00",
      countries: ["CA"],
    },
    "America/Yellowknife": {
      name: "America/Yellowknife",
      utcOffset: -420,
      offsetStr: "-07:00",
      countries: ["CA"],
    },
    "America/Inuvik": {
      name: "America/Inuvik",
      utcOffset: -420,
      offsetStr: "-07:00",
      countries: ["CA"],
    },
    "America/Creston": {
      name: "America/Creston",
      utcOffset: -420,
      offsetStr: "-07:00",
      countries: ["CA"],
    },
    "America/Dawson_Creek": {
      name: "America/Dawson_Creek",
      utcOffset: -420,
      offsetStr: "-07:00",
      countries: ["CA"],
    },
    "America/Fort_Nelson": {
      name: "America/Fort_Nelson",
      utcOffset: -420,
      offsetStr: "-07:00",
      countries: ["CA"],
    },
    "America/Vancouver": {
      name: "America/Vancouver",
      utcOffset: -480,
      offsetStr: "-08:00",
      countries: ["CA"],
    },
    "America/Whitehorse": {
      name: "America/Whitehorse",
      utcOffset: -480,
      offsetStr: "-08:00",
      countries: ["CA"],
    },
    "America/Dawson": {
      name: "America/Dawson",
      utcOffset: -480,
      offsetStr: "-08:00",
      countries: ["CA"],
    },
    "Indian/Cocos": {
      name: "Indian/Cocos",
      utcOffset: 390,
      offsetStr: "+06:30",
      countries: ["CC"],
    },
    "Europe/Zurich": {
      name: "Europe/Zurich",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["CH", "DE", "LI"],
    },
    "Africa/Abidjan": {
      name: "Africa/Abidjan",
      utcOffset: 0,
      offsetStr: "+00:00",
      countries: [
        "CI",
        "BF",
        "GM",
        "GN",
        "ML",
        "MR",
        "SH",
        "SL",
        "SN",
        "ST",
        "TG",
      ],
    },
    "Pacific/Rarotonga": {
      name: "Pacific/Rarotonga",
      utcOffset: -600,
      offsetStr: "-10:00",
      countries: ["CK"],
    },
    "America/Santiago": {
      name: "America/Santiago",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["CL"],
    },
    "Pacific/Easter": {
      name: "Pacific/Easter",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["CL"],
    },
    "Asia/Shanghai": {
      name: "Asia/Shanghai",
      utcOffset: 480,
      offsetStr: "+08:00",
      countries: ["CN"],
    },
    "Asia/Urumqi": {
      name: "Asia/Urumqi",
      utcOffset: 360,
      offsetStr: "+06:00",
      countries: ["CN"],
    },
    "America/Bogota": {
      name: "America/Bogota",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["CO"],
    },
    "America/Costa_Rica": {
      name: "America/Costa_Rica",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["CR"],
    },
    "America/Havana": {
      name: "America/Havana",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["CU"],
    },
    "Atlantic/Cape_Verde": {
      name: "Atlantic/Cape_Verde",
      utcOffset: -60,
      offsetStr: "-01:00",
      countries: ["CV"],
    },
    "America/Curacao": {
      name: "America/Curacao",
      utcOffset: -240,
      offsetStr: "-04:00",
      countries: ["CW", "AW", "BQ", "SX"],
    },
    "Indian/Christmas": {
      name: "Indian/Christmas",
      utcOffset: 420,
      offsetStr: "+07:00",
      countries: ["CX"],
    },
    "Asia/Nicosia": {
      name: "Asia/Nicosia",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["CY"],
    },
    "Europe/Prague": {
      name: "Europe/Prague",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["CZ", "SK"],
    },
    "Europe/Berlin": {
      name: "Europe/Berlin",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["DE"],
    },
    "Europe/Copenhagen": {
      name: "Europe/Copenhagen",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["DK"],
    },
    "America/Santo_Domingo": {
      name: "America/Santo_Domingo",
      utcOffset: -240,
      offsetStr: "-04:00",
      countries: ["DO"],
    },
    "Africa/Algiers": {
      name: "Africa/Algiers",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["DZ"],
    },
    "America/Guayaquil": {
      name: "America/Guayaquil",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["EC"],
    },
    "Pacific/Galapagos": {
      name: "Pacific/Galapagos",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["EC"],
    },
    "Europe/Tallinn": {
      name: "Europe/Tallinn",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["EE"],
    },
    "Africa/Cairo": {
      name: "Africa/Cairo",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["EG"],
    },
    "Africa/El_Aaiun": {
      name: "Africa/El_Aaiun",
      utcOffset: 0,
      offsetStr: "+00:00",
      countries: ["EH"],
    },
    "Europe/Madrid": {
      name: "Europe/Madrid",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["ES"],
    },
    "Africa/Ceuta": {
      name: "Africa/Ceuta",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["ES"],
    },
    "Atlantic/Canary": {
      name: "Atlantic/Canary",
      utcOffset: 0,
      offsetStr: "+00:00",
      countries: ["ES"],
    },
    "Europe/Helsinki": {
      name: "Europe/Helsinki",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["FI", "AX"],
    },
    "Pacific/Fiji": {
      name: "Pacific/Fiji",
      utcOffset: 720,
      offsetStr: "+12:00",
      countries: ["FJ"],
    },
    "Atlantic/Stanley": {
      name: "Atlantic/Stanley",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["FK"],
    },
    "Pacific/Chuuk": {
      name: "Pacific/Chuuk",
      utcOffset: 600,
      offsetStr: "+10:00",
      countries: ["FM"],
    },
    "Pacific/Pohnpei": {
      name: "Pacific/Pohnpei",
      utcOffset: 660,
      offsetStr: "+11:00",
      countries: ["FM"],
    },
    "Pacific/Kosrae": {
      name: "Pacific/Kosrae",
      utcOffset: 660,
      offsetStr: "+11:00",
      countries: ["FM"],
    },
    "Atlantic/Faroe": {
      name: "Atlantic/Faroe",
      utcOffset: 0,
      offsetStr: "+00:00",
      countries: ["FO"],
    },
    "Europe/Paris": {
      name: "Europe/Paris",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["FR"],
    },
    "Europe/London": {
      name: "Europe/London",
      utcOffset: 0,
      offsetStr: "+00:00",
      countries: ["GB", "GG", "IM", "JE"],
    },
    "Asia/Tbilisi": {
      name: "Asia/Tbilisi",
      utcOffset: 240,
      offsetStr: "+04:00",
      countries: ["GE"],
    },
    "America/Cayenne": {
      name: "America/Cayenne",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["GF"],
    },
    "Africa/Accra": {
      name: "Africa/Accra",
      utcOffset: 0,
      offsetStr: "+00:00",
      countries: ["GH"],
    },
    "Europe/Gibraltar": {
      name: "Europe/Gibraltar",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["GI"],
    },
    "America/Godthab": {
      name: "America/Godthab",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["GL"],
    },
    "America/Danmarkshavn": {
      name: "America/Danmarkshavn",
      utcOffset: 0,
      offsetStr: "+00:00",
      countries: ["GL"],
    },
    "America/Scoresbysund": {
      name: "America/Scoresbysund",
      utcOffset: -60,
      offsetStr: "-01:00",
      countries: ["GL"],
    },
    "America/Thule": {
      name: "America/Thule",
      utcOffset: -240,
      offsetStr: "-04:00",
      countries: ["GL"],
    },
    "Europe/Athens": {
      name: "Europe/Athens",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["GR"],
    },
    "Atlantic/South_Georgia": {
      name: "Atlantic/South_Georgia",
      utcOffset: -120,
      offsetStr: "-02:00",
      countries: ["GS"],
    },
    "America/Guatemala": {
      name: "America/Guatemala",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["GT"],
    },
    "Pacific/Guam": {
      name: "Pacific/Guam",
      utcOffset: 600,
      offsetStr: "+10:00",
      countries: ["GU", "MP"],
    },
    "Africa/Bissau": {
      name: "Africa/Bissau",
      utcOffset: 0,
      offsetStr: "+00:00",
      countries: ["GW"],
    },
    "America/Guyana": {
      name: "America/Guyana",
      utcOffset: -240,
      offsetStr: "-04:00",
      countries: ["GY"],
    },
    "Asia/Hong_Kong": {
      name: "Asia/Hong_Kong",
      utcOffset: 480,
      offsetStr: "+08:00",
      countries: ["HK"],
    },
    "America/Tegucigalpa": {
      name: "America/Tegucigalpa",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["HN"],
    },
    "America/Port-au-Prince": {
      name: "America/Port-au-Prince",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["HT"],
    },
    "Europe/Budapest": {
      name: "Europe/Budapest",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["HU"],
    },
    "Asia/Jakarta": {
      name: "Asia/Jakarta",
      utcOffset: 420,
      offsetStr: "+07:00",
      countries: ["ID"],
    },
    "Asia/Pontianak": {
      name: "Asia/Pontianak",
      utcOffset: 420,
      offsetStr: "+07:00",
      countries: ["ID"],
    },
    "Asia/Makassar": {
      name: "Asia/Makassar",
      utcOffset: 480,
      offsetStr: "+08:00",
      countries: ["ID"],
    },
    "Asia/Jayapura": {
      name: "Asia/Jayapura",
      utcOffset: 540,
      offsetStr: "+09:00",
      countries: ["ID"],
    },
    "Europe/Dublin": {
      name: "Europe/Dublin",
      utcOffset: 0,
      offsetStr: "+00:00",
      countries: ["IE"],
    },
    "Asia/Jerusalem": {
      name: "Asia/Jerusalem",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["IL"],
    },
    "Asia/Kolkata": {
      name: "Asia/Kolkata",
      utcOffset: 330,
      offsetStr: "+05:30",
      countries: ["IN"],
    },
    "Indian/Chagos": {
      name: "Indian/Chagos",
      utcOffset: 360,
      offsetStr: "+06:00",
      countries: ["IO"],
    },
    "Asia/Baghdad": {
      name: "Asia/Baghdad",
      utcOffset: 180,
      offsetStr: "+03:00",
      countries: ["IQ"],
    },
    "Asia/Tehran": {
      name: "Asia/Tehran",
      utcOffset: 210,
      offsetStr: "+03:30",
      countries: ["IR"],
    },
    "Atlantic/Reykjavik": {
      name: "Atlantic/Reykjavik",
      utcOffset: 0,
      offsetStr: "+00:00",
      countries: ["IS"],
    },
    "Europe/Rome": {
      name: "Europe/Rome",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["IT", "SM", "VA"],
    },
    "America/Jamaica": {
      name: "America/Jamaica",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["JM"],
    },
    "Asia/Amman": {
      name: "Asia/Amman",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["JO"],
    },
    "Asia/Tokyo": {
      name: "Asia/Tokyo",
      utcOffset: 540,
      offsetStr: "+09:00",
      countries: ["JP"],
    },
    "Africa/Nairobi": {
      name: "Africa/Nairobi",
      utcOffset: 180,
      offsetStr: "+03:00",
      countries: ["KE", "DJ", "ER", "ET", "KM", "MG", "SO", "TZ", "UG", "YT"],
    },
    "Asia/Bishkek": {
      name: "Asia/Bishkek",
      utcOffset: 360,
      offsetStr: "+06:00",
      countries: ["KG"],
    },
    "Pacific/Tarawa": {
      name: "Pacific/Tarawa",
      utcOffset: 720,
      offsetStr: "+12:00",
      countries: ["KI"],
    },
    "Pacific/Enderbury": {
      name: "Pacific/Enderbury",
      utcOffset: 780,
      offsetStr: "+13:00",
      countries: ["KI"],
    },
    "Pacific/Kiritimati": {
      name: "Pacific/Kiritimati",
      utcOffset: 840,
      offsetStr: "+14:00",
      countries: ["KI"],
    },
    "Asia/Pyongyang": {
      name: "Asia/Pyongyang",
      utcOffset: 510,
      offsetStr: "+08:30",
      countries: ["KP"],
    },
    "Asia/Seoul": {
      name: "Asia/Seoul",
      utcOffset: 540,
      offsetStr: "+09:00",
      countries: ["KR"],
    },
    "America/Cayman": {
      name: "America/Cayman",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["KY"],
    },
    "Asia/Almaty": {
      name: "Asia/Almaty",
      utcOffset: 360,
      offsetStr: "+06:00",
      countries: ["KZ"],
    },
    "Asia/Qyzylorda": {
      name: "Asia/Qyzylorda",
      utcOffset: 360,
      offsetStr: "+06:00",
      countries: ["KZ"],
    },
    "Asia/Aqtobe": {
      name: "Asia/Aqtobe",
      utcOffset: 300,
      offsetStr: "+05:00",
      countries: ["KZ"],
    },
    "Asia/Aqtau": {
      name: "Asia/Aqtau",
      utcOffset: 300,
      offsetStr: "+05:00",
      countries: ["KZ"],
    },
    "Asia/Oral": {
      name: "Asia/Oral",
      utcOffset: 300,
      offsetStr: "+05:00",
      countries: ["KZ"],
    },
    "Asia/Beirut": {
      name: "Asia/Beirut",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["LB"],
    },
    "Asia/Colombo": {
      name: "Asia/Colombo",
      utcOffset: 330,
      offsetStr: "+05:30",
      countries: ["LK"],
    },
    "Africa/Monrovia": {
      name: "Africa/Monrovia",
      utcOffset: 0,
      offsetStr: "+00:00",
      countries: ["LR"],
    },
    "Europe/Vilnius": {
      name: "Europe/Vilnius",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["LT"],
    },
    "Europe/Luxembourg": {
      name: "Europe/Luxembourg",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["LU"],
    },
    "Europe/Riga": {
      name: "Europe/Riga",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["LV"],
    },
    "Africa/Tripoli": {
      name: "Africa/Tripoli",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["LY"],
    },
    "Africa/Casablanca": {
      name: "Africa/Casablanca",
      utcOffset: 0,
      offsetStr: "+00:00",
      countries: ["MA"],
    },
    "Europe/Monaco": {
      name: "Europe/Monaco",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["MC"],
    },
    "Europe/Chisinau": {
      name: "Europe/Chisinau",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["MD"],
    },
    "Pacific/Majuro": {
      name: "Pacific/Majuro",
      utcOffset: 720,
      offsetStr: "+12:00",
      countries: ["MH"],
    },
    "Pacific/Kwajalein": {
      name: "Pacific/Kwajalein",
      utcOffset: 720,
      offsetStr: "+12:00",
      countries: ["MH"],
    },
    "Asia/Rangoon": {
      name: "Asia/Rangoon",
      utcOffset: 390,
      offsetStr: "+06:30",
      countries: ["MM"],
    },
    "Asia/Ulaanbaatar": {
      name: "Asia/Ulaanbaatar",
      utcOffset: 480,
      offsetStr: "+08:00",
      countries: ["MN"],
    },
    "Asia/Hovd": {
      name: "Asia/Hovd",
      utcOffset: 420,
      offsetStr: "+07:00",
      countries: ["MN"],
    },
    "Asia/Choibalsan": {
      name: "Asia/Choibalsan",
      utcOffset: 480,
      offsetStr: "+08:00",
      countries: ["MN"],
    },
    "Asia/Macau": {
      name: "Asia/Macau",
      utcOffset: 480,
      offsetStr: "+08:00",
      countries: ["MO"],
    },
    "America/Martinique": {
      name: "America/Martinique",
      utcOffset: -240,
      offsetStr: "-04:00",
      countries: ["MQ"],
    },
    "Europe/Malta": {
      name: "Europe/Malta",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["MT"],
    },
    "Indian/Mauritius": {
      name: "Indian/Mauritius",
      utcOffset: 240,
      offsetStr: "+04:00",
      countries: ["MU"],
    },
    "Indian/Maldives": {
      name: "Indian/Maldives",
      utcOffset: 300,
      offsetStr: "+05:00",
      countries: ["MV"],
    },
    "America/Mexico_City": {
      name: "America/Mexico_City",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["MX"],
    },
    "America/Cancun": {
      name: "America/Cancun",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["MX"],
    },
    "America/Merida": {
      name: "America/Merida",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["MX"],
    },
    "America/Monterrey": {
      name: "America/Monterrey",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["MX"],
    },
    "America/Matamoros": {
      name: "America/Matamoros",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["MX"],
    },
    "America/Mazatlan": {
      name: "America/Mazatlan",
      utcOffset: -420,
      offsetStr: "-07:00",
      countries: ["MX"],
    },
    "America/Chihuahua": {
      name: "America/Chihuahua",
      utcOffset: -420,
      offsetStr: "-07:00",
      countries: ["MX"],
    },
    "America/Ojinaga": {
      name: "America/Ojinaga",
      utcOffset: -420,
      offsetStr: "-07:00",
      countries: ["MX"],
    },
    "America/Hermosillo": {
      name: "America/Hermosillo",
      utcOffset: -420,
      offsetStr: "-07:00",
      countries: ["MX"],
    },
    "America/Tijuana": {
      name: "America/Tijuana",
      utcOffset: -480,
      offsetStr: "-08:00",
      countries: ["MX"],
    },
    "America/Santa_Isabel": {
      name: "America/Santa_Isabel",
      utcOffset: -480,
      offsetStr: "-08:00",
      countries: ["MX"],
    },
    "America/Bahia_Banderas": {
      name: "America/Bahia_Banderas",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["MX"],
    },
    "Asia/Kuala_Lumpur": {
      name: "Asia/Kuala_Lumpur",
      utcOffset: 480,
      offsetStr: "+08:00",
      countries: ["MY"],
    },
    "Asia/Kuching": {
      name: "Asia/Kuching",
      utcOffset: 480,
      offsetStr: "+08:00",
      countries: ["MY"],
    },
    "Africa/Maputo": {
      name: "Africa/Maputo",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["MZ", "BI", "BW", "CD", "MW", "RW", "ZM", "ZW"],
    },
    "Africa/Windhoek": {
      name: "Africa/Windhoek",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["NA"],
    },
    "Pacific/Noumea": {
      name: "Pacific/Noumea",
      utcOffset: 660,
      offsetStr: "+11:00",
      countries: ["NC"],
    },
    "Pacific/Norfolk": {
      name: "Pacific/Norfolk",
      utcOffset: 660,
      offsetStr: "+11:00",
      countries: ["NF"],
    },
    "Africa/Lagos": {
      name: "Africa/Lagos",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["NG", "AO", "BJ", "CD", "CF", "CG", "CM", "GA", "GQ", "NE"],
    },
    "America/Managua": {
      name: "America/Managua",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["NI"],
    },
    "Europe/Amsterdam": {
      name: "Europe/Amsterdam",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["NL"],
    },
    "Europe/Oslo": {
      name: "Europe/Oslo",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["NO", "SJ"],
    },
    "Asia/Kathmandu": {
      name: "Asia/Kathmandu",
      utcOffset: 345,
      offsetStr: "+05:45",
      countries: ["NP"],
    },
    "Pacific/Nauru": {
      name: "Pacific/Nauru",
      utcOffset: 720,
      offsetStr: "+12:00",
      countries: ["NR"],
    },
    "Pacific/Niue": {
      name: "Pacific/Niue",
      utcOffset: -660,
      offsetStr: "-11:00",
      countries: ["NU"],
    },
    "Pacific/Auckland": {
      name: "Pacific/Auckland",
      utcOffset: 780,
      offsetStr: "+13:00",
      countries: ["NZ", "AQ"],
    },
    "Pacific/Chatham": {
      name: "Pacific/Chatham",
      utcOffset: 825,
      offsetStr: "+13:45",
      countries: ["NZ"],
    },
    "America/Panama": {
      name: "America/Panama",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["PA"],
    },
    "America/Lima": {
      name: "America/Lima",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["PE"],
    },
    "Pacific/Tahiti": {
      name: "Pacific/Tahiti",
      utcOffset: -600,
      offsetStr: "-10:00",
      countries: ["PF"],
    },
    "Pacific/Marquesas": {
      name: "Pacific/Marquesas",
      utcOffset: -570,
      offsetStr: "-09:30",
      countries: ["PF"],
    },
    "Pacific/Gambier": {
      name: "Pacific/Gambier",
      utcOffset: -540,
      offsetStr: "-09:00",
      countries: ["PF"],
    },
    "Pacific/Port_Moresby": {
      name: "Pacific/Port_Moresby",
      utcOffset: 600,
      offsetStr: "+10:00",
      countries: ["PG"],
    },
    "Pacific/Bougainville": {
      name: "Pacific/Bougainville",
      utcOffset: 660,
      offsetStr: "+11:00",
      countries: ["PG"],
    },
    "Asia/Manila": {
      name: "Asia/Manila",
      utcOffset: 480,
      offsetStr: "+08:00",
      countries: ["PH"],
    },
    "Asia/Karachi": {
      name: "Asia/Karachi",
      utcOffset: 300,
      offsetStr: "+05:00",
      countries: ["PK"],
    },
    "Europe/Warsaw": {
      name: "Europe/Warsaw",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["PL"],
    },
    "America/Miquelon": {
      name: "America/Miquelon",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["PM"],
    },
    "Pacific/Pitcairn": {
      name: "Pacific/Pitcairn",
      utcOffset: -480,
      offsetStr: "-08:00",
      countries: ["PN"],
    },
    "America/Puerto_Rico": {
      name: "America/Puerto_Rico",
      utcOffset: -240,
      offsetStr: "-04:00",
      countries: ["PR"],
    },
    "Asia/Gaza": {
      name: "Asia/Gaza",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["PS"],
    },
    "Asia/Hebron": {
      name: "Asia/Hebron",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["PS"],
    },
    "Europe/Lisbon": {
      name: "Europe/Lisbon",
      utcOffset: 0,
      offsetStr: "+00:00",
      countries: ["PT"],
    },
    "Atlantic/Madeira": {
      name: "Atlantic/Madeira",
      utcOffset: 0,
      offsetStr: "+00:00",
      countries: ["PT"],
    },
    "Atlantic/Azores": {
      name: "Atlantic/Azores",
      utcOffset: -60,
      offsetStr: "-01:00",
      countries: ["PT"],
    },
    "Pacific/Palau": {
      name: "Pacific/Palau",
      utcOffset: 540,
      offsetStr: "+09:00",
      countries: ["PW"],
    },
    "America/Asuncion": {
      name: "America/Asuncion",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["PY"],
    },
    "Asia/Qatar": {
      name: "Asia/Qatar",
      utcOffset: 180,
      offsetStr: "+03:00",
      countries: ["QA", "BH"],
    },
    "Indian/Reunion": {
      name: "Indian/Reunion",
      utcOffset: 240,
      offsetStr: "+04:00",
      countries: ["RE", "TF"],
    },
    "Europe/Bucharest": {
      name: "Europe/Bucharest",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["RO"],
    },
    "Europe/Belgrade": {
      name: "Europe/Belgrade",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["RS", "BA", "HR", "ME", "MK", "SI"],
    },
    "Europe/Kaliningrad": {
      name: "Europe/Kaliningrad",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["RU"],
    },
    "Europe/Moscow": {
      name: "Europe/Moscow",
      utcOffset: 180,
      offsetStr: "+03:00",
      countries: ["RU"],
    },
    "Europe/Simferopol": {
      name: "Europe/Simferopol",
      utcOffset: 180,
      offsetStr: "+03:00",
      countries: ["RU"],
    },
    "Europe/Volgograd": {
      name: "Europe/Volgograd",
      utcOffset: 180,
      offsetStr: "+03:00",
      countries: ["RU"],
    },
    "Europe/Samara": {
      name: "Europe/Samara",
      utcOffset: 240,
      offsetStr: "+04:00",
      countries: ["RU"],
    },
    "Asia/Yekaterinburg": {
      name: "Asia/Yekaterinburg",
      utcOffset: 300,
      offsetStr: "+05:00",
      countries: ["RU"],
    },
    "Asia/Omsk": {
      name: "Asia/Omsk",
      utcOffset: 360,
      offsetStr: "+06:00",
      countries: ["RU"],
    },
    "Asia/Novosibirsk": {
      name: "Asia/Novosibirsk",
      utcOffset: 360,
      offsetStr: "+06:00",
      countries: ["RU"],
    },
    "Asia/Novokuznetsk": {
      name: "Asia/Novokuznetsk",
      utcOffset: 420,
      offsetStr: "+07:00",
      countries: ["RU"],
    },
    "Asia/Krasnoyarsk": {
      name: "Asia/Krasnoyarsk",
      utcOffset: 420,
      offsetStr: "+07:00",
      countries: ["RU"],
    },
    "Asia/Irkutsk": {
      name: "Asia/Irkutsk",
      utcOffset: 480,
      offsetStr: "+08:00",
      countries: ["RU"],
    },
    "Asia/Chita": {
      name: "Asia/Chita",
      utcOffset: 480,
      offsetStr: "+08:00",
      countries: ["RU"],
    },
    "Asia/Yakutsk": {
      name: "Asia/Yakutsk",
      utcOffset: 540,
      offsetStr: "+09:00",
      countries: ["RU"],
    },
    "Asia/Khandyga": {
      name: "Asia/Khandyga",
      utcOffset: 540,
      offsetStr: "+09:00",
      countries: ["RU"],
    },
    "Asia/Vladivostok": {
      name: "Asia/Vladivostok",
      utcOffset: 600,
      offsetStr: "+10:00",
      countries: ["RU"],
    },
    "Asia/Sakhalin": {
      name: "Asia/Sakhalin",
      utcOffset: 600,
      offsetStr: "+10:00",
      countries: ["RU"],
    },
    "Asia/Ust-Nera": {
      name: "Asia/Ust-Nera",
      utcOffset: 600,
      offsetStr: "+10:00",
      countries: ["RU"],
    },
    "Asia/Magadan": {
      name: "Asia/Magadan",
      utcOffset: 600,
      offsetStr: "+10:00",
      countries: ["RU"],
    },
    "Asia/Srednekolymsk": {
      name: "Asia/Srednekolymsk",
      utcOffset: 660,
      offsetStr: "+11:00",
      countries: ["RU"],
    },
    "Asia/Kamchatka": {
      name: "Asia/Kamchatka",
      utcOffset: 720,
      offsetStr: "+12:00",
      countries: ["RU"],
    },
    "Asia/Anadyr": {
      name: "Asia/Anadyr",
      utcOffset: 720,
      offsetStr: "+12:00",
      countries: ["RU"],
    },
    "Asia/Riyadh": {
      name: "Asia/Riyadh",
      utcOffset: 180,
      offsetStr: "+03:00",
      countries: ["SA", "KW", "YE"],
    },
    "Pacific/Guadalcanal": {
      name: "Pacific/Guadalcanal",
      utcOffset: 660,
      offsetStr: "+11:00",
      countries: ["SB"],
    },
    "Indian/Mahe": {
      name: "Indian/Mahe",
      utcOffset: 240,
      offsetStr: "+04:00",
      countries: ["SC"],
    },
    "Africa/Khartoum": {
      name: "Africa/Khartoum",
      utcOffset: 180,
      offsetStr: "+03:00",
      countries: ["SD", "SS"],
    },
    "Europe/Stockholm": {
      name: "Europe/Stockholm",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["SE"],
    },
    "Asia/Singapore": {
      name: "Asia/Singapore",
      utcOffset: 480,
      offsetStr: "+08:00",
      countries: ["SG"],
    },
    "America/Paramaribo": {
      name: "America/Paramaribo",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["SR"],
    },
    "America/El_Salvador": {
      name: "America/El_Salvador",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["SV"],
    },
    "Asia/Damascus": {
      name: "Asia/Damascus",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["SY"],
    },
    "America/Grand_Turk": {
      name: "America/Grand_Turk",
      utcOffset: -240,
      offsetStr: "-04:00",
      countries: ["TC"],
    },
    "Africa/Ndjamena": {
      name: "Africa/Ndjamena",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["TD"],
    },
    "Indian/Kerguelen": {
      name: "Indian/Kerguelen",
      utcOffset: 300,
      offsetStr: "+05:00",
      countries: ["TF"],
    },
    "Asia/Bangkok": {
      name: "Asia/Bangkok",
      utcOffset: 420,
      offsetStr: "+07:00",
      countries: ["TH", "KH", "LA", "VN"],
    },
    "Asia/Dushanbe": {
      name: "Asia/Dushanbe",
      utcOffset: 300,
      offsetStr: "+05:00",
      countries: ["TJ"],
    },
    "Pacific/Fakaofo": {
      name: "Pacific/Fakaofo",
      utcOffset: 780,
      offsetStr: "+13:00",
      countries: ["TK"],
    },
    "Asia/Dili": {
      name: "Asia/Dili",
      utcOffset: 540,
      offsetStr: "+09:00",
      countries: ["TL"],
    },
    "Asia/Ashgabat": {
      name: "Asia/Ashgabat",
      utcOffset: 300,
      offsetStr: "+05:00",
      countries: ["TM"],
    },
    "Africa/Tunis": {
      name: "Africa/Tunis",
      utcOffset: 60,
      offsetStr: "+01:00",
      countries: ["TN"],
    },
    "Pacific/Tongatapu": {
      name: "Pacific/Tongatapu",
      utcOffset: 780,
      offsetStr: "+13:00",
      countries: ["TO"],
    },
    "Europe/Istanbul": {
      name: "Europe/Istanbul",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["TR"],
    },
    "America/Port_of_Spain": {
      name: "America/Port_of_Spain",
      utcOffset: -240,
      offsetStr: "-04:00",
      countries: [
        "TT",
        "AG",
        "AI",
        "BL",
        "DM",
        "GD",
        "GP",
        "KN",
        "LC",
        "MF",
        "MS",
        "VC",
        "VG",
        "VI",
      ],
    },
    "Pacific/Funafuti": {
      name: "Pacific/Funafuti",
      utcOffset: 720,
      offsetStr: "+12:00",
      countries: ["TV"],
    },
    "Asia/Taipei": {
      name: "Asia/Taipei",
      utcOffset: 480,
      offsetStr: "+08:00",
      countries: ["TW"],
    },
    "Europe/Kiev": {
      name: "Europe/Kiev",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["UA"],
    },
    "Europe/Uzhgorod": {
      name: "Europe/Uzhgorod",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["UA"],
    },
    "Europe/Zaporozhye": {
      name: "Europe/Zaporozhye",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["UA"],
    },
    "Pacific/Wake": {
      name: "Pacific/Wake",
      utcOffset: 720,
      offsetStr: "+12:00",
      countries: ["UM"],
    },
    "America/New_York": {
      name: "America/New_York",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["US"],
    },
    "America/Detroit": {
      name: "America/Detroit",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["US"],
    },
    "America/Kentucky/Louisville": {
      name: "America/Kentucky/Louisville",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["US"],
    },
    "America/Kentucky/Monticello": {
      name: "America/Kentucky/Monticello",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["US"],
    },
    "America/Indiana/Indianapolis": {
      name: "America/Indiana/Indianapolis",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["US"],
    },
    "America/Indiana/Vincennes": {
      name: "America/Indiana/Vincennes",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["US"],
    },
    "America/Indiana/Winamac": {
      name: "America/Indiana/Winamac",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["US"],
    },
    "America/Indiana/Marengo": {
      name: "America/Indiana/Marengo",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["US"],
    },
    "America/Indiana/Petersburg": {
      name: "America/Indiana/Petersburg",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["US"],
    },
    "America/Indiana/Vevay": {
      name: "America/Indiana/Vevay",
      utcOffset: -300,
      offsetStr: "-05:00",
      countries: ["US"],
    },
    "America/Chicago": {
      name: "America/Chicago",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["US"],
    },
    "America/Indiana/Tell_City": {
      name: "America/Indiana/Tell_City",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["US"],
    },
    "America/Indiana/Knox": {
      name: "America/Indiana/Knox",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["US"],
    },
    "America/Menominee": {
      name: "America/Menominee",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["US"],
    },
    "America/North_Dakota/Center": {
      name: "America/North_Dakota/Center",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["US"],
    },
    "America/North_Dakota/New_Salem": {
      name: "America/North_Dakota/New_Salem",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["US"],
    },
    "America/North_Dakota/Beulah": {
      name: "America/North_Dakota/Beulah",
      utcOffset: -360,
      offsetStr: "-06:00",
      countries: ["US"],
    },
    "America/Denver": {
      name: "America/Denver",
      utcOffset: -420,
      offsetStr: "-07:00",
      countries: ["US"],
    },
    "America/Boise": {
      name: "America/Boise",
      utcOffset: -420,
      offsetStr: "-07:00",
      countries: ["US"],
    },
    "America/Phoenix": {
      name: "America/Phoenix",
      utcOffset: -420,
      offsetStr: "-07:00",
      countries: ["US"],
    },
    "America/Los_Angeles": {
      name: "America/Los_Angeles",
      utcOffset: -480,
      offsetStr: "-08:00",
      countries: ["US"],
    },
    "America/Metlakatla": {
      name: "America/Metlakatla",
      utcOffset: -480,
      offsetStr: "-08:00",
      countries: ["US"],
    },
    "America/Anchorage": {
      name: "America/Anchorage",
      utcOffset: -540,
      offsetStr: "-09:00",
      countries: ["US"],
    },
    "America/Juneau": {
      name: "America/Juneau",
      utcOffset: -540,
      offsetStr: "-09:00",
      countries: ["US"],
    },
    "America/Sitka": {
      name: "America/Sitka",
      utcOffset: -540,
      offsetStr: "-09:00",
      countries: ["US"],
    },
    "America/Yakutat": {
      name: "America/Yakutat",
      utcOffset: -540,
      offsetStr: "-09:00",
      countries: ["US"],
    },
    "America/Nome": {
      name: "America/Nome",
      utcOffset: -540,
      offsetStr: "-09:00",
      countries: ["US"],
    },
    "America/Adak": {
      name: "America/Adak",
      utcOffset: -600,
      offsetStr: "-10:00",
      countries: ["US"],
    },
    "Pacific/Honolulu": {
      name: "Pacific/Honolulu",
      utcOffset: -600,
      offsetStr: "-10:00",
      countries: ["US", "UM"],
    },
    "America/Montevideo": {
      name: "America/Montevideo",
      utcOffset: -180,
      offsetStr: "-03:00",
      countries: ["UY"],
    },
    "Asia/Samarkand": {
      name: "Asia/Samarkand",
      utcOffset: 300,
      offsetStr: "+05:00",
      countries: ["UZ"],
    },
    "Asia/Tashkent": {
      name: "Asia/Tashkent",
      utcOffset: 300,
      offsetStr: "+05:00",
      countries: ["UZ"],
    },
    "America/Caracas": {
      name: "America/Caracas",
      utcOffset: -270,
      offsetStr: "-04:30",
      countries: ["VE"],
    },
    "Asia/Ho_Chi_Minh": {
      name: "Asia/Ho_Chi_Minh",
      utcOffset: 420,
      offsetStr: "+07:00",
      countries: ["VN"],
    },
    "Pacific/Efate": {
      name: "Pacific/Efate",
      utcOffset: 660,
      offsetStr: "+11:00",
      countries: ["VU"],
    },
    "Pacific/Wallis": {
      name: "Pacific/Wallis",
      utcOffset: 720,
      offsetStr: "+12:00",
      countries: ["WF"],
    },
    "Pacific/Apia": {
      name: "Pacific/Apia",
      utcOffset: 840,
      offsetStr: "+14:00",
      countries: ["WS"],
    },
    "Africa/Johannesburg": {
      name: "Africa/Johannesburg",
      utcOffset: 120,
      offsetStr: "+02:00",
      countries: ["ZA", "LS", "SZ"],
    },
  },
  currencies: {
    USD: {
      symbol: "$",
      name: "US Dollar",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "USD",
      name_plural: "US dollars",
    },
    CAD: {
      symbol: "CA$",
      name: "Canadian Dollar",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "CAD",
      name_plural: "Canadian dollars",
    },
    EUR: {
      symbol: "€",
      name: "Euro",
      symbol_native: "€",
      decimal_digits: 2,
      rounding: 0,
      code: "EUR",
      name_plural: "euros",
    },
    AED: {
      symbol: "AED",
      name: "United Arab Emirates Dirham",
      symbol_native: "د.إ.‏",
      decimal_digits: 2,
      rounding: 0,
      code: "AED",
      name_plural: "UAE dirhams",
    },
    AFN: {
      symbol: "Af",
      name: "Afghan Afghani",
      symbol_native: "؋",
      decimal_digits: 0,
      rounding: 0,
      code: "AFN",
      name_plural: "Afghan Afghanis",
    },
    ALL: {
      symbol: "ALL",
      name: "Albanian Lek",
      symbol_native: "Lek",
      decimal_digits: 0,
      rounding: 0,
      code: "ALL",
      name_plural: "Albanian lekë",
    },
    AMD: {
      symbol: "AMD",
      name: "Armenian Dram",
      symbol_native: "դր.",
      decimal_digits: 0,
      rounding: 0,
      code: "AMD",
      name_plural: "Armenian drams",
    },
    ARS: {
      symbol: "AR$",
      name: "Argentine Peso",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "ARS",
      name_plural: "Argentine pesos",
    },
    AUD: {
      symbol: "AU$",
      name: "Australian Dollar",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "AUD",
      name_plural: "Australian dollars",
    },
    AZN: {
      symbol: "man.",
      name: "Azerbaijani Manat",
      symbol_native: "ман.",
      decimal_digits: 2,
      rounding: 0,
      code: "AZN",
      name_plural: "Azerbaijani manats",
    },
    BAM: {
      symbol: "KM",
      name: "Bosnia-Herzegovina Convertible Mark",
      symbol_native: "KM",
      decimal_digits: 2,
      rounding: 0,
      code: "BAM",
      name_plural: "Bosnia-Herzegovina convertible marks",
    },
    BDT: {
      symbol: "Tk",
      name: "Bangladeshi Taka",
      symbol_native: "৳",
      decimal_digits: 2,
      rounding: 0,
      code: "BDT",
      name_plural: "Bangladeshi takas",
    },
    BGN: {
      symbol: "BGN",
      name: "Bulgarian Lev",
      symbol_native: "лв.",
      decimal_digits: 2,
      rounding: 0,
      code: "BGN",
      name_plural: "Bulgarian leva",
    },
    BHD: {
      symbol: "BD",
      name: "Bahraini Dinar",
      symbol_native: "د.ب.‏",
      decimal_digits: 3,
      rounding: 0,
      code: "BHD",
      name_plural: "Bahraini dinars",
    },
    BIF: {
      symbol: "FBu",
      name: "Burundian Franc",
      symbol_native: "FBu",
      decimal_digits: 0,
      rounding: 0,
      code: "BIF",
      name_plural: "Burundian francs",
    },
    BND: {
      symbol: "BN$",
      name: "Brunei Dollar",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "BND",
      name_plural: "Brunei dollars",
    },
    BOB: {
      symbol: "Bs",
      name: "Bolivian Boliviano",
      symbol_native: "Bs",
      decimal_digits: 2,
      rounding: 0,
      code: "BOB",
      name_plural: "Bolivian bolivianos",
    },
    BRL: {
      symbol: "R$",
      name: "Brazilian Real",
      symbol_native: "R$",
      decimal_digits: 2,
      rounding: 0,
      code: "BRL",
      name_plural: "Brazilian reals",
    },
    BWP: {
      symbol: "BWP",
      name: "Botswanan Pula",
      symbol_native: "P",
      decimal_digits: 2,
      rounding: 0,
      code: "BWP",
      name_plural: "Botswanan pulas",
    },
    BYN: {
      symbol: "Br",
      name: "Belarusian Ruble",
      symbol_native: "руб.",
      decimal_digits: 2,
      rounding: 0,
      code: "BYN",
      name_plural: "Belarusian rubles",
    },
    BZD: {
      symbol: "BZ$",
      name: "Belize Dollar",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "BZD",
      name_plural: "Belize dollars",
    },
    CDF: {
      symbol: "CDF",
      name: "Congolese Franc",
      symbol_native: "FrCD",
      decimal_digits: 2,
      rounding: 0,
      code: "CDF",
      name_plural: "Congolese francs",
    },
    CHF: {
      symbol: "CHF",
      name: "Swiss Franc",
      symbol_native: "CHF",
      decimal_digits: 2,
      rounding: 0.05,
      code: "CHF",
      name_plural: "Swiss francs",
    },
    CLP: {
      symbol: "CL$",
      name: "Chilean Peso",
      symbol_native: "$",
      decimal_digits: 0,
      rounding: 0,
      code: "CLP",
      name_plural: "Chilean pesos",
    },
    CNY: {
      symbol: "CN¥",
      name: "Chinese Yuan",
      symbol_native: "CN¥",
      decimal_digits: 2,
      rounding: 0,
      code: "CNY",
      name_plural: "Chinese yuan",
    },
    COP: {
      symbol: "CO$",
      name: "Colombian Peso",
      symbol_native: "$",
      decimal_digits: 0,
      rounding: 0,
      code: "COP",
      name_plural: "Colombian pesos",
    },
    CRC: {
      symbol: "₡",
      name: "Costa Rican Colón",
      symbol_native: "₡",
      decimal_digits: 0,
      rounding: 0,
      code: "CRC",
      name_plural: "Costa Rican colóns",
    },
    CVE: {
      symbol: "CV$",
      name: "Cape Verdean Escudo",
      symbol_native: "CV$",
      decimal_digits: 2,
      rounding: 0,
      code: "CVE",
      name_plural: "Cape Verdean escudos",
    },
    CZK: {
      symbol: "Kč",
      name: "Czech Republic Koruna",
      symbol_native: "Kč",
      decimal_digits: 2,
      rounding: 0,
      code: "CZK",
      name_plural: "Czech Republic korunas",
    },
    DJF: {
      symbol: "Fdj",
      name: "Djiboutian Franc",
      symbol_native: "Fdj",
      decimal_digits: 0,
      rounding: 0,
      code: "DJF",
      name_plural: "Djiboutian francs",
    },
    DKK: {
      symbol: "Dkr",
      name: "Danish Krone",
      symbol_native: "kr",
      decimal_digits: 2,
      rounding: 0,
      code: "DKK",
      name_plural: "Danish kroner",
    },
    DOP: {
      symbol: "RD$",
      name: "Dominican Peso",
      symbol_native: "RD$",
      decimal_digits: 2,
      rounding: 0,
      code: "DOP",
      name_plural: "Dominican pesos",
    },
    DZD: {
      symbol: "DA",
      name: "Algerian Dinar",
      symbol_native: "د.ج.‏",
      decimal_digits: 2,
      rounding: 0,
      code: "DZD",
      name_plural: "Algerian dinars",
    },
    EEK: {
      symbol: "Ekr",
      name: "Estonian Kroon",
      symbol_native: "kr",
      decimal_digits: 2,
      rounding: 0,
      code: "EEK",
      name_plural: "Estonian kroons",
    },
    EGP: {
      symbol: "EGP",
      name: "Egyptian Pound",
      symbol_native: "ج.م.‏",
      decimal_digits: 2,
      rounding: 0,
      code: "EGP",
      name_plural: "Egyptian pounds",
    },
    ERN: {
      symbol: "Nfk",
      name: "Eritrean Nakfa",
      symbol_native: "Nfk",
      decimal_digits: 2,
      rounding: 0,
      code: "ERN",
      name_plural: "Eritrean nakfas",
    },
    ETB: {
      symbol: "Br",
      name: "Ethiopian Birr",
      symbol_native: "Br",
      decimal_digits: 2,
      rounding: 0,
      code: "ETB",
      name_plural: "Ethiopian birrs",
    },
    GBP: {
      symbol: "£",
      name: "British Pound Sterling",
      symbol_native: "£",
      decimal_digits: 2,
      rounding: 0,
      code: "GBP",
      name_plural: "British pounds sterling",
    },
    GEL: {
      symbol: "GEL",
      name: "Georgian Lari",
      symbol_native: "GEL",
      decimal_digits: 2,
      rounding: 0,
      code: "GEL",
      name_plural: "Georgian laris",
    },
    GHS: {
      symbol: "GH₵",
      name: "Ghanaian Cedi",
      symbol_native: "GH₵",
      decimal_digits: 2,
      rounding: 0,
      code: "GHS",
      name_plural: "Ghanaian cedis",
    },
    GNF: {
      symbol: "FG",
      name: "Guinean Franc",
      symbol_native: "FG",
      decimal_digits: 0,
      rounding: 0,
      code: "GNF",
      name_plural: "Guinean francs",
    },
    GTQ: {
      symbol: "GTQ",
      name: "Guatemalan Quetzal",
      symbol_native: "Q",
      decimal_digits: 2,
      rounding: 0,
      code: "GTQ",
      name_plural: "Guatemalan quetzals",
    },
    HKD: {
      symbol: "HK$",
      name: "Hong Kong Dollar",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "HKD",
      name_plural: "Hong Kong dollars",
    },
    HNL: {
      symbol: "HNL",
      name: "Honduran Lempira",
      symbol_native: "L",
      decimal_digits: 2,
      rounding: 0,
      code: "HNL",
      name_plural: "Honduran lempiras",
    },
    HRK: {
      symbol: "kn",
      name: "Croatian Kuna",
      symbol_native: "kn",
      decimal_digits: 2,
      rounding: 0,
      code: "HRK",
      name_plural: "Croatian kunas",
    },
    HUF: {
      symbol: "Ft",
      name: "Hungarian Forint",
      symbol_native: "Ft",
      decimal_digits: 0,
      rounding: 0,
      code: "HUF",
      name_plural: "Hungarian forints",
    },
    IDR: {
      symbol: "Rp",
      name: "Indonesian Rupiah",
      symbol_native: "Rp",
      decimal_digits: 0,
      rounding: 0,
      code: "IDR",
      name_plural: "Indonesian rupiahs",
    },
    ILS: {
      symbol: "₪",
      name: "Israeli New Sheqel",
      symbol_native: "₪",
      decimal_digits: 2,
      rounding: 0,
      code: "ILS",
      name_plural: "Israeli new sheqels",
    },
    INR: {
      symbol: "Rs",
      name: "Indian Rupee",
      symbol_native: "টকা",
      decimal_digits: 2,
      rounding: 0,
      code: "INR",
      name_plural: "Indian rupees",
    },
    IQD: {
      symbol: "IQD",
      name: "Iraqi Dinar",
      symbol_native: "د.ع.‏",
      decimal_digits: 0,
      rounding: 0,
      code: "IQD",
      name_plural: "Iraqi dinars",
    },
    IRR: {
      symbol: "IRR",
      name: "Iranian Rial",
      symbol_native: "﷼",
      decimal_digits: 0,
      rounding: 0,
      code: "IRR",
      name_plural: "Iranian rials",
    },
    ISK: {
      symbol: "Ikr",
      name: "Icelandic Króna",
      symbol_native: "kr",
      decimal_digits: 0,
      rounding: 0,
      code: "ISK",
      name_plural: "Icelandic krónur",
    },
    JMD: {
      symbol: "J$",
      name: "Jamaican Dollar",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "JMD",
      name_plural: "Jamaican dollars",
    },
    JOD: {
      symbol: "JD",
      name: "Jordanian Dinar",
      symbol_native: "د.أ.‏",
      decimal_digits: 3,
      rounding: 0,
      code: "JOD",
      name_plural: "Jordanian dinars",
    },
    JPY: {
      symbol: "¥",
      name: "Japanese Yen",
      symbol_native: "￥",
      decimal_digits: 0,
      rounding: 0,
      code: "JPY",
      name_plural: "Japanese yen",
    },
    KES: {
      symbol: "Ksh",
      name: "Kenyan Shilling",
      symbol_native: "Ksh",
      decimal_digits: 2,
      rounding: 0,
      code: "KES",
      name_plural: "Kenyan shillings",
    },
    KHR: {
      symbol: "KHR",
      name: "Cambodian Riel",
      symbol_native: "៛",
      decimal_digits: 2,
      rounding: 0,
      code: "KHR",
      name_plural: "Cambodian riels",
    },
    KMF: {
      symbol: "CF",
      name: "Comorian Franc",
      symbol_native: "FC",
      decimal_digits: 0,
      rounding: 0,
      code: "KMF",
      name_plural: "Comorian francs",
    },
    KRW: {
      symbol: "₩",
      name: "South Korean Won",
      symbol_native: "₩",
      decimal_digits: 0,
      rounding: 0,
      code: "KRW",
      name_plural: "South Korean won",
    },
    KWD: {
      symbol: "KD",
      name: "Kuwaiti Dinar",
      symbol_native: "د.ك.‏",
      decimal_digits: 3,
      rounding: 0,
      code: "KWD",
      name_plural: "Kuwaiti dinars",
    },
    KZT: {
      symbol: "KZT",
      name: "Kazakhstani Tenge",
      symbol_native: "тңг.",
      decimal_digits: 2,
      rounding: 0,
      code: "KZT",
      name_plural: "Kazakhstani tenges",
    },
    LBP: {
      symbol: "L.L.",
      name: "Lebanese Pound",
      symbol_native: "ل.ل.‏",
      decimal_digits: 0,
      rounding: 0,
      code: "LBP",
      name_plural: "Lebanese pounds",
    },
    LKR: {
      symbol: "SLRs",
      name: "Sri Lankan Rupee",
      symbol_native: "SL Re",
      decimal_digits: 2,
      rounding: 0,
      code: "LKR",
      name_plural: "Sri Lankan rupees",
    },
    LTL: {
      symbol: "Lt",
      name: "Lithuanian Litas",
      symbol_native: "Lt",
      decimal_digits: 2,
      rounding: 0,
      code: "LTL",
      name_plural: "Lithuanian litai",
    },
    LVL: {
      symbol: "Ls",
      name: "Latvian Lats",
      symbol_native: "Ls",
      decimal_digits: 2,
      rounding: 0,
      code: "LVL",
      name_plural: "Latvian lati",
    },
    LYD: {
      symbol: "LD",
      name: "Libyan Dinar",
      symbol_native: "د.ل.‏",
      decimal_digits: 3,
      rounding: 0,
      code: "LYD",
      name_plural: "Libyan dinars",
    },
    MAD: {
      symbol: "MAD",
      name: "Moroccan Dirham",
      symbol_native: "د.م.‏",
      decimal_digits: 2,
      rounding: 0,
      code: "MAD",
      name_plural: "Moroccan dirhams",
    },
    MDL: {
      symbol: "MDL",
      name: "Moldovan Leu",
      symbol_native: "MDL",
      decimal_digits: 2,
      rounding: 0,
      code: "MDL",
      name_plural: "Moldovan lei",
    },
    MGA: {
      symbol: "MGA",
      name: "Malagasy Ariary",
      symbol_native: "MGA",
      decimal_digits: 0,
      rounding: 0,
      code: "MGA",
      name_plural: "Malagasy Ariaries",
    },
    MKD: {
      symbol: "MKD",
      name: "Macedonian Denar",
      symbol_native: "MKD",
      decimal_digits: 2,
      rounding: 0,
      code: "MKD",
      name_plural: "Macedonian denari",
    },
    MMK: {
      symbol: "MMK",
      name: "Myanma Kyat",
      symbol_native: "K",
      decimal_digits: 0,
      rounding: 0,
      code: "MMK",
      name_plural: "Myanma kyats",
    },
    MOP: {
      symbol: "MOP$",
      name: "Macanese Pataca",
      symbol_native: "MOP$",
      decimal_digits: 2,
      rounding: 0,
      code: "MOP",
      name_plural: "Macanese patacas",
    },
    MUR: {
      symbol: "MURs",
      name: "Mauritian Rupee",
      symbol_native: "MURs",
      decimal_digits: 0,
      rounding: 0,
      code: "MUR",
      name_plural: "Mauritian rupees",
    },
    MXN: {
      symbol: "MX$",
      name: "Mexican Peso",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "MXN",
      name_plural: "Mexican pesos",
    },
    MYR: {
      symbol: "RM",
      name: "Malaysian Ringgit",
      symbol_native: "RM",
      decimal_digits: 2,
      rounding: 0,
      code: "MYR",
      name_plural: "Malaysian ringgits",
    },
    MZN: {
      symbol: "MTn",
      name: "Mozambican Metical",
      symbol_native: "MTn",
      decimal_digits: 2,
      rounding: 0,
      code: "MZN",
      name_plural: "Mozambican meticals",
    },
    NAD: {
      symbol: "N$",
      name: "Namibian Dollar",
      symbol_native: "N$",
      decimal_digits: 2,
      rounding: 0,
      code: "NAD",
      name_plural: "Namibian dollars",
    },
    NGN: {
      symbol: "₦",
      name: "Nigerian Naira",
      symbol_native: "₦",
      decimal_digits: 2,
      rounding: 0,
      code: "NGN",
      name_plural: "Nigerian nairas",
    },
    NIO: {
      symbol: "C$",
      name: "Nicaraguan Córdoba",
      symbol_native: "C$",
      decimal_digits: 2,
      rounding: 0,
      code: "NIO",
      name_plural: "Nicaraguan córdobas",
    },
    NOK: {
      symbol: "Nkr",
      name: "Norwegian Krone",
      symbol_native: "kr",
      decimal_digits: 2,
      rounding: 0,
      code: "NOK",
      name_plural: "Norwegian kroner",
    },
    NPR: {
      symbol: "NPRs",
      name: "Nepalese Rupee",
      symbol_native: "नेरू",
      decimal_digits: 2,
      rounding: 0,
      code: "NPR",
      name_plural: "Nepalese rupees",
    },
    NZD: {
      symbol: "NZ$",
      name: "New Zealand Dollar",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "NZD",
      name_plural: "New Zealand dollars",
    },
    OMR: {
      symbol: "OMR",
      name: "Omani Rial",
      symbol_native: "ر.ع.‏",
      decimal_digits: 3,
      rounding: 0,
      code: "OMR",
      name_plural: "Omani rials",
    },
    PAB: {
      symbol: "B/.",
      name: "Panamanian Balboa",
      symbol_native: "B/.",
      decimal_digits: 2,
      rounding: 0,
      code: "PAB",
      name_plural: "Panamanian balboas",
    },
    PEN: {
      symbol: "S/.",
      name: "Peruvian Nuevo Sol",
      symbol_native: "S/.",
      decimal_digits: 2,
      rounding: 0,
      code: "PEN",
      name_plural: "Peruvian nuevos soles",
    },
    PHP: {
      symbol: "₱",
      name: "Philippine Peso",
      symbol_native: "₱",
      decimal_digits: 2,
      rounding: 0,
      code: "PHP",
      name_plural: "Philippine pesos",
    },
    PKR: {
      symbol: "PKRs",
      name: "Pakistani Rupee",
      symbol_native: "₨",
      decimal_digits: 0,
      rounding: 0,
      code: "PKR",
      name_plural: "Pakistani rupees",
    },
    PLN: {
      symbol: "zł",
      name: "Polish Zloty",
      symbol_native: "zł",
      decimal_digits: 2,
      rounding: 0,
      code: "PLN",
      name_plural: "Polish zlotys",
    },
    PYG: {
      symbol: "₲",
      name: "Paraguayan Guarani",
      symbol_native: "₲",
      decimal_digits: 0,
      rounding: 0,
      code: "PYG",
      name_plural: "Paraguayan guaranis",
    },
    QAR: {
      symbol: "QR",
      name: "Qatari Rial",
      symbol_native: "ر.ق.‏",
      decimal_digits: 2,
      rounding: 0,
      code: "QAR",
      name_plural: "Qatari rials",
    },
    RON: {
      symbol: "RON",
      name: "Romanian Leu",
      symbol_native: "RON",
      decimal_digits: 2,
      rounding: 0,
      code: "RON",
      name_plural: "Romanian lei",
    },
    RSD: {
      symbol: "din.",
      name: "Serbian Dinar",
      symbol_native: "дин.",
      decimal_digits: 0,
      rounding: 0,
      code: "RSD",
      name_plural: "Serbian dinars",
    },
    RUB: {
      symbol: "RUB",
      name: "Russian Ruble",
      symbol_native: "₽.",
      decimal_digits: 2,
      rounding: 0,
      code: "RUB",
      name_plural: "Russian rubles",
    },
    RWF: {
      symbol: "RWF",
      name: "Rwandan Franc",
      symbol_native: "FR",
      decimal_digits: 0,
      rounding: 0,
      code: "RWF",
      name_plural: "Rwandan francs",
    },
    SAR: {
      symbol: "SR",
      name: "Saudi Riyal",
      symbol_native: "ر.س.‏",
      decimal_digits: 2,
      rounding: 0,
      code: "SAR",
      name_plural: "Saudi riyals",
    },
    SDG: {
      symbol: "SDG",
      name: "Sudanese Pound",
      symbol_native: "SDG",
      decimal_digits: 2,
      rounding: 0,
      code: "SDG",
      name_plural: "Sudanese pounds",
    },
    SEK: {
      symbol: "Skr",
      name: "Swedish Krona",
      symbol_native: "kr",
      decimal_digits: 2,
      rounding: 0,
      code: "SEK",
      name_plural: "Swedish kronor",
    },
    SGD: {
      symbol: "S$",
      name: "Singapore Dollar",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "SGD",
      name_plural: "Singapore dollars",
    },
    SOS: {
      symbol: "Ssh",
      name: "Somali Shilling",
      symbol_native: "Ssh",
      decimal_digits: 0,
      rounding: 0,
      code: "SOS",
      name_plural: "Somali shillings",
    },
    SYP: {
      symbol: "SY£",
      name: "Syrian Pound",
      symbol_native: "ل.س.‏",
      decimal_digits: 0,
      rounding: 0,
      code: "SYP",
      name_plural: "Syrian pounds",
    },
    THB: {
      symbol: "฿",
      name: "Thai Baht",
      symbol_native: "฿",
      decimal_digits: 2,
      rounding: 0,
      code: "THB",
      name_plural: "Thai baht",
    },
    TND: {
      symbol: "DT",
      name: "Tunisian Dinar",
      symbol_native: "د.ت.‏",
      decimal_digits: 3,
      rounding: 0,
      code: "TND",
      name_plural: "Tunisian dinars",
    },
    TOP: {
      symbol: "T$",
      name: "Tongan Paʻanga",
      symbol_native: "T$",
      decimal_digits: 2,
      rounding: 0,
      code: "TOP",
      name_plural: "Tongan paʻanga",
    },
    TRY: {
      symbol: "TL",
      name: "Turkish Lira",
      symbol_native: "TL",
      decimal_digits: 2,
      rounding: 0,
      code: "TRY",
      name_plural: "Turkish Lira",
    },
    TTD: {
      symbol: "TT$",
      name: "Trinidad and Tobago Dollar",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "TTD",
      name_plural: "Trinidad and Tobago dollars",
    },
    TWD: {
      symbol: "NT$",
      name: "New Taiwan Dollar",
      symbol_native: "NT$",
      decimal_digits: 2,
      rounding: 0,
      code: "TWD",
      name_plural: "New Taiwan dollars",
    },
    TZS: {
      symbol: "TSh",
      name: "Tanzanian Shilling",
      symbol_native: "TSh",
      decimal_digits: 0,
      rounding: 0,
      code: "TZS",
      name_plural: "Tanzanian shillings",
    },
    UAH: {
      symbol: "₴",
      name: "Ukrainian Hryvnia",
      symbol_native: "₴",
      decimal_digits: 2,
      rounding: 0,
      code: "UAH",
      name_plural: "Ukrainian hryvnias",
    },
    UGX: {
      symbol: "USh",
      name: "Ugandan Shilling",
      symbol_native: "USh",
      decimal_digits: 0,
      rounding: 0,
      code: "UGX",
      name_plural: "Ugandan shillings",
    },
    UYU: {
      symbol: "$U",
      name: "Uruguayan Peso",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "UYU",
      name_plural: "Uruguayan pesos",
    },
    UZS: {
      symbol: "UZS",
      name: "Uzbekistan Som",
      symbol_native: "UZS",
      decimal_digits: 0,
      rounding: 0,
      code: "UZS",
      name_plural: "Uzbekistan som",
    },
    VEF: {
      symbol: "Bs.F.",
      name: "Venezuelan Bolívar",
      symbol_native: "Bs.F.",
      decimal_digits: 2,
      rounding: 0,
      code: "VEF",
      name_plural: "Venezuelan bolívars",
    },
    VND: {
      symbol: "₫",
      name: "Vietnamese Dong",
      symbol_native: "₫",
      decimal_digits: 0,
      rounding: 0,
      code: "VND",
      name_plural: "Vietnamese dong",
    },
    XAF: {
      symbol: "FCFA",
      name: "CFA Franc BEAC",
      symbol_native: "FCFA",
      decimal_digits: 0,
      rounding: 0,
      code: "XAF",
      name_plural: "CFA francs BEAC",
    },
    XOF: {
      symbol: "CFA",
      name: "CFA Franc BCEAO",
      symbol_native: "CFA",
      decimal_digits: 0,
      rounding: 0,
      code: "XOF",
      name_plural: "CFA francs BCEAO",
    },
    YER: {
      symbol: "YR",
      name: "Yemeni Rial",
      symbol_native: "ر.ي.‏",
      decimal_digits: 0,
      rounding: 0,
      code: "YER",
      name_plural: "Yemeni rials",
    },
    ZAR: {
      symbol: "R",
      name: "South African Rand",
      symbol_native: "R",
      decimal_digits: 2,
      rounding: 0,
      code: "ZAR",
      name_plural: "South African rand",
    },
    ZMK: {
      symbol: "ZK",
      name: "Zambian Kwacha",
      symbol_native: "ZK",
      decimal_digits: 0,
      rounding: 0,
      code: "ZMK",
      name_plural: "Zambian kwachas",
    },
    ZWL: {
      symbol: "ZWL$",
      name: "Zimbabwean Dollar",
      symbol_native: "ZWL$",
      decimal_digits: 0,
      rounding: 0,
      code: "ZWL",
      name_plural: "Zimbabwean Dollar",
    },
  },
  prefixLookup(num) {
    const index = Object.values(this.countries).findIndex(
      (a) => a.prefix == num.replace("+", ""),
    );
    return index >= 0 ? Object.keys(this.countries)[index] : null;
  },
  countriesOptions(top) {
    const options = {
      top: [],
      other: [],
    };

    Object.keys(this.countries).map((a) => {
      const countryData = this.countries[a];
      const country = Object.assign({}, countryData, {
        code: a,
        value: a,
        title: countryData.name,
      });
      if (AppConfig.topCountries.includes(a)) {
        options.top.push(country);
      } else if (country.flag) {
        options.other.push(country);
      }
    });

    if (!top) {
      return [...options.top, ...options.other].sort((a, b) =>
        a.name > b.name ? 1 : -1,
      );
    } else {
      options.top.sort((a, b) => (a.name > b.name ? 1 : -1));
      options.other.sort((a, b) => (a.name > b.name ? 1 : -1));
      return options;
    }
  },
};
