import "vuetify/styles";
import Styles from "Core/config/VuetifyStyles";

import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import { light, dark } from "Core/config/VuetifyThemes";

// https://vuetifyjs.com/en/introduction/why-vuetify/'#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: "light",
    variations: {
      colors: ["primary", "secondary", "warning", "error", "success", "grey"],
      lighten: 5,
      darken: 5,
    },
    themes: {
      light,
      dark,
    },
  },
  display: {
    mobileBreakpoint: "sm",
    thresholds: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1025,
      xl: 1280,
      xxl: 1800,
    },
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
    dropdown: "mdi-chevron-down",
  },
  defaults: Styles,
});
