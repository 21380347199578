export default {
  title: "Wildjar",
  logo: "/assets/brand/wildjar-text.png",
  icon: "/static/_app/branding/icon-256.png",
  login: "/assets/brand/wildjar-logo.png",
  links: {
    support: "https://support.wildjar.com/hc/en-us/",
    tcs: "https://www.wildjar.com/terms-and-conditions",
  },
  stickyHeader: true,
  style: "",
  cssFile: "",
  colors: {
    primary: "#5d78ff",
    secondary: "#ffa709",
    accent: "#82B1FF",
    blue: "#5d78ff",
    dark: "#333333",
    error: "#fd397a",
    info: "#5d78ff",
    success: "#0abb87",
    background: "#FFFFFF",
    "background-2": "#f2f3f8",
    menu: "#1e1e2d",
    "menu-dark": "#1b1b28",
    "menu-text": "#FFFFFF",
    border: "#dbdbdb",
    text: "#595d6e",
  },
};
