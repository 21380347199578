import AppConfig from "Constants/AppConfig";
import REQ from "Api/Requests";

import ReportGroupItem from "Models/reports/GroupItem";

export default {
  async reportSummary(params) {
    try {
      const path = REQ.setPath("reports/summary2/", params, [
        "accounts",
        "date",
      ]);
      const response = await REQ.get(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async tableReport(grouping, params) {
    try {
      let filters = params.account ? ["date"] : ["accounts", "date"];
      const path = REQ.setPath(
        "reports/groupList/" + grouping,
        params,
        filters,
      );
      const response = await REQ.get(path);
      return response.data.map((a) => Object.assign(new ReportGroupItem(), a));
    } catch (err) {
      throw err;
    }
  },
};
