export default [
  {
    path: "/not-found",
    name: "not-found",
    component: () => import("Views/core/NotFound2.vue"),
    meta: {
      public: true,
      title: "404",
      breadcrumb: "404",
    },
  },
];
