export default {
  path: "/settings/wizard/:id/dni",
  name: "settings-single-wizard-dni",
  components: {
    mobile: () => import("Layouts/menus/mobile/Default"),
    sidebar: () => import("Layouts/menus/sidebar/Default"),
    header: () => import("Components/headers/Default.vue"),
    contentHeader: () => import("Components/contentHeaders/Default.vue"),
    footer: () => import("Components/footers/Default.vue"),
    main: () => import("Views/settings/Wizard.vue"),
    wizard: () => import("Views/settings/wizard/Dni.vue"),
  },
  meta: {
    auth: true,
    editable: [],
    readable: ["admin", "super", "system", "support"],
    guarded: false,
    tab: "dni",
  },
};
