import { defineStore } from "pinia";
import DefaultTheme from "Constants/DefaultTheme";

import CustomisationModel from "Models/whiteLabel/CustomisationModel";

export const useThemeStore = defineStore("theme", {
  state: () => ({
    darkMode: false,
    currentTheme: null,
    customisation: new CustomisationModel(),
  }),
  getters: {
    copyrightText() {
      return (
        this.title + " © " + new Date().getFullYear() + " all rights reserved."
      );
    },
  },
  actions: {
    async getTheme() {
      try {
        const response = await API.Billing.customisation.getTheme(
          window.location.hostname,
        );
        this.customisation = Object.assign(this.customisation, response);
        this.currentTheme = Object.assign({}, this.customisation, response);
      } catch (err) {}
    },
    async resetCustomisations() {
      this.customisation = Object.assign(this.customisation, DefaultTheme);
    },
    async resetDefault() {
      this.customisation = Object.assign(this.customisation, this.currentTheme);
    },
  },
  persist: false,
});
