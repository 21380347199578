import Prospects from "./Prospects";
import Prospect from "./Prospect";
import Clients from "./ClientsList";
import Client from "./Client";
import Invoices from "./Invoices";

export default {
  path: "/clients",
  name: "clients",
  redirect: "/clients/list",
  components: {
    mobile: () => import("Layouts/menus/mobile/Default"),
    sidebar: () => import("Layouts/menus/sidebar/Default"),
    header: () => import("Components/headers/Default.vue"),
    contentHeader: () => import("Components/contentHeaders/Default.vue"),
    footer: () => import("Components/footers/Default.vue"),
    main: () => import("Views/clients/Clients.vue"),
  },
  meta: {
    auth: true,
    editable: [],
    readable: ["super", "system", "support"],
    guarded: false,
  },
  children: [Prospects, Prospect, Clients, Client, Invoices],
};
