export default class PortingListItem {
  constructor(
    account,
    carrier,
    customer,
    id,
    numbers,
    request,
    status,
    type,
    user,
  ) {
    this.account = account;
    this.carrier = carrier;
    this.customer = customer;
    this.id = id;
    this.numbers = numbers || [];
    this.request = request;
    this.status = status;
    this.type = type;
    this.user = user;
  }
}
