import AppConfig from "Constants/AppConfig";
import REQ from "Api/Requests";

import Contact from "Models/users/Contact";

export default {
  async list() {
    try {
      const path = REQ.setPath("addressbook/");
      const response = await REQ.get(path);
      return response.data.map((a) => Object.assign(new Contact(), a));
    } catch (err) {
      throw err;
    }
  },

  async get(uuid) {
    try {
      const path = REQ.setPath("addressbook/" + uuid);
      const response = await REQ.get(path);
      return Object.assign(new Contact(), response.data);
    } catch (err) {
      throw err;
    }
  },

  async create(payload) {
    try {
      const path = REQ.setPath("addressbook/");
      const response = await REQ.put(path, payload);
      return Object.assign(new Contact(), response.data);
    } catch (err) {
      throw err;
    }
  },

  async edit(payload) {
    try {
      const path = REQ.setPath("addressbook/" + payload.uuid);
      const response = await REQ.post(path, payload);
      return Object.assign(new Contact(), response.data);
    } catch (err) {
      throw err;
    }
  },

  async delete(uuid) {
    try {
      const path = REQ.setPath("addressbook/" + uuid);
      const response = await REQ.delete(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },
};
