export default {
  abandoned: "Abbandoned",
  account: "Account",
  actions: "actions",
  accept: "accept",
  activate: "activate",
  add: "add",
  addConfiguration: "Add Configuration",
  addCondition: "Add Condition",
  addContact: "Add Contact",
  addNumbers: "add numbers",
  advanced: "Advanced",
  advancedOptions: "Advanced Options",
  advancedFlow: "Advanced flow",
  agent: "Agent",
  all: "all",
  allAccounts: "All Accounts",
  and: "and",
  answered: "Answered",
  apply: "apply",
  availableForAccount: "available for this account",
  cancel: "cancel",
  chooseOne: "Choose one of the following",
  chooseNumber: "Choose Number",
  chooseSource: "Choose Source",
  chooseOptions: "Choose options",
  chooseOption: "Choose option",
  clickHere: "click here",
  comingSoon: "Coming soon",
  clear: "clear",
  close: "close",
  colors: "colors",
  configure: "configure",
  confirm: "Confirm",
  confirmNavigation: "confirm navigation",
  contact: "Contact",
  contacts: "Contacts",
  continue: "continue",
  copiedToClipboard: "Copied to clipboard",
  create: "create",
  credit: "credit",
  csv: "csv",
  currentPassword: "Current Password",
  date: "date",
  dataNotAvailable: "Data not available",
  decommissioned: "Decommissioned",
  delete: "Delete",
  deleteConfiguration: "Delete configuration",
  disabled: "Disabled",
  disconnect: "disconnect",
  download: "download",
  downloadDemoFile: "Download demo file",
  email: "email",
  emailAddress: "email address",
  enabled: "Enabled",
  excel: "excel",
  export: "export",
  exportTools: "export tools",
  extra: "extra",
  failed: "failed",
  file: "file",
  firstCall: "first call",
  firstName: "first name",
  for: "for",
  frequency: "frequency",
  frequentlyUsed: "frequentlyUsed",
  from: "from",
  givenName: "given name",
  greater: "greater",
  group: "group",
  helpdesk: "helpdesk",
  hi: "hi",
  hide: "hide",
  import: "import",
  includeSubAccounts: "Include All {num} Sub accounts",
  install: "Install",
  invalid: "invalid",
  insertAmount: "insert amount",
  inUse: "in use",
  itemsSelected: "items selected",
  lastName: "last name",
  less: "less",
  logout: "Log Out",
  login: "Log in",
  loginAgain: "Login again",
  loginAs: "Login as",
  messageSent: "message sent",
  needHelp: "Need Help",
  new: "new",
  newPassword: "New Password",
  next: "next",
  noDataAvailable: "No data available",
  noDidsForAccount: "No numbers configured for this account",
  noResultsMatched: "No results matched",
  name: "name",
  newUpload: "New upload",
  number: "number",
  numbers: "numbers",
  noChangeSave: "No changes to be saved",
  ok: "ok",
  open: "open",
  openDetails: "open details",
  optional: "optional",
  options: "options",
  on: "on",
  off: "off",
  other: "other",
  others: "others",
  outbound: "outbound",
  page: "page",
  pagess: "pages",
  parse: "parse",
  pdf: "pdf",
  perMessage: "per message",
  perMinute: "per minute",
  phone: "phone",
  phoneNumber: "phone number",
  preview: "preview",
  private: "private",
  proceed: "proceed",
  print: "print",
  quote: "quote",
  remove: "remove",
  removed: "removed",
  recharge: "recharge",
  recipients: "recipients",
  refresh: "refresh",
  replacing: "replacing",
  reply: "reply",
  request: "request",
  reject: "reject",
  reset: "reset",
  save: "save",
  search: "search",
  seconds: "seconds",
  selectAccount: "select account",
  selectAccountOnly: "select only this account",
  selectAll: "select all",
  selectItems: "Select items",
  selectTime: "Select Time",
  selectOption: "Select an option",
  settings: "settings",
  share: "share",
  show: "show",
  sms: "sms",
  status: "status",
  submit: "submit",
  suspended: "suspended",
  tag: "tag",
  tags: "tags",
  termsConds: "terms and conditions",
  than: "than",
  time: "time",
  title: "title",
  to: "to",
  total: "total",
  trackingNumber: "Tracking Number",
  trackingNumbers: "Tracking Numbers",
  ttpText: "Text to speech message",
  ttpAudio: "Text to speech audio URL",
  ttpOrAudio: "Text to speech or audio URL",
  type: "type",
  typeToSearchAddress: "Start typing to search address",
  audioUrl: "Audio file url",
  typeToSearch: "Type to Search",
  unshare: "unshare",
  untilToday: "until today",
  upload: "upload",
  uploadCsv: "Upload CSV file",
  update: "Update",
  url: "url",
  unsavedChanges: "There are unsaved changes that will be lost, proceed?",
  verifyPassword: "Verify Password",
  view: "view",
  webDialer: "Web Dialer",
  with: "with",
  you: "you",

  maxFileSize: "Max file size is {size}",
  daily: "daily",
  weekly: "weekly",
  monthly: "monthly",
  timeAgo: "{text} ago",

  headers: {
    addressBook: "Address book",
    addressBookSubtitle: "Contact list per account scructure",
    userPreferences: "User Preferences",
    userPreferencesSubtitle: "Manage your user settings",
    supportPage: "Support page",
    administration: "Administration",
    accountStructure: "Account structure",
    allAccounts: "All Accounts",
    includeSubAccounts: "Include Sub Accounts",
    createNewAccount: "Create New Account",
    addTrackingNumber: "Add Tracking Number",
    blockedNumbers: "Blocked Numbers",
    blockedNumbersSubtitle: "Blocked Calling Numbers / Spam Filter",
    emailReports: "Email Reports",
    emailReportsSubtitle: "Daily, weekly & monthly reports",
    inviteUser: "Invite User",
  },

  administration: {
    payments: "Payments",
    paymentsSubtitle: "Invoices & Payments",
    summary: "Summary",
    summarySubtitle: "Monthly Summary",
    customisation: "Customisation",
    whiteLabel: "White Label",
    customisationSubtitle: "White Label Options",
    users: "Users",
    usersSubtitle: "Manage Web Access",
    billerCodes: "Biller Codes",
    billerCodesSubtitle: "Clients Management",
    prospects: "Prospects",
    prospectsSubtitle: "Activation Links",
  },

  pwa: {
    updateAvailable: "Update available",
    pwaInstall: "Install WildJar directly on your device",
  },

  pages: {
    login: {
      title: "Sign In To ",
      signIn: "Sign In",
      forgotPassword: "forgot password",
      acceptInvitation: "Accept Invitation",
    },
    forgotPassword: {
      title: "Forgotten Password ?",
      subtitle: "Enter your email to reset your password:",
      successInfo:
        "Password recovery instruction has been sent to your email address.",
      backToLogin: "Back to Login",
    },
    resetPassword: {
      title: "Reset Password",
      invalidHash: "invalid  hash",
      update: "Update new password",
      ready: "You are ready to go...",
      goToLogin: "Go to Login",
    },
    signup: {
      businessDetails: "Business Details",
      inviteSent:
        "An invitation/access email is going to be sent to <strong>{email}</strong>",
      stripeTitle: "Almost there!",
      stripeSubtitle:
        "Please provide your credit card details to join the pack.",
      stripeSubmit: "Authorize Monthly Payments",
      completeTitle: "Welcome to the pack!",
      completeSubtitle: "YPlease bear with us as we set up your account.",
      completeInfo:
        "You'll receive an email from pronto@trkcall.com with your invite to the dashboard shortly.",
    },
    dashboard: {
      top10: "TOP 10",
      adSources: "Tracking Sources",
      accounts: "Accounts",
      answerPoints: "Destination Numbers",
    },
    insights: {
      topTrackingTitle: "Top 5 Tracking Source for {date}",
      topSourceTitle: "Top Source / Medium for {date}",
      configureAccountInfo:
        "Please configure the category for the selected account.<br/><br/>If the account is configured properly but you still see this, we are sorry, but at the moment we don't have enough data to represent a category comparison for your vertical.",
    },
    outboundOverview: {
      accounts: "Accounts",
      agent: "Agent",
      name: "Name",
      newCallers: "New callers",
      abandoned: "Abandoned",
      answered: "Answered",
      missed: "Missed",
      failed: "Failed",
      notAnswered: "Not answered",
      duration: "Avg Duration",
      totalCalls: "Total Calls",
      totalCallsInfo: "Outbound phone calls",
      connectedCalls: "Connected Calls",
      connectedCallsInfo: "Answered phone calls",
      successRate: "Success Rate",
      successRateInfo: "Between Total & Connected",
      talkTime: "Avg Talk Time",
      averageTotalTime: "Average Total Time",
      averageTotalTimeInfo: "For all outbound calls",
      averageTalkTime: "Average Talk Time",
      averageTalkTimeInfo: "For outbound connected calls",
      averageScore: "Average Score",
      averageScoreInfo: "For scored calls only",
      missedCalls: "Missed Calls",
      missedCallsInfo: "{num} Answered, failed & abandoned calls",
      top10: "TOP 10",
    },
    callLog: {
      callLog: "Call Log",
      outboundCallLog: "Outbound Call Log",
    },
    callHistory: {
      caller: "Caller",
      notPresentInAdressbook: "Not present in address book",
      presentInAdressbook: "Present in address book",
      initialSource: "Initial Source",
      initialSourceInfo: "Phone or Web info from first source",
      trackingSource: "Tracking Source",
      trackingSourceInfo: "Fisrt Tracking Number contacted",
      firstInteraction: "First Interaction",
      firstInteractionInfo: "Across calls & sms",
      totalInteractions: "Total Interactions",
      totalInteractionsInfo: "Including calls & sms",
      reContact: "Re-contact",
      reContactInfo: "Average time have been called back",
      source: "Source",
      outboundCall: "Outbound Call",
    },
    billing: {
      whichBiller: "Which Biller",
      summary: {
        trackingNumbers: "Tracking Numbers",
        inboundCalls: "Inbound Calls",
        outboundCalls: "Outbound Calls",
        rental: "Rental",
        dids: "DIDs",
        amount: "Amount",
        minutes: "Minutes",
        calls: "Calls",
        transcript: "Transcript",
        sms: "SMS",
        bulk: "Bulk",
      },
      payments: {
        invoices: "Invoices",
        paidInvoices: "Paid nvoices",
        overdueInvoices: "Overdue Invoices",
        paymentMethods: "Payment Methods",
        availablePayments: "Available Payment Methods",
        billingPayments: "Billing Payments",
        selectCardInfo:
          "Selected card will be used for <strong>automatic payment of monthly invoices</strong>",
        selectCardSmsInfo:
          "Selected card will be used for <strong>automatic SMS top ups</strong>",
        noInvoices: "No Invoices yet",
        noPaymentMethods: "No payment methods present",
        openInvoice: "Open Invoice",
        addPaymentMethod: "Add Payment Method",
        addCard: "Add Credit Card",
        setPaymentMethod: "Set payment method",
        setPaymentMethodConfirm:
          "Set the selected card as default payment method for automatic payment of monthly invoices?",
        setPaymentMethodSmsConfirm:
          "Set the selected card as default payment method for automatic SMS top ups?",
        smsTopUp: "SMS Top-Up",
        smsCredit: "SMS Credit",
        topUpSuspended:
          "<strong>Automatic top-ups are suspended</strong> They will be re-activated in next few days",
        rechargeAmount: "Amount to recharge",
        creditCardToUse: "Credit Card to use",
        confirmRecharge: "You will recharge {amount}, confirm?",
        addSelectPayment: "Add or Select Payment Method",
        statuses: {
          PAID: "Paid",
          AUTHORISED: "Overdue",
          DELETED: "Deleted",
          PENDING: "Pending",
          VOIDED: "Voided",
          FAILED: "Failed",
        },
        expenses: {
          "NUMBER-RENTAL": "Rentals",
          "MONTHLY-FEE": "Monthly Fees",
          VOICE: "Call Charges",
          TRANSCRIPT: "Transcripts",
          "SMS-OUTBOUND": "SMSs",
          OTHER: "Other",
        },
      },
    },
    addressbook: {
      createNewContact: "Create New Contact",
      addPhoneNumbers: "Add phone numbers",
      defaultContactNum: "Default Contact Number",
    },
    messages: {
      removeInvalidNumbers: "remove invalid numbers",
      campaigns: {
        senderRecipients: "Sender and Recipients",
        messageContent: "Message Content",
        messageDetails: "Message Details",
        campaignConfig: "Campagin Configuration",
        campaignName: "Campaign Name",
        identificationCode: "Identification Code",
        sender: "Sender",
        account: "Account",
        message: "Message",
        targets: "Targets",
        uploadTargets: "Upload a list of targets",
        inputNumPerLine: "Input one number per line",
        schedule: "Schedule",
        scheduled: "Scheduled",
        enableSchedule: "Enable/Disable schedule",
        dummyText: "Opt-out reply STOP",
        contactList: "Contact list",
        noChangeList:
          "Changes to the list won't affect this campaign once scheduled",
        previousBulk: "Previous Bulk",
        quoteDetails: "Message overview",
        costs: "Costs",
        content: "Content",
        send: "Send",
        sendNow: "Send Now",
        preview: "Preview",
        concatenatedSms: "concatenated sms",
        characters: "characters",
        total: "total",
        sendPreview: "Send Preview",
        previewSent: "Preview successfully sent",
        hasUnicode: "Contains unicode characters",
        insufficientFunds: "Insufficient funds to send the campagin, please ",
        topUpCredit: "Top Up Your Credit",
        cancelQuote: "Cancel Quote",
        cancelQuoteAndEdit: "Cancel Quote and Edit Campaign",
        addNumbers: "Add Numbers",
        targetsListInput: "Targets input",
        invalidNums: "{count} Invalid Numbers Present",
        counts: "Counts",
        useList: "Lists",
        useListHit:
          "Use contacts from campaign lists, you can select mulitple lists and their contacts will be merged to the current targets",
        toNTargets: "{num} targets",
        activeCampaigns: "Active Campaigns",
        remoteIdCode: "Remote Identification Code",
        bulkCampaignName: "Bulk Campaign Name",
        deleteCampaign: "Delete Campaign",
        newCampaign: "New Campaign",
        deleteConfirm: "Bulk camaign will be deleted, proceed?",
        noRecipients: "No recipients provided, please add some targets",
        campaignSent: "Campaign successfully sent",
        campaignSaved: "Campaign successfully saved",
        campaignScheduled: "Campaign successfully scheduled",
      },
      reports: {
        title: "Bulk campaign reports",
        campaign: "Campaign",
        name: "Name",
        date: "Date",
        sent: "Sent",
        delivered: "Delivered",
        inList: "In List",
        bounced: "Bounced",
        blocked: "Opt-Outs",
        blocked2: "Blocked",
        replies: "Replies",
        dispatched: "Dispatched",
        deliveredToMobile: "Delivered to mobile",
        scheduledSms: "Scheduled SMS",
        sender: "sender",
        sentBy: "Sent by",
      },
      lists: {
        title: "Campaign Lists",
        changeListInfo:
          "Changes to a list won't affect a pre-scheduled campaign",
        uploadContacts: "Upload a List of Contacts",
        createNewList: "Create New List",
        newList: "New List",
        lists: "Lists",
        inputNumPerLine: "Input one number per line",
        deleteListConfirm: "Select list will be deleted permanently, proceed?",
        optOut: "Opt Out",
        optOutInfo: "Block phone numbers for SMS bulk campaigns",
        checkNumber: "Check Number",
        blockNumber: "Block Number",
        phoneNumber: "Phone Number",
        alreadyBlocked: "Already Blocked / Opted Out",
        numberBlocked: "Number Successfully Blocked",
        noListsPresent: "No Lists Present",
      },
      chats: {
        newSms: "New SMS",
        activeChats: "Active Conversations",
        startNewChat: "Send an SMS / Start a Conversation",
        sendSms: "Send SMS",
        noChatsPresent: "No active conversations yet",
        messageSent: "Message successfully sent",
      },
    },
    users: {
      userList: "User List",
      allow: "Allow",
      webPhone: "Web Phone",
      removeAcls: "Remove all custom ACLs",
      multiAccountAccess: "Multi account access",
      sendPasswordRecoveryMail: "send password recovery email",
      inviteUser: "Invite User",
      inviteSent: "Invite Sent",
      suspendUser: "suspend User",
      reactivate: "Re-activate",
      removeUser: "Remove User",
      userSuspended: "User Suspended",
      userRemoved: "User Removed",
      deleteUserConfirm: "User will deleted permanently, confirm?",
      viewLoginReport: "View Login Report",
      copyInviteCode: "Copy invite code",
      resendInvite: "Resend invite",
      newUser: "New User",
      userProfile: "User profile",
      masterAccount: "Master Account",
      personalInformation: "Personal Information",
      personalInformationInfo: "Update your personal information",
      changePassword: "Change Password",
      changePasswordInfo: "Should change it every 2 months",
      loginList: "Logins List",
      newReport: "New Report",
      detailedLog: "Detailed Call Log",
      includeSubAccounts: "Include Sub Accounts",
      groupByAccount: "Group By Account",
      createReportForAccount: "Create Report for Account",
      oauths: "3rd Party Authentication",
      actions: {
        forgot: {
          success: "Email sent!",
          error: "Email not dispatched",
        },
        "re-invite": {
          success: "Email sent!",
          error: "Email not dispatched",
        },
      },
      logins: {
        userLoginReport: "User Login Report",
        user: "User",
        account: "Account",
        logins: "Logins",
        duration: "Duration",
      },
      oauth: {
        title: "3rd Party Authentication",
        subtitle: "Required for external integrations",
        sharedBy: "Shared by",
        usedBy: "Used by {num} accounts",
        activeConfigurations: "Active configurations",
        unutilized: "unutilised",
        btnsDesc:
          "Use the following buttons to authorise your account with the following services",
        confirmDeleteActive:
          "You are going to delete a configuration which is active on <strong>{num}</strong> accounts.<br/><strong>This action is irreversible.</strong><br/><br/>To proceed type <strong>{num}</strong> in the following input.",
      },
    },
    settings: {
      newAccount: "New account",
      createNewAccount: "Create new account",
      accountDetails: "Account Details",
      decomissionAccount: "Decomission Account",
      decomissionNumber: "Decomission Number",
      deleteAccountConfirm:
        "Are you sure you want to destroy account configuration & data permanently?",
      deleteNumberConfirm:
        "Are you sure you want to permanently decommission this number and remove its call data?",
      configureTrackingNumber: "Configure new Tracking Number",
      orderTackingNumber: "Order a new Tracking Number",
      newTrackingNumberFor: "New Tracking Number for",
      sorryNoNumbers: "Sorry, no numbers available",
      moreSelectedNumbers: "more selected numbers",
      selectNumbers: "Select Number(s)",
      selectedNumbers: "Selected Number(s)",
      prefixOrLocation: "Prefix or Area",
      proceedOrderNumber:
        "Proceed with the order of the selected Tracking Numbers?",
      accountNumbers: "Account numbers",
      audioMsg: "Audio Message",
      audioFile: "Audio File",
      accountStructure: "Accounts Structure",
      moveAccountConfirm:
        'Are you sure you want to move <strong class="text-primary">{child}</strong> as sub account of <strong>{parent}</strong>?',
      moveAccountConfirmOk: "Yes, move it",
      moveAccountConfirmCancel: "Not too sure",
      viewAccountDetails: "View Account Details",
      porting: {
        requestsList: "Requests List",
        newRequest: "New Request",
        requestDetails: "Request Details",
        deleteRequestConfirm: "Request will be deleted permanently, proceed?",
      },
      blocks: {
        blockedNumbersFor: "Blocked Numbers For",
        number: "Caller Number",
        user: "Blocked By",
        date: "Blocked On",
        reason: "Reason",
        blockCaller: "Block Caller",
        callerToBlock: "Caller To Block",
        reasonForBlocking: "Reason For Blocking",
        block: "Block",
        unblock: "Unblock",
        numberUnblocked: "Caller Unblocked",
        unblockCaller: "Unblock this caller id",
        blockedNumbersList: "Blocked Numbers List",
      },
      customisation: {
        title: "Customisation",
        dashboard: "Dashboard",
        emailsTab: "Emails",
        invoices: "Invoices",
        domain: "Domain",
        logo: "Logo",
        icon: "Icon",
        loginLogo: "Login logo",
        support: "Support page",
        tcs: "Terms & Conditions",
        styleFile: "CSS file url",
        resetConfirm: "Confirm Reset",
        resetConfirmInfo:
          "All settings will be reset to default status, proceed?",
        previewNotice:
          "Changes are visualized in your dashboard as preview, they will not persist until saved",
        dash: {
          resetDefault: "Reset to default theme",
          css: "Custom CSS",
          stickyHeader: "Sticky Header",
          colors: {
            accent: "accent",
            background: "main background",
            "background-2": "alt background",
            blue: "blue",
            border: "border",
            dark: "dark",
            error: "error",
            info: "info",
            primary: "primary",
            secondary: "secondary",
            success: "success",
            menu: "menu background",
            "menu-dark": "menu item active bg",
            "menu-text": "menu item text",
            text: "text color",
            answered: "answered call",
            missed: "missed call",
            abandoned: "abandoned call",
          },
        },
        emails: {
          views: {
            fields: "Fields",
            text: "JSON",
            tree: "Tree",
          },
          types: {
            "Call-Notification": "Call Notification",
            "Client-activation": "Client Activation",
            "Scheduled-reports": "Scheduled Reports",
            "Signup-confirmation": "Signup Confirmation",
            SMS2email: "SMS 2 Email",
            "User-forgot-password": "User Forgot Password",
            "User-invite": "User Invite",
          },
          fields: {
            logo: "logo",
            brand: "brand",
            subject: "subject",
            from: "from address",
            SMSsender: "sender name",
            "text-title": "title text",
            "text-info": "info text",
            "text-link": "link text",
            dashboard: "dashboard url",
            domain: "domain",
            support: "support page",
            footer: "footer content",
            "color-page": "page color",
            "color-background": "background color",
            "color-title": "title color",
            "color-text": "text color",
            "color-link": "link color",
            "sms-source": "SMS source",
            "sms-sender": "SMS sender",
            "sms-content": "SMS content",
          },
        },
        smtp: {
          from: "From",
          replyTo: "Reply-to",
          host: "Host",
          username: "Username",
          password: "Password",
          port: "Port",
          tls: "TLS",
          defaultFrom: "Default from email: pronto@trkcall.com",
        },
      },
      wizard: {
        routing: "Call Routing",
        routingTitle: "Call Flow",
        routingInfo: "Tracking Number call routing",
        locations: "Manage Location Routing",
        locationsTitle: "Locations",
        locationsInfo: "Call Routing",
        branchesTitle: "Branches",
        branchesInfo: "In use by local routing",
        flow: "Advanced Call Flow",
        google: "Google Analytics",
        googleTitle: "Google analytics Configuration",
        details: "Number Details",
        detailsTitle: "Tracking Details",
        detailsInfo: "Main configuration",
        dni: "Dynamic Number (DNI)",
        dniTitle: "DNI",
        dniInfo: "Dynamic Number Insertion",
        messaging: "SMS/MMS routing",
        messagingTitle: "Messaging",
        messagingInfo: "SMS/MMS inbound configuration",
        branchName: "Branch Name",
        callRecording: "Call Recording",
        destinationNumber: "Destination Number",
        destinationNumberInfo: "Phone number to route the calls",
        forceCallerID: "Force Caller ID",
        forceCallerIDInfo:
          "We will display this phone number on the called party's phone. This will not affect your call logs",
        shareCallFlow: "Share Call Flow",
        friendlyName: "Friendly Name",
        friendlyNameInfo:
          "Friendly name or branch name, useful for smart reports",
        friendlyNameExample: "e.g. Sydney Branch",
        recording: "Recording",
        recordingMessage: "Recording Message",
        timeout: "Timeout",
        timeoutInfo:
          "How many seconds the destination will ring before the call is considered not answered",
        whisper: "Whisper",
        whisperInfo:
          "Receive this message when answering a call to notify you of where the caller is calling from. A quick heads up can make the difference!",
        whisperExample: "Whisper Message",
        voicemail: "Voicemail",
        voicemailMessage: "Voicemail Message",
        voicemailFile: "Voicemail file",
        sendNotification: "Send notification",
        multiTrackNum: "Multi Tracking Number",
        routingConfig: "Routing configuration",
        replaceConfig: "Replace routing configuration",
        replaceConfigInfo:
          "Remove this routing configuration and use the same routing from",
        reRoute: "re-route",
        callRecordingMsg: "Call recording",
        callRecordingFile: "Call recording file",
        startNewConfig: "Start a new configuration",
        clearConfig: "Start a new routing for this Tracking Number",
        otherRoutingDids: "Other DIDs with same routing",
        confirmEditRouting: "The selected routing will be applied, proceed?",
        confirmResetRouting:
          "All setting for routing will be reset, <br/><br/> Starting a new routing is going to remove any call routing for this tracking number, make sure to save a new routing or calls won't be terminated <br/><br/> Proceed?",
        stopExample: "e.g. STOP",
        optOut: "Opt Out",
        optOutInfo: 'Used to detect "opt-outs" for outbound bulk campaign',
        useMessage: "Write a Text to Speech message",
        useFile: "Upload or link an Audio file",
        assignSameRoutingDups:
          "Assign the same routing to the other selected numbers?{numbers}",
        complexRouting: "Complex Routing",
        complexRoutingInfo:
          "This call flow looks a bit too convoluted for the quick edit, try using the full call flow application.",
        routingShared:
          "This Call Flow is shared between multiple tracking numbers.",
        saveFlow: "Save Flow",
        locationsData: {
          addBranch: "Add Branch",
          uploadCsvBranches: "Upload CSV Branches",
          branchesLocations: "Branches / Locations",
          deleteBranchConfirm: "Branch will be deleted permanently, proceed?",
          branchDeleted: "Branch deleted",
          automaticRouting: "Automatic Routing",
          ivrRounting: "IVR Routing",
          routePhoneNumber: "Route based on phone number",
          mobileLocations: "Get location from mobile network",
          postcodeLocation: "PostCode / Location Mapping",
          branchMapping: "Branch Mapping",
          downloadexisitingRouting: "Download existing routing",
          defaultBranch: "Default Branch",
          callDeliver: "To divert calls not matching any location",
          propmptIvr: "Prompt IVR PostCode",
          ivrMenuMsg: "IVR menu message",
          ivrMenuFile: "IVR menu audio file",
          ivrCodeSettings: "IVR Postcode Settings",
          ivrDigits: "IVR Digits",
          ivrAttempts: "IVR Attempts",
          postcodeDigits: "postcode digits",
          invalidPostcodeRetry: "retry in case of invalid postcode",
        },
        flows: {
          DnD: "Drag & drop to create a new action",
          showTooltips: "Show Helpers Tooltips",
          keepLocations: "Keep Edit Form in the same location",
          autoPos: "Automatic Positioning",
          saveFlow: "Save Call Flow",
          newTransfer: "New <strong>Transfer</strong>",
          newVoicemail: "New <strong>Voicemail</strong>",
          newTimeRouting: "New <strong>Time Routing</strong>",
          newIVR: "New <strong>IVR</strong>",
          newTag: "Assign new <strong>Tag</strong>",
          newRoundRobin: "New <strong>Round Robin</strong>",
          newLocationRouting: "New <strong>Location Routing</strong>",
          restoreConfig: "Restore previous configuration",
          destroyConfig: "Destroy action configuration",
          noDbSave: "Doesn't save on database",
          selectConnectionTodelete:
            '<span class="text-error">Select connection to delete it</span>',
          callAnswered: "Call has been answered",
          routingAfterCallFailure: "Routing after call failure",
          availableIvrRouting: "Available IVR routing for valid input",
          ivrTimeoutAction:
            "Action to perform if IVR digits timeout or invalid option",
          defaultTimeRouting:
            "Default routing if not filtered by specific times",
          timeRounting: "Routing that match time filter",
          robinRouting: "Routing to assign",
          enableDisableRec: "Enable/Disable Call Recording",
          forceCallerIdInfo:
            "We will display this phone number on the called party's phone. This will not affect your call logs.",
          callWhisper: "Call Whisper",
          selectTags: "Select Tags",
          numberOfRetries: "Number of Retries",
          allowedDigits: "Allowed Digits",
          digits: "Digits",
          digitsInfo:
            "How many digits to accept as valid input (eg: postcode IVR should have 4, basic IVR should have 1)",
          digitTimeout: "Digit Timeout",
          digitTimeoutInfo:
            "How many seconds between one digit and the other before considering the user not sure and reply the message or send him to the default option",
          retries: "Retries",
          retriesInfo:
            "How many attempts/retries to offer to the caller before sending him to the default option",
          timeoutInfo:
            "How many seconds before considering the user not sure and reply the message or send him to the default option",
          connectionHasSourcesDelete:
            "There are other connections which will be deleted to insert this one, proceed?",
          createLocationsWarning:
            "By activating the Locations action, a new tab will added where you can configure separate flows for each different routing based on the caller's location.<br/><br/>All other present actions will be removed, proceed?",
          deleteLocationsFirst:
            "To change the current configuration you need to delete the Locations action first",
          errorWithFlow: "Save failed, errors found in flow configuration",
        },
      },
    },
    dni: {
      advanced: "Advanced",
      addDomain: "Add Domain",
      whichDomain: "Which Domain",
      domain: "Domain",
      domainMock: "Ex: sitename.com",
      numbers: "Numbers",
      snippet: "Snippet",
      configuration: "Configuration",
      addNumber: "Add Number",
      addSwapRule: "Add Swap Rule",
      visitorsFrom: "Visitors from (medium)",
      websiteNumber: "The phone number on the website",
      trackingNumber: "Tracking Number",
      addTrackingSetting: "Add Tracking Setting",
      sourceReferral: "source / referral",
      campaign: "Campaign",
      keywords: "Keywords",
      replacementType: "Replacement Content Type",
      customReturn: "Custom Return",
      importUtm: "Import UTM",
      newNumber: "New Number",
      removeNumber: "Remove Number",
      removeDni: "Delete domain",
      removeDniInfo: "Completaly remove domain configuration",
      removeSetting: "Remove Setting",
      advancedSettings: "Advanced",
      pools: "Pools",
      trackingSetting: "Tracking setting",
      javascriptSnippet: "Javascript Snippet",
      deleteDomainConfirm:
        "This domain and all it's configurations will be removed, proceed?",
      needApprovalInfo:
        "This domain is already configured for BuxEE test, out support have been contacted and is going to check with the account administrators to grant you the privileges to configure DNI (Dynamic Number Insertion) for you.",
      snippetInfo:
        "Install the below code in Google Tag Manager as a Custom HTML tag and trigger to fire on all pages.",
      utmtagExample:
        "eg: https://trkcall.com/?utm_source=facebook&utm_medium=cpc&utm_campaign=social-offer&utm_term=%7Bkeyword%7D",
      stringToParse: "String to Parse",
      poolConfiguration: "Pool Configuration",
      managePool: "Manage pool",
      additionalNumbers: "Additional Numbers",
      configuredNumber: "Configured Number",
      configuredNumbers: "Configured Tracking Numbers",
      noConfiguredNumbers: "No Configured Tracking Numbers",
      selectAdditionalNumbers: "Select Additional Numbers",
      poolTooltip: "Tracking Numbers Pool to enhance tracking",
      poolInfo1:
        '<strong>Suggested for:</strong><ul class="pl-8 mt-1"><li>High volume website</li><li>Multiple campaigns tracking</li><li>Data freaks</li></ul>',
      poolInfo2:
        '<strong>Our approach:</strong><p class="px-2 py-1">Configuration stay as is, but you can link a new tracking number to the existing one, so it\'s gonna rotate on your website,at any time you could move your "extra" tracking number across pool, where you need more accuracy.</p>',
      pendingApproval: "Pending Approval",
      pendingApprovalInfo:
        "This domain is already configured for BuxEE test, out support have been contacted and is going to check with the account administrators to grant you the privileges to configure DNI (Dynamic Number Insertion) for you.",
      removeRequest: "Remove Request",
      webAliases: "Web Aliases",
      sharedAccounts: "Shared Accounts",
      numberForAnyPaid:
        "This tracking number will be used for any paid visit, to configure specific sources please use the advanced features.",
      managedByAccount: "Managed by account",
      replacedBy: "Replaced by",
      aliasAlreadyPresent: "Alias Already Present",
      seeAllConfigurations: "View All Pools",
      allPoolConfigurations: "Other configured pools",
      mediums: {
        any: "All Traffic",
        "(none)": "Direct Visitors",
        organic: "Organic Search",
        paid: "Paid",
        referral: "Referral",
        custom: "Custom",
        anyTitle: "All Traffic",
        "(none)Title": "Direct Traffic",
        organicTitle: "Organic Search",
        paidTitle: "Paid Clicks ({source})",
        referralTitle: "Visitors from {source}",
        customTitle: "Custom ({source})",
      },
      sources: {
        any: "Any",
        google: "Google",
        bing: "Bing",
        facebook: "Facebook",
      },
      replacements: {
        text: "Number",
        image: "image",
        custom: "Text",
        js: "Javascript",
      },
    },
    powerups: {
      deleteConfigurationConfirm: "Configuration will be deleted, proceed?",
      followup: {
        cardDesc:
          "Re-engage with customers via SMS, making it easy to follow up and save lost opportunities.",
        cardNotice: "charge per SMS, invoiced monthly",
        title: "SMS Follow Up",
        titlefor: "SMS Follow Up for <strong>{account}</strong>",
        intro:
          'To configure SMS Follow Up, you must have a mobile number to send and receive SMS messages.<br/><br/>You can use the automatic number order below to quickly set one up.<br/>Alternatively <a class="underline" href="/settings/tracking-number/new">click here</a> to do a complete new mobile Tracking Number setup, then return to this page.',
        configureNumber: "Configure new mobile Tracking Number:",
        emailAddress: "Email Address",
        emailAddressHint: "Email address to receive SMS responses",
        country: "Country",
        sender: "Sender",
        callStatus: "Call Status",
        answeredMessage: "Answered Call Message",
        answeredMessagePlaceholder:
          "Answered Calls: Please enter the text you would like to send to your customer via SMS.",
        missedMessage: "Missed Call Message",
        missedMessagePlaceholder:
          "Missed Calls: Please enter the text you would like to send to your customer via SMS.",
        trackingNumbers: "TRacking Numbers",
        trackingNumbersInfo:
          "Select a tracking number to apply the SMS follow up message.",
        useDifferentSender: "Use different sender",
        chooseSender: "Choose sender",
      },
      tags: {
        cardDesc:
          "Gain instant lead quality by automatically tagging and scoring your leads, based on your callers behaviours.",
        smartTagsFor: "Smart Tags for",
        noTagsPresent: "No Tags Present",
        tagDetails: "Tag Details",
        newTag: "New Tag",
        noTagsFound: "No Tags Found",
        smartTag: "Smart Tag",
        smartTagDesc:
          "Gain instant lead quality by automatically tagging and scoring your leads, based on your callers behaviours.<br/>Each account can have its own set of Smart Tags created, edited and colour coded to help you sort your data faster.<br/>Once a call is tagged, they can be used for integrating into 3rd party platforms.",
        groups: "Groups",
        groupDesc:
          "Creating a Group gives you more flexibility for reporting, and allows you to ‘Group’ multiple Smart Tags.",
        leadScorer: "Lead Scorer",
        leadScorerDesc:
          "Using your phones keypad, you can set up a Smart Tag which will automatically trigger when specific Lead Scorer criteria is configured.<br/>You can set keypad entries such as “*1” to score the call as a successful booking and gain instant feedback.",
        genericTags:
          'Generic Tags <span class="fs-10 caps ml-2 text-primary">without group</span>',
        group: "Group",
        groupOptional: "Group (optional)",
        groupPlaceholder: "Enter Tag Group",
        groupInfo: "Used for advanced reporting",
        name: "Name",
        namePlaceholder: "Enter Tag Name",
        nameInfo: "Name to apply on call match",
        score: "Score",
        color: "Color",
        conditions: "Conditions",
        addCondition: "Add Condition",
        exportGroupNotice: "<strong>Special tag</strong> for report generation",
        applySubaccounts: "apply to all sub accounts",
        noConditionTagInfo:
          "This tag can be applied only from advanced dialplan as does't have any conditions",
      },
      intelligence: {
        cardDesc:
          "Transcribe calls, identify specific words spoken and uncover your customer’s emotion and sentiment.",
        cardNotice: "charge per minute apply",
        callIntelligenceFor: "Call Intelligence for",
        callIntelligence: "Call Intelligence",
        spotlight: "Spotlight",
        spotlights: "Spotlights",
        note: "NOTE: Call Recording must be turned on for transcription and Spotlight to work.",
        desc: '<strong>Spotlight</strong> brings a new layer of intelligence to your customer interactions.<br/>Automatically highlight the keywords that matter most to your business so you can respond accordingly.<br/><br/><ul class="pl-5"><li>Analyse each customer interaction quickly</li><li>Identify when important keywords are used at a glance</li><li>Use Spotlight to tag and score interactions based on specific keywords spoken</li><li>Understand emotion and sentiment of the caller</li></ul>',
        desc2:
          "Turn on the numbers you wish to transcribe conversations below, and start creating your Spotlight tags!",
        newSpotlight: "New Spotlight",
        noSpotlightsPresent: "No Spotlights Present",
        spotlightDetails: "Spotlight Details",
        enableIntelligence: "Enable Call Intelligence for this Account",
        reProcessCalls: "Re-Process past calls",
        quoteReProcess: "Quote Re-Process",
        reProcessProceed: "Proceed with the Re-process",
        reProcessDateRange: "Select time span of calls to re-process",
        reProcessQuoteCost:
          'Calls between <span class="text-primary">{from}</span> and <span class="text-primary">{to}</span>.<br/><br/>The cost of the re-process is <strong class="text-warning fs-18">{cost}</strong> for <strong class="fs-18 text-primary">{calls}</strong> calls, for a total of <strong class="fs-18 text-primary">{minutes}</strong> minutes.<br/><br/>proceed with the re-process?',
        reProcessInfo:
          "All the calls in the selected time range will be re-processed, this is going to take some time to complete, you will be notified when the process is completed.",
        reProcessNoCalls: "No calls to re-process",
        reprocessEstimate:
          "The re-process will take around {amount} to complete, you will be notified once done.",
        spotlightData: {
          name: "Name",
          namePlaceholder: "Enter Spotlight Name",
          nameInfo: "Name to apply on intelligence match",
          matchKeyword: "Match Keyword",
          keywordPlaceholder: "Keywords to search on call transcript",
          keywordInfo: "Separated by comma",
          spotKeywords: "Spot keywords on",
          spotKeywordsSmart:
            "Spot keywords only if one of the following SmartTag match",
          score: "Score",
          color: "Color",
          talkTime: "Talk % Time",
          whenThe: "When the",
          caller: "Caller",
          target: "Target",
          talked: "Talked",
          gte: "at Least",
          lte: "at most",
          max: "the most",
          ofTime: "of time",
          Aleg: "caller",
          Bleg: "target",
          matchAllKeywords: "Match ALL Keywords",
          matchAnyKeywords: "Match ANY Keywords",
        },
      },
      reconnect: {
        cardDesc:
          "Reconnect with your customers and save opportunities. Track and record your outbound calls from missed lead alerts.",
      },
      tracking: {
        cardDesc:
          "Measure which channels, campaigns, ads, and keywords are generating form submissions.",
      },
      attribution: {
        title: "Sales Attribution",
        cardDesc:
          "Simply upload your CSV sales report. Our Sales Attribution algorithm matches the sales data to the call, showing which marketing activity has generated revenue.",
        includeSubaccounts: "Include sub accounts when reporting",
        uploadCsv: "Upload Your Sales Data (csv, xlsx)",
        uploadDesc:
          "Make sure to include: • Phone number • Date • Sales or Revenue number (if you would like to attribute callers to a sales figure). The uploaded data will be securely stored on our database for a temporary period whilst the report is being generated.<br/><br/>Once the report is generated, all the data will be removed from our platform for privacy and security.",
        tableDisplay:
          "* showing only the first {num} lines (lines to be processed: <strong>{total}</strong>)",
        linkPhone: "Link phone number",
        linkDate: "Link date",
        linkAmount: "Link amount",
        linkPhone2: "Link second phone number",
        mouseOver: "Mouse over the table and click on the column to select",
        skipLine: "Skip first line (used as header)",
        specify: "Specify at least phone & date columns",
        errors:
          "You could fix the errors and re-upload the file... or proceed anyways with {valid} records and skip the {invalid} with errors",
        dataErrors: "The data provided contains errors",
        links: {
          phone: "Phone",
          date: "Date",
          amount: "Amount",
          phone2: "Phone2",
        },
      },
    },
    integrations: {
      deleteConfigurationConfirm: "Configuration will be deleted, proceed?",
      disconnectServiceConfirm: "Disconnect service?",
      ga: {
        title: "Google Analytics",
        cardDesc:
          "Send call data into your Google Analytics account as an Event and convert to a goal for attribution reporting.",
      },
      ga4: {
        title: "Google Analytics 4",
        cardDesc:
          "Send call data into your GA4 account to improve your campaign performance and attribution reporting.",
      },
      microsoft: {
        title: "Microsoft",
        cardDesc:
          "Convert phone calls into Offline Goals on Microsoft Advertising platform. Keep your bids in track & maximise you ROI.",
      },
      slack: {
        title: "Slack",
        cardDesc:
          "Send call notifications into your slack channel, you and your sales team are not going to miss any other lead.",
      },
      zapier: {
        title: "Zapier",
        cardDesc: "Use Zapier to connect WildJar to 1,000s of applications.",
      },
      hubspot: {
        title: "Hubspot",
        cardDesc:
          "Automatically create contacts and listen to call recordings from the HubSpot activity log.",
      },
      autogate: {
        title: "Autogate",
        cardDesc:
          "Manage and nurture your leads more effectively by integrating phone calls into you Autogate PRO account.",
      },
      exporter: {
        title: "Exporter",
        cardDesc:
          "External Reporting Connector. Create web access points to access your data and integrate into 3rd party reporting platforms.",
        tableTitle: "External Reporting Optimization",
      },
      autoplay: {
        title: "Autoplay",
        cardDesc: "Manage all your leads on AutoPlay platform",
        desc: "Manage all your leads on AutoPlay platform<br/><br/>Activate Smart Tags and power up your reporting by sending call data that matches these tags.",
        configFor: "Autoplay Configuration For",
        formInfo: "Specify the autoplay parameters for integration",
      },
    },
    clients: {
      organization: "Organisation",
      addProspect: "Create Prospect",
      email: "Email",
      name: "Name",
      status: "Status",
      plan: "Plan",
      country: "Country",
      currency: "Currency",
      updated: "Updated",
      deleteProspect: "Delete Prospect",
      copyActiovationLink: "Copy Activation Link",
      manageRates: "Manage Rates",
      deleteProspectConfirm: "Prospect will deleted permanently, confirm?",
      deleteClientConfirm: "Client will deleted permanently, confirm?",
      deleteRateConfirm: "Rate will deleted permanently, confirm?",
      newProspect: "New Prospect",
      editClient: "Edit Client",
      deleteClient: "Delete Client",
      viewInvoices: "View Invoices",
      showExtraDetails: "Show Extra Details",
      billerCode: "Biller Code",
      prospect: "Prospect",
      prospects: "Prospects",
      client: "Client",
      clients: "Clients",
      deleteRate: "Delete Rate",
      addRate: "Add Rate",
      editRates: "Edit Rates",
      configureBillerCode: "Configure as BillerCode",
      prospectActivated: "Prospect activated",
      activationError: "Prospect activation error",
    },
  },

  settingsOptions: {
    addEmailPhone: "Add a new email or phone number",
    addContacts: "Add contacts",
    typeEmailOrPhone: "Type email or phone",
    voicemail: {
      triggers: {
        missed: "Only missed calls",
        always: "All Calls",
        no: "Don't send",
      },
    },
  },

  cta: {
    portExistingNumber: "Port your existing Tracking Numbers",
    configureNewNumber: "Configure a new Tracking Number",
    getNewNumber: "Get new number",
    accountConfiguration: "Account configuration",
    changeAccount: "Change Account",
    availablePlaceholders: "Placeholders",
    clickToCopy: "Click to Copy",
    signup: "Sign Up",
  },

  "2fa": {
    title: "2 Factor Authentication",
    desc: "Authenticator app and SMS configuration",
    persistTokenEnable: "Don't ask for the next 30 days",
    sendViaSms: "Send code via SMS",
    typeOtpApp: "Type the 6 digits code from authenticator app or received SMS",
    remindNext: "Remind me next time",
    codesWhere: "Where do you want to receive your codes?",
    codesWhereDesc:
      "You will receive an SMS with a one-time code to make sure it's working and your mobile number is correct",
    mobileNumber: "Mobile number",
    inputHint: "After you'll be able to configure an authenticator app",
    sendMeTheCode: "Send me the code via SMS",
    smsError: "SMS not sent, are you sure you entered the correct number?",
    codeSent: "We sent an SMS with the validation code to your mobile number.",
    codeExpires: "The code is expiring in",
    codeNotValid: "Sorry, the code is not valid anymore",
    scanQr:
      "Scan this QR code with a security or authentication app Google Authenticator, Authy or Duo. Download one from your app store",
    skipQr: "If you want to skip the 2FA application and use only SMS",
    authAppCheck:
      "Insert the code from your Athenticator App to complete the setup",
    invalidCode: "Invalid code",
    generateCode: "Generate Authenticator configuration",
    authCode: "Authenticator Code",
    receiveCodeInfo:
      "You are going to receive an SMS with the authentication code, make sure your mobile number is correct",
    insertOtp: "Insert OTP",
    typeSmsCode: "Type the 6 digits code we sent to your mobile phone",
    isConfigured: "2 Factor Authentication is correctly configured",
  },

  filters: {
    addFilter: "add filter",
    filters: "filters",
    removeFilter: "remove filter",
    viewType: "View type",
    hours: "hours",
    weekdays: "weekdays",
    days: "days",
    months: "months",
    trackingNumber: "tracking number",
    status: "call status",
    trackingSource: "tracking source",
    callDuration: "call duration",
    selectStatus: "select status",
    selectNumber: "select number",
    selectTrackingSource: "select tracking source",
    durationMin: "minimum duration",
    durationMax: "maximum duration",
    moreThanSeconds: "more than seconds",
    lessThanSeconds: "less than seconds",
    selectAccount: "Select account",
    selectAccounts: "Select accounts",
    whichAccount: "Which account",
  },

  statuses: {
    answered: "answered",
    missed: "missed",
    abandoned: "abandoned",
  },

  dates: {
    yesterday: "Yesterday",
    today: "Today",
    tomorrow: "Tomorrow",
    last7Days: "Last 7 days",
    last30Days: "Last 30 days",
    thisMonth: "This month",
    lastMonth: "Last month",
    selectDateRange: "Select daterange",
    monday: "monday",
    tuesday: "tuesday",
    wednesday: "wednesday",
    thursday: "thursday",
    friday: "friday",
    saturday: "saturday",
    sunday: "sunday",
    afternoon: "afternoon",
    morning: "morning",
    hours: "hours",
    minutes: "minutes",
    seconds: "seconds",
    days: "days",
    months: "months",
    years: "years",
  },

  loaders: {
    authenticating: "Authenticating...",
    configuring: "Configuring...",
    loading: "Loading...",
    loadingComponent: "Loading component...",
    loggingOut: "Logging out...",
    loadingData: "Loading data...",
    parsingData: "Parsing data...",
    updatingData: "Updating data...",
    savingData: "Saving data...",
    savingSettings: "Saving settings...",
    savingConfiguration: "Saving configuration...",
    deletingConfiguration: "Deleting configuration...",
    processingRequest: "Processing request...",
    configAndRender: "Loading configuration and rendering...",
    switchingIdentity: "Switching identity...",
    creatingNumber: "Creating number...",
    connecting: "Connectiong...",
    disconnecting: "Disconnecting...",
    processingQuote: "Processing quote...",
  },

  validations: {
    requiredField: "Required field",
    phoneNotValid: "Phone number not valid",
    insertValidEmail: "Insert a valid email",
    insertValidPhone: "Insert a valid phone number",
    insertValidUrl: "Insert a valid URL",
    insertValidDomain: "Insert a valid Domain",
    insertValidImgPath: "Insert valid image path or Url",
    noPrefix: "Remove prefix",
    basicPasswordFormat: "Please enter at least 6 characters",
    passwordMismatch: "Password and verify password do not match",
    passwordFormat:
      "The password must contain at least 8 characters, a lowercase letter, an uppercase letter, a number, and at least one special character",
    onlyNumbersAllowed: "Only numbers allowed",
    onlyPdfAllowed: "Only .pdf files allowed",
    insertValidPiva: "Insert a valid VAT number",
    numberFromAccount: "Number is from same account",
    minLength: "Requires at least {num} characters",
    insertValidPhoneOrEmail: "Insert a valid phone number or email",
    otp: "Invalid OTP",
    dates: {
      invalid: "Invalid date",
      maxToday: "Date must be before or equal to today",
    },
  },

  inputs: {
    dropFileHere: "Drop here",
    dropOrSelect: "Drag 'n' drop or click to select",
    uploadMp3: "Upload mp3",
    typeAndEnter: "Type and press enter to add entry",
  },

  fields: {
    email: "email",
    password: "password",
    confirmPassword: "confirm password",
    firstName: "first name",
    lastName: "last name",
    agree: "I agree to the ",
    phone: "Mobile phone number",
    loginRemember: "Don't ask for the next 30 days",
    businessName: "Business name",
    businessTax: "Business Tax ID",
    businessEmail: "Business Email",
    businessCategory: "Business Category",
    businessAddress: "Business Address",
  },

  tables: {
    showingItems: "Showing {from} to {to} of {total} entries",
    show: "show",
    entries: "entries",
    configureColumns: "configure columns",
  },

  warnings: {
    hangup: "Is missing the Call Flow",
    gaDisabled: "GA disabled",
    gaCustom: "GA custom",
    gaDefault: "GA default",
  },

  flowData: {
    deleteSelectedElements: "Delete selected elements",
    confirmFlowReset:
      "All unsaved progress will be deleted and flow will be reset to last saved status, proceed?",
    confirmSaveFlow: "Save current flow?",
    nodesOrConnectionsErrors:
      "There are nodes and/or connections with configuration errors, please review the flow and try again",
    noActionAttachedToStart: "No actions are linked to start point",
  },

  reportData: {
    account: "Account",
    name: "Name",
    calls: "Calls",
    totalCalls: "Total calls",
    first: "First",
    returning: "Returning",
    newCaller: "New caller",
    newCallerAttributionInfo: "Call Source Attribution per New Caller",
    newCallers: "New callers",
    answered: "Answered",
    notAnswered: "Not answered",
    missed: "Missed",
    abandoned: "Abandoned",
    missedAbandoned: "Missed&Abandoned",
    avgDuration: "Avg duration",
    revenue: "Revenue",
    webTraffic: "Web Traffic",
    timeToAnswer: "Time to Answer",
    avgTimeToAnswer: "Average Time to Answer",
    timeToAnswerInfo: "Average time it takes to answer",
    totalCallsInfo: "Inbound phone calls",
    newCallersInfo: "First time phone interaction",
    missedInfo: "Missed and abandoned call percentage",
    avgDurationInfo: "Average phone call duration",
    webTrafficInfo: "Calls coming from Website",
    callSummary: "Call summary",
    callSummaryInfo: "{num} Missed & abandoned calls",
    answeredCalls: "Answered Calls",
    missedCalls: "Missed Calls",
    firstTimeCallers: "First time callers",
    returningCallers: "Returning callers",
    detailedTimeReport: "Detailed time report",
    callStatus: "Call status",
    medium: "Medium",
    mediumInfo: "Phone calls from web medium",
    source: "Source",
    sourceInfo: "Phone calls from web source",
    sourceAttributionInfo: "Attribution by Call Source",
    device: "Device",
    deviceInfo: "Phone calls by web device",
    other: "Other",
    pageCounter: "Page Counter",
    pageCounterInfo: "Page views before phone call",
    mediumSource: "Source / Medium",
    keywords: "Keywords",
    landing: "Landing",
    conversion: "Conversion",
    callAttributionReport: "Call Attribution Report",
    lessThan60Seconds: "less than 60 seconds",
    basedOnAnsweredCalls: "Based on answered calls",
    secondsBetweenStartAndAnswer: "Seconds between start & answer",
    callDuration: "Call duration",
    callByDays: "Call by days",
    callByMonths: "Call by months",
    notAnsweredCalls: "{num}% Not answered calls",
    callByHour: "Call by hour",
    callByWeekday: "Call by day of the week",
    insights: "Insights",
    insightsInfo:
      "Insights report is based on category, if the category for this account doesn't seem right ",
    pleaseClickHere: "please click here",
    selectAccountForInsights: "Select account for insights",
    phoneCalls: "Phone Calls",
    phoneCallsInfo: "Total calls received",
    notAnsweredCalls_: "Not Answered Calls",
    busyTime: "Busiest Time",
    callsPerCategory: "Calls per Category",
    callsPerAccount: "Calls per Account",
    tags: "Tags",
    tagsIvrInfo: "Attribution by Naming",
    newCallerIvrInfo: "IVR Name Attribution per New Caller",
    ivrReport: "IVR Report",
    names: "Names",
    digitPressed: "Digit Pressed",
    durations: {
      less30: "Less than 30 seconds",
      3060: "31- 60 seconds",
      min12: "1 - 2 minutes",
      min23: "2 - 3 minutes",
      min35: "3 - 5 minutes",
      min510: "5 - 10 minutes",
      over10: "More than 10 minutes",
    },
    comparisons: {
      account: {
        title: "Your Account",
        desc: "comparison to previous month",
        phoneCalls: "Total calls received",
        notAnsweredCalls: "Percentage of missed calls",
        timeToAnswer: "Average time it takes to answer",
        callDuration: "Average phone call duration",
      },
      category: {
        title: "Category",
        desc: "comparison to previous month",
        phoneCalls: "Increase/Decrease in phone calls",
        notAnsweredCalls: "Percentage of missed calls",
        timeToAnswer: "Average time it takes to answer",
        callDuration: "Average phone call duration",
      },
      comparison: {
        title: "Comparison Trend",
        desc: "your account vs category",
        phoneCalls: "Increase/Decrease vs category",
        notAnsweredCalls: "Increase/Decrease vs category",
        timeToAnswer: "Compared to {month} category",
        callDuration: "Compared to {month} category",
      },
    },
  },

  callData: {
    id: "ID",
    agent: "Agent",
    status: "Status",
    timestamp: "Date",
    trackingSource: "Tracking",
    caller: "Caller",
    duration: "Duration",
    web: "Attribution, Tags & Info",
    recordedInboundCall: "Recorded inbound call",
    destinationNum: "Destination number",
    otherFields: "Other fields",
    WebAttribution: "Web Attribution",
    answered: "Answered",
    moreInfo: "More Info",
    callIdentifier: "Call Identifier",
    blockCaller: "Block caller",
    notes: "Notes",
    call_ID: "call ID",
    date_time: "date/time",
    area: "area",
    first_time_caller: "first time caller",
    tracking_number: "tracking number",
    tracking_source: "tracking source",
    talk_time: "talk time",
    target: "target",
    call_recording: "call recording",
    web_source: "web source",
    web_medium: "web medium",
    tags: "tags",
    IVR_option: "IVR option",
    score: "score",
    account: "account",
    account_name: "account name",
    addTag: "Add Tag",
    customerJourney: "Customer Journey",
    webCallBack: "Web Call Back",
    talkTime: "Talk Time",
    callDuration: "Call Duration",
    noWebAttribution: "No Web Attribution",
    sourceMedium: "Source / Medium",
    campaign: "Campaign",
    keywords: "Keywords",
    device: "Device",
    landingPage: "Landing Page",
    conversionPage: "Conversion Page",
    destinationNumber: "Destination Number",
    withAudio: "With Audio Recording",
    withTranscript: "With transcript",
    hasNotes: "Has notes",
    confirmBlock: "Confirm block",
    confirmBlockInfo: "This caller will be blocked, proceed?",
    callerBlocked: "caller blocked",
    callerBlockedSuccess: "Caller successfully blocked",
    callSummary: "Call Summary",
    loadCallSummary: "load call summary",
    hideActivityLog: "Hide Activity Log",
    showActivityLog: "Show Activity Log",
    activityLog: "Activity Log",
    params: {
      uuid: "call ID",
      dateStartUser: "date/time",
      caller: "caller",
      area: "area",
      firstTimeCaller: "first time caller",
      trackingNumber: "tracking number",
      trackingSource: "tracking source",
      status: "status",
      duration: "duration",
      talkTime: "talk time",
      target: "target",
      audio: "call recording",
      web_source: "web source",
      web_medium: "web medium",
      tags: "tags",
      IVR: "IVR option",
      score: "score",
      notes: "notes",
      account: "account",
      accountName: "account name",
    },
    smsTypes: {
      inbound: "Inbound SMS",
      outbound: "Outbound SMS",
    },
    statuses: {
      generic: {
        all: "All Calls",
        missed: "Missed Calls",
        answered: "Answered Calls",
      },
      inbound: {
        answered: "Answered Inbound Call",
        "answered-audio": "Recorded Inbound Call",
        abandoned: "Abandoned Inbound Call",
        "abandoned-audio": "Abandoned Inbound Call",
        missed: "Missed Inbound Call",
        "missed-audio": "Inbound Voicemail",
        inbound: "Inbound Call",
      },
      outbound: {
        answered: "Answered Outbound Call",
        "answered-audio": "Recorded Outbound Call",
        abandoned: "Abandoned Outbound Call",
        "abandoned-audio": "Abandoned Outbound Call",
        missed: "Outbound Missed Call",
        "missed-audio": "Outbound Missed Call",
        voicemail: "Outbound Call to Voicemail",
        outbound: "Outbound Call",
      },
    },
    ratings: {
      nonClassified: "Non classfied",
      poor: "Poor",
      average: "Average",
      good: "Good",
    },
    conditions: {
      adSource: "Tracking Source",
      status: "Call Status",
      duration: "Duration",
      talk: "Talk Time",
      caller: "Caller Type",
      lead: "Lead Scorer",
      labels: {
        adSource: "Tracking <strong>Source</strong> is",
        status: "Call <strong>status</strong> is",
        duration: "Call <strong>Duration</strong> is",
        talk: "<strong>Talk Time</strong> is",
        caller: "<strong>Caller type</strong> is",
        lead: "<strong>LeadScorer</strong> keypad press",
        newCaller: "New Caller",
        returningCaller: "Returning Caller",
        Aleg: "A leg (caller)",
        Bleg: "B leg (target)",
      },
    },
  },

  accountData: {
    account: "Account",
    name: "Name",
    country: "Country",
    category: "Category",
    highway: "Highway",
    structure: "Structure",
    mainBilling: "main billing client",
    subBilling: "sub billing client",
    statuses: {
      active: "Active",
      suspended: "Suspended",
      invited: "Invited",
    },
  },

  userData: {
    email: "Email",
    emailAddress: "Email Address",
    mobilePhone: "Mobile Phone",
    firstName: "First Name",
    lastName: "Last Name",
    timezone: "Time Zone",
    account: "Account",
    name: "Name",
    system: "System",
    reports: "Reports",
    conf: "Conf",
    users: "Users",
    billing: "Billing",
    admin: "Admin",
    super: "Reseller",
    allow: "Allow",
  },

  numberData: {
    sourceName: "Source Name",
    sourceNameHint:
      "e.g. Print Ads, TV, Radio, Social, Email Signatures, Directories",
    country: "Country",
    network: "Network",
    moreAvailableNumbers: "More Available Numbers",
    email: "Email",
    emailHint: "SMS replies will be sent to this address",
    name: "name",
    trackingNumber: "tracking number",
    trackingSource: "Tracking Source",
    trackingName: "Tracking Name",
    account: "account",
    source: "source",
    action: "action",
    extra: "extra",
    area: "area",
    networks: {
      any: "Any Network",
      geo: "Landline",
      mobile: "Mobile",
      smart: "National",
      tollfree: "Toll Free",
    },
    voicemail: {
      no: "Don't Send",
      missed: "Only Missed Calls",
      always: "All Calls",
    },
  },

  branchData: {
    name: "Name",
    address: "Address",
    editDetails: "Edit Details",
    editCallFlow: "Edit Call Flow",
    branchDetails: "Branch Details",
    code: "Code",
    accountLink: "Account Link",
    accountLinkInfo:
      "<strong>Advanced</strong> link account for enterprise advanced configuration (optional)",
  },

  prospectData: {
    plan: "Plan",
    package: "Package",
    payment: "Stripe",
    email: "Email",
    clientId: "Client ID",
    clintIdInfo: "Reference to 3rd party client identifier",
    country: "Country",
    underClient: "Under Client",
    underClientInfo: "The master client won't be billed",
    currency: "Currency",
    plans: {
      starter: "starter",
      agency: "agency",
      custom: "custom",
    },
    payments: {
      stripe: "Mandatory",
      skip: "Not Required",
    },
  },

  clientData: {
    organization: "Organisation",
    businessName: "Business Name",
    email: "Email Address",
    address: "Address",
    taxNumber: "Business / Tax Number",
    billerCode: "Biller Code",
    account: "Account",
    clientId: "Client ID",
    country: "Country",
    currency: "Currency",
    signupEmail: "Signup Email",
  },

  invoiceData: {
    name: "Name",
    email: "Contact Email",
    clientId: "Client ID",
    total: "Total",
    status: "Status",
    statuses: {
      PAID: "Paid",
      AUTHORISED: "Waiting Payment",
    },
  },

  portingData: {
    request: "request",
    name: "name",
    carrier: "carrier",
    status: "status",
    account: "account",
    numbers: "numbers",
    labels: {
      carrier: "Losing Carrier Name",
      carrierPlaceholder: "Enter the carrier name",
      customer: "Customer Account Number",
      customerPlaceholder: "Referring to the current carrier",
      invoice: "Last Invoice",
      account: "Account",
      company: "Business Name",
      companyPlaceholder: "Company owning the phone number",
      address: "Business Address",
      addressPlaceholder: "Company full address",
      tax: "Business ABN",
      taxPlaceholder: "Company Tax Number",
      phone: "Phone contact",
      phonePlaceholder: "Contact Phone Number",
      name: "Full Name",
      namePlaceholder: "Your Full Name",
      title: "Role Title",
      titlePlaceholder: "Your Title in the Company",
      numbers: "Tracking Numbers",
      onePerLine: "One number per line",
      invoiceRequired: "Last invoice is required",
    },
  },

  ratesData: {
    item: "Item",
    country: "Country",
    charges: "Charges",
    type: "Type / Network",
    month: "Month",
    items: {
      "NUMBER-RENTAL": "Number Rental",
      "VOICE-USAGE": "Inbound Minutes",
      "VOICE-OUTBOUND": "Outbound Minutes",
      TRANSCRIPT: "Call Intelligence",
      "MONTHLY-FEE": "Monthly Technology Fee",
      ACCOUNT: "Flat Cost Per Account",
      CHATBOT: "Web Chat Bot",
      "SMS-OUTBOUND": "Outbound SMS",
      "SMS-BULK": "SMS Recharge/Campaigns",
      "SUPPORT-FEE": "Support Assistance Fee",
      "FREE-MONTH": "Waive Invoice",
    },
    groups: {
      numbers: "Numbers",
      usage: "Usage",
      fixedRates: "Fixed Rates",
      sms: "SMS",
      custom: "Custom Items",
    },
  },

  listData: {
    contacts: "Contacts",
    name: "Name",
    account: "Account",
  },

  chatData: {
    sender: "Sender",
    target: "Target",
    message: "Message",
  },

  intelligence: {
    sentiment: "Sentiment",
    openTranscriptDetails: "Open Transcript Details",
    callerSpeakTime: "Caller Speaking Time",
    callTranscript: "Call Transcript",
    target: "Target",
    caller: "Caller",
    silence: "Silence",
    sentiments: {
      MostlyPositive: "Mostly Positive",
      Positive: "Positive",
      MostlyNegative: "Mostly Negative",
      Negative: "Negative",
      Neutral: "Neutral",
    },
  },

  exportData: {
    call: {
      uuid: "call ID",
      dateStartUser: "date/time",
      caller: "caller",
      area: "area",
      firstTimeCaller: "first time caller",
      trackingNumber: "tracking number",
      trackingSource: "tracking source",
      status: "status",
      duration: "duration",
      talkTime: "talk time",
      target: "target",
      audio: "call recording",
      web_source: "web source",
      web_medium: "web medium",
      tags: "tags",
      IVR: "IVR option",
      score: "score",
      notes: "notes",
      account: "account",
      accountName: "account name",
    },
    invoice: {
      accountName: "Account",
      total: "Total",
      rental: "Tracking Number Rental",
      dids: "Tracking Number DIDs",
      inboundAmount: "Inbound Calls Amount",
      inboundMinutes: "Inbound Calls Minutes",
      inboundCalls: "Inbound Calls",
      outboundAmount: "Outbound Calls Amount",
      outboundMinutes: "Outbound Calls Minutes",
      outboundCalls: "Outbound Calls",
      transcript: "Transcript",
      sms: "SMS",
      bulk: "Bulk SMS",
    },
    messageReport: {
      number: "Number",
      status: "Status",
    },
    top10: {
      name: "Name",
      total: "Total Calls",
      newCallers: "New Callers",
      answered: "Answered",
      missed: "Missed",
      abandoned: "Abandoned",
      avgDuration: "Avg Duration",
      revenue: "Revenue",
    },
    timeAndDay: {
      label: "Segment",
      answer: "Answered",
      missed: "Missed",
      first: "New Callers",
      returning: "Returning Callers",
    },
    callAttributionReport: {
      source: "Source",
      total: "Total Calls",
      newCallers: "New Callers",
      answer: "Answered",
      missed: "Missed",
      abandoned: "Abandoned",
      avgDuration: "Avg Duration",
      first: "First",
      returning: "Returning",
    },
    callDuration: {
      label: "",
      num: "Value",
      percent: "Percent",
      totalCalls: "Total Calls",
      answeredCalls: "Answered Calls",
      callsUnder60: "Calls less than 60 seconds",
      totalAnsweredCallsTime: "Total answered calls time",
      tta: "Average time to answer",
      g0: "Less than 30 seconds",
      g1: "31- 60 seconds",
      g2: "1 - 2 minutes",
      g3: "2 - 3 minutes",
      g4: "3 - 5 minutes",
      g5: "5 - 10 minutes",
      g6: "More than 10 minutes",
    },
    missedCalls: {
      total: "Total",
      label: "",
      answered: "Answered",
      missed: "Missed",
      abandoned: "Abandoned",
      byDay: "Call by days",
      byHour: "Call by hour",
      byWeekday: "Call by week day",
    },
    ivr: {
      name: "Name",
      digits: "Digit Pressed",
      tot: "Total Calls",
      answer: "Answered",
      missed: "Missed",
      abandoned: "Abandoned",
      avgDuration: "Avg Duration",
    },
    outboundTop10: {
      name: "Name",
      total: "Total Calls",
      answered: "Connected Calls",
      avgTalk: "Avg Talk time",
      avgDuration: "Avg Duration",
    },
    smsBulkReport: {
      sender: "Campaign",
      name: "Name",
      scheduled: "Date",
      sent: "Sent",
      delivered: "Delivered",
      bounced: "Bounced",
      blocked: "Opt-Outs",
      replied: "Replies",
    },
  },

  integrations: {
    exporter: {
      name: "Name",
      account: "Account",
      subaccounts: "Sub Accounts",
      auth: "Auth",
      format: "Format",
      tags: "Tags",
      uuid: "uuid",
      optimization: "Optimization",
      newOptimization: "New Optimization",
      createNewOptimization: "Create New Optimization",
      newExternalLink: "Create a New External Link",
      copyUrl: "Copy Integration URL",
      generateNewKey: "Generate new API key",
      saveAPiKey: "Save your API secret now, we won't show it again.",
      newApiKey: "New API KEY",
      fields: {
        name: "Name or the external platform",
        account: "Account",
        authentication: "Authentication",
        dataFormat: "Data Format",
        tags: "Tags available in EXPORT group",
        tagsInfo: "If no tags are selected, all calls are going to be exported",
        apiKeyModal: "API KEY",
        apiKeyInfo: "Save you API KEY now, we won't show it again.",
        authentications: {
          basic: "Oauth2",
          key: "X-API-KEY",
          none: "NONE (not reccomended)",
        },
      },
      info: {
        title: "External Reporting Connector",
        description:
          "The external reporting connector allows you to create web access points to easily access your data and seamlessly integrate into 3rd party reporting platforms.<br/><br/>First, create the URL you wish for the accounts (or group of accounts). Note: it will take 24 hours for the selected account data to populate to the new URL address point. After this 24 hour period, it is available all the time.",
        desc2Left:
          'You can generate either a CSV or JSON data format.<br/><br/>Parameters supported:<div class="pl-3">dateFrom=<br/>dateTo=<br/>limit=<br/>fields=</div>',
        desc2Right:
          "<strong>DATE:</strong> You can use “1 week ago” or a more traditional ISO 8601 date range for the date.<br/><br/>The data will always return in the same order displaying the most recent phone call first.<br/><br/><strong>LIMIT:</strong> The limit parameter can be used to return results between 1 and 10,000 <br/><br/><strong>FIELDS:</strong> The fields parameter allows you select ‘ALL’ the data to keep the data consistent month to month, or you can select only the data you would like to see. NOTE: only applicable to csv",
        examplesTitle: "Some examples below:",
        examples: [
          {
            title: "Example call data for yesterday:",
            url: "https://api.trkcall.com/v2/public/exporter/v1.5/xxx?dateFrom=yesterday&dateTo=today",
          },
          {
            title: "Example call data for month with 10,000 limit:",
            url: "https://api.trkcall.com/v2/public/exporter/v1.5/xxx?dateFrom=2022-10-01&dateTo=2022-11-01&limit=10000",
          },
          {
            title:
              "Example call data for month, limit of 10000 records and all fields:",
            url: "https://api.trkcall.com/v2/public/exporter/v1.5/xxx?dateFrom=2022-10-01&dateTo=2022-11-01&limit=10000&fields=ALL",
          },
        ],
      },
    },
    autoplay: {
      DealershipId: "Dealership ID",
      DealershipIdPlaceholder: "e.g.: 2323",
      yardRef: "Yard Reference",
      yardRefPlaceholder: "e.g.: 9999",
      CustomerCompany: "Customer Company",
      CustomerCompanyPlaceholder: "e.g.: Call Tracking",
      LeadCampaignName: "Lead Campaign Name",
      LeadCampaignNamePlaceholder: "e.g.: Call",
      LeadSource: "Lead Source",
      LeadSourcePlaceholder: "e.g.: Web Phone Call",
      LeadTitle: "Lead Title",
      LeadTitlePlaceholder: "e.g.: [[callId]]",
      LeadNotes: "Lead Notes",
      LeadNotesPlaceholder: "e.g.: [[recordingUrl]]",
    },
    ga: {
      titleFor: "Google Analytics 4 integrations for",
      measurementId: "Measurement ID",
      measurementIdHint: "Your GA4 Measurement ID in G-XXXXXXXX format",
      apiSecret: "API Secret",
      apiSecretHint: "Your measurement protocol API secret",
      trackingNumbers: "Tracking Numbers",
      numberOptions: {
        yes: "All Tracking Numbers",
        no: "Select Tracking Numbers",
      },
    },
    slack: {
      titleFor: "Slack integration for",
      url: "URL",
      urlHint: "Your slack webhook URL, you can choose one",
      urlHintCta: "connecting to slack",
      notifyAt: "Notify at",
      trackingNumbers: "Tracking Numbers",
      notificationOptions: {
        no: "Call Completion",
        yes: "Call Start & Call Completion",
      },
    },
    hubspot: {
      titleFor: "Hubspot Integration for",
      authenticationRequired: "Authentication required",
      hsAccount: "HubSpot Account",
      otherConfig:
        "<strong>Only the person that authorised this integration can modify it.</strong><br/>You can delete it and re-configure it with your authentication",
      accessAuthorizedBy: "Access authorised by {who}",
      skipAnon: "Skip Anonymous Creation",
      skipAnonDesc:
        "Only log a call for private or blocked callers. A contact will not be created.",
      webDialer: "Integrated Web Dialer",
      webDialerDesc:
        "Track and record your outbound calls directly from Hubspot.",
      shareContacts: "Share contacts/address book",
      shareContactsDesc:
        "Find out which contact called you, not just their number.",
      phoneToLeads: "Turn your phone calls into HubSpot leads",
      changeCallerId: "Allow Seats to change Caller ID",
      changeCallerIdDesc: "Or select one display number for all outbound calls",
      setTags: "Only send calls that match the following Smart Tags",
      noSmartTags:
        'You don\'t have any <span class="text-error">smart tag</span> configured, other than pretty colors they help you filtering the data and choose exactly what to push and where (hubspot in this case)',
      numberOptions: {
        yes: "All Tracking Numbers",
        no: "Select Tracking Numbers",
      },
    },
    microsoft: {
      title: "Microsoft Integrations",
      authenticationRequired: "Authentication required",
      msAccountName: "Microsoft account",
      msAccountNameShort: "MS account",
      msAccountHint: "Listing only the accounts not configured",
      msConversionNameShort: "MS conversion",
      msConversionName: "Microsoft conversion",
      msConversionHint: "Choose the Offline Goal Conversion",
      manualConfig: "Manual Configuration",
      configuration: "Configuration",
      disconnect: "Disconnect from Microsoft",
      disconnectConfirm: "Disconnect from Microsoft Account",
      removeProvider: "Remove Provider Integration",
      perfectMatch: "Perfect match!",
      possibleMatch: "Possible match...",
      notMatch: "Not such a good match, but... {distance}",
    },
  },

  routing: {
    confirmNavigation: "Confirm navigation",
    unsavedChanges: "There are unsaved changes that will be lost, proceed?",
    confirmAccountChange: "Confirm Account Change",
  },

  categories: {
    "ART-0": "Arts and Entertainment",
    "AUT-0": "Automotive - Dealership",
    "AUT-1": "Automotive - Mechanic and Parts",
    "AUT-2": "Automotive - Tyre Fitter",
    "BEA-0": "Beauty and Fitness",
    "BUS-0": "Business and Industrial Markets",
    "COM-0": "Computers and Electronics",
    "FIN-0": "Finance and Insurance",
    "FOD-0": "Food and Drink",
    "GAM-0": "Games",
    "HEA-1": "Healthcare - Aged Care",
    "HEA-2": "Healthcare - Dental",
    "HEA-0": "Healthcare - Other",
    "HOB-0": "Hobbies and Leisure",
    "HOM-1": "Home Services - Electrical",
    "HOM-2": "Home Services - HVAC",
    "HOM-3": "Home Services - Locksmith",
    "HOM-4": "Home Services - Painting and Decorating",
    "HOM-5": "Home Services - Plumbing",
    "HOM-6": "Home Services - Pool and Garden",
    "HOM-7": "Home Services - Removalist",
    "HOM-0": "Home Services - Other",
    "INT-0": "Internet and Telecom",
    "JOB-0": "Jobs and Education",
    "GOV-0": "Law and Government",
    "MAR-0": "Marketing and Advertising",
    "PET-0": "Pets and Animals",
    "EST-0": "Real Estate",
    "SHO-0": "Shopping",
    "SPO-0": "Sports",
    "TRA-0": "Travel",
    OTHER: "Other",
  },

  theme: {
    toggleDarkMode: "Toggle Dark Mode",
  },

  menuItems: {
    "not-found": "404 not found",
    dashboard: "Dashboard",
    "settings-numbers": "Tracking Numbers",
    "settings-structure": "Account structure",
    "settings-single-account": "Account settings",
    "dynamic-insertion": "Dynamic insertion",
    "time-and-day": "Time and Day",
    "web-attribution": "Web Attribution",
    "call-attribution": "Call Attribution",
    "call-duration": "Call Duration",
    "missed-calls": "Missed Calls",
    numbers: "Numbers",
    insights: "Insights",
    calls: "Calls",
    outbound: "Outbound",
    overview: "Overview",
    "outbound-calls": "Calls",
    "messages-campaign-lists": "Campaign Lists",
    inbox: "Inbox",
    reports: "Reports",
    ivr: "IVR",
    sms: "SMS",
    "bulk-campagins": "Bulk Campaigns",
    "bulk-reports": "Bulk Reports",
    "calls-campaign-lists": "Campaign Lists",
    "opt-outs": "Opt Outs",
    "sms-top-up": "SMS Top-up",
    integrations: "Integrations",
    powerup: "Power ups",
    dni: "Dynamic insertion",
    "auth-login": "Login",
    "auth-invite": "Invite",
    "auth-forgot-password": "Forgot password",
    "auth-reset-password": "Reset password",
  },

  pageNames: {
    "not-found": "404 not found",
    dashboard: "Dashboard",
    "billing-payments": "Payments and Invoices",
    "billing-biller-payments": "Payments and Invoices",
    "billing-summary": "Billing summary",
    "settings-numbers": "Tracking Numbers",
    "settings-blocks": "Blocked Numbers",
    "settings-structure": "Account structure",
    "settings-new-account": "Account management",
    "settings-single-account": "Account settings",
    "settings-customisation": "Customisation",
    "settings-new-tracking-number": "New Tracking Number",
    "settings-single-wizard": "Tracking Number Settings",
    "settings-single-wizard-routing": "Tracking Number Settings",
    "settings-single-wizard-flow": "Tracking Number Settings",
    "settings-single-wizard-dni": "Tracking Number Settings",
    "settings-single-wizard-google": "Tracking Number Settings",
    "settings-single-wizard-details": "Tracking Number Settings",
    "settings-single-wizard-messaging": "Tracking Number Settings",
    "settings-single-wizard-locations": "Locations",
    "settings-porting": "Porting Tracking Numbers",
    "settings-porting-single": "Tracking Number Porting Request",
    "dynamic-insertion": "Dynamic insertion",
    "time-and-day": "Time and Day",
    "web-attribution": "Web Attribution",
    "call-attribution": "Call Attribution",
    "call-duration": "Call Duration",
    "call-ivr": "IVR Report",
    "calls-history": "Call History",
    "missed-calls": "Missed Calls",
    numbers: "Numbers",
    insights: "Insights",
    calls: "Calls",
    outbound: "Outbound",
    overview: "Outbound Overview",
    "outbound-calls": "Outbound Call Log",
    "messages-campaign-lists": "Campaign Lists",
    inbox: "SMS Inbox",
    reports: "Reports",
    sms: "SMS",
    "bulk-campagins": "Bulk Campaigns",
    "bulk-reports": "Bulk Reports",
    "calls-campaign-lists": "Campaign Lists",
    "opt-outs": "Opt Outs",
    "sms-top-up": "SMS Top-up",
    integrations: "Integrations",
    powerup: "Power ups",
    dni: "Dynamic insertion",
    "dni-list": "Dynamic insertion",
    users: "Users",
    "users-list": "Users",
    "users-addressbook": "Contacts",
    "users-reports": "Scheduled Reports",
    "users-me": "Personal Information",
    "users-logins": "User Login Report",
    "auth-login": "Login",
    "auth-invite": "Invite",
    "auth-forgot-password": "Forgot password",
    "auth-reset-password": "Reset password",
    "clients-prospects": "Prospects",
    "client-prospect": "Prospect",
    "new-client-prospect": "New Prospect",
    "client-prospect-rates": "Prospect Rates",
    clients: "Clients",
    "clients-list": "Clients",
    client: "Client",
    "new-client": "New Client",
    "client-rates": "Client Rates",
    "client-invoices": "Client Invoices",
    "powerups-list": "Power ups",
    "powerups-followup": "SMS Follow Up",
    "powerups-tags": "Smart Tags",
    "powerups-intelligence": "Call Intelligence",
    "powerups-reconnect": "Reconnect",
    "powerups-tracking": "Form Tracking",
    "powerups-attribution": "Sales Attribution",
    "integrations-list": "Integrations",
    "integrations-ga": "Google Analytics configuration",
    "integrations-ga4": "Google Analytics 4 configuration",
    "integrations-microsoft": "Microsoft configuration",
    "integrations-slack": "Slack configuration",
    "integrations-zapier": "Zapier configuration",
    "integrations-hubspot": "Hubspot configuration",
    "integrations-autogate": "Autogate configuration",
    "integrations-exporter": "Exporter configuration",
    "integrations-autoplay": "Autoplay configuration",
  },

  confirmations: {
    editComplete: "Update completed successfully",
    updateComplete: "Update completed successfully",
    createComplete: "Creation completed successfully",
    deleteComplete: "Deletion completed successfully",
    orderComplete: "Order completed successfully",
    saveComplete: "Save completed successfully",
    insertComplete: "Insert completed successfully",
    requestComplete: "Request completed successfully",
    rechargeComplete: "Recharge completed successfully",
    configurationComplete: "Configuration completed successfully",
    configurationUpdated: "Configuration updated successfully",
    configurationDeleted: "Configuration deleted successfully",
    processInitiated: "Process initiated",
    itemCreated: "Item created successfully",
    itemDeleted: "Item deleted successfully",
    deleteIntegration: "Delete integration",
    settingsSaved: "Settings saved",
    confirmDelete: "Confirm deletion",
    confirmCreate: "Confirm creation",
    confirmEdit: "Confirm edit",
    confirmDisable: "Confirm disable",
    confirmAction: "Confirm action",
    confirmOrder: "Confirm order",
    confirmClose: "Confirm close",
    confirmSave: "Confirm save",
    proceedDeletion: "Proceed with deletion",
  },

  errors: {
    anErrorOccuredWhileEditing: "An error occurred while editing",
    anErrorOccuredWhileSaving: "An error occurred while saving",
    anErrorOccuredWhileInserting: "An error occurred while inserting",
    anErrorOccuredWhileUpdating: "An error occurred while updating",
    anErrorOccuredWhileLoadingData: "An error occurred while loading data",
    anErrorOccuredWhileDeleting: "An error occurred while deleting",
    anErrorOccuredWithRequest: "An error occurred with request",
    anErrorOccuredWhileCreating: "An error occurred while creating",
    anErrorOccuredDuringProcess: "An error occurred with process",
    sessionExpired: "Session expired",
    sessionExpiredMsg: "Sorry... too long without doing nothing.",
    configurationIssues: "Configuration issues",
    checkAllFields: "Check all fields",
    resourceNotFound: "Resource not found",
    resourceNotFoundInfo: "The reqeusted resource is invalid o not accesible",
    selectConfig: "Select configuration",
    fillAtLeastOne: "Please fill at least 1 of these fields",
    fileTypeError: "File type not accepted",
    configurationError: "Configuration error",
    followingItemsInsertError:
      "A error was encountere while inserting the following items:",
    insertError: "Insert Error",
    configurationAlreadyPresent: "Configuration Already Present",
    invalidValue: "Invalid value",
    api: {
      get: "Error loading data",
      post: "Error seding data",
      put: "Error putting data",
      delete: "Error deleting data",
    },
  },

  error_codes: {
    AUTH01: "Login error",
    GEN01: "Error with API gateway",
    GEN02: "Error processing request",
    E003: "Invalid credentials",
    INV01: "Invalid invitation token",
    ACCESS_DENIED_ACCOUNT: "Access denied, invalid account",
    "INVALID-PAYLOAD": "Invalid payload",
    "INVALID-DID": "Tracking number not valid",
    "SUB-ACCOUNTS": "Unable to delete accounts with sub accounts",
    "ACTIVE-NUMBERS": "Unable to delete accounts with active numbers",
    "file-too-large": "File too large",
    "file-invalid-type": "Invalid file type",
    "INVALID-PASSWORD": "Wrong password",
    "ORGANISATION-NOT-FOUND": "Organisation not found",
    "MISSING-COUNTRY-CHARGES-COUNTRY":
      "You are not authorised to send SMS to this countries: <strong>{country}</strong><br/>",
    "MISSING-COUNTRY-CHARGES":
      "Missing country charges, please review the target numbers or configure the {rate} rates for the relative countries ({country})",
    "NO-VALID-TARGETS": "Targets are all invalid",
    "NUMBER-NOT-FOUND": "Number not found",
    "INVALID-2FA-CODE-MISS": "Invalid OTP code",
    "MICROSOFT-API-FAILED": "Error with Microsoft API response",
    "POPUP-CLOSED-BY-USER": "Process canceled by user",
    "NO-ACTION-ID-LOOP-DETECTED": "There is an error with the configuration",
    "EXPORTER-NOT-FOUND": "The exporter was not found",
    "STRIPE-NOT-CONFIGURED":
      "You dont have any Stripe ayment methods configured",
  },
};
