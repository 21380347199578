import AppConfig from "Constants/AppConfig";
import REQ from "Api/Requests";

import MsgModel from "Models/msgs/Msg";
import BulkReport from "Models/msgs/BulkReport";
import List from "Models/msgs/List";

export default {
  sms: {
    async get(params) {
      try {
        const path = REQ.setPath("sms/mix", params);
        const response = await REQ.get(path);
        return response.data.map((a) => Object.assign(new MsgModel(), a));
      } catch (err) {
        throw err;
      }
    },

    async lists(params) {
      try {
        const path = REQ.setPath("sms/list", params);
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async bulk(params) {
      try {
        const path = REQ.setPath("sms/bulk", params);
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async bulkSingle(uuid) {
      try {
        const path = REQ.setPath("sms/bulk/" + uuid);
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async quote(payload) {
      try {
        const path = REQ.setPath("sms/bulk/quote");
        const response = await REQ.put(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async senders(params) {
      try {
        const path = REQ.setPath("/sms/senders/", params);
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async edit(payload) {
      try {
        const path = REQ.setPath("/sms/bulk/" + payload.uuid);
        const response = await REQ.post(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async preview(uuid, num) {
      try {
        const path = REQ.setPath("/sms/bulk/" + uuid + "/" + num);
        const response = await REQ.patch(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async payloadPreview(payload, num) {
      try {
        const path = REQ.setPath("/sms/bulk/preview/" + num);
        const response = await REQ.put(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async send(payload) {
      try {
        const path = REQ.setPath("sms/bulk");
        const response = await REQ.put(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async delete(uuid) {
      try {
        const path = REQ.setPath("sms/bulk/" + uuid);
        const response = await REQ.delete(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  bulkReports: {
    async list(params) {
      try {
        const path = REQ.setPath("sms/bulk", params, ["accounts"]);
        const response = await REQ.get(path);
        return response.data.map((a) => Object.assign(new BulkReport(), a));
      } catch (err) {
        throw err;
      }
    },

    async get(uuid) {
      try {
        const path = REQ.setPath("sms/bulk/" + uuid);
        const response = await REQ.get(path);
        return Object.assign(new BulkReport(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async full(uuid) {
      try {
        const path = REQ.setPath("sms/bulk/" + uuid + "/full");
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  lists: {
    async list(params) {
      try {
        const path = REQ.setPath("sms/list", params);
        const response = await REQ.get(path);
        return response.data.map((a) => Object.assign(new List(), a));
      } catch (err) {
        throw err;
      }
    },

    async get(uuid) {
      try {
        const path = REQ.setPath("sms/list/" + uuid);
        const response = await REQ.get(path);
        return Object.assign(new List(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async create(payload) {
      try {
        const path = REQ.setPath("sms/list");
        const response = await REQ.put(path, payload);
        return Object.assign(new List(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async edit(payload) {
      try {
        const path = REQ.setPath("sms/list/" + payload.uuid);
        const response = await REQ.post(path, payload);
        return Object.assign(new List(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async delete(uuid) {
      try {
        const path = REQ.setPath("sms/list/" + uuid);
        const response = await REQ.delete(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  blocks: {
    async check(account, num) {
      try {
        const path = REQ.setPath("sms/block/" + account + "/" + num);
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async get(uuid) {
      try {
        const path = REQ.setPath("sms/block/" + account + "/" + num);
        const response = await REQ.put(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  chats: {
    async getMessages(params) {
      try {
        const path = REQ.setPath("sms/mix", params);
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async send(payload) {
      try {
        const path = REQ.setPath("sms/send");
        const response = await REQ.put(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },
};
