export default class Custmoisation {
  constructor(
    title,
    logo,
    icon,
    login,
    links,
    stickyHeader,
    style,
    cssFile,
    colors
  ) {
    this.title = title || "Wildjar";
    this.logo = logo || "/assets/brand/wildjar-text.png";
    this.icon = icon || "/assets/icon.png";
    this.login = login || "/assets/brand/wildjar-logo.png";
    this.stickyHeader = stickyHeader || true;
    this.style = style || "";
    this.cssFile = cssFile || "";
    this.links = links || {
      support: "https://support.wildjar.com/hc/en-us/",
      tcs: "https://www.wildjar.com/terms-and-conditions",
    };
    this.colors = colors || {
      primary: "#5d78ff",
      secondary: "#ffa709",
      accent: "#82B1FF",
      blue: "#5d78ff",
      dark: "#333333",
      error: "#fd397a",
      info: "#5d78ff",
      success: "#0abb87",
      background: "#FFFFFF",
      "background-2": "#f2f3f8",
      menu: "#1e1e2d",
      "menu-dark": "#1b1b28",
      "menu-text": "#FFFFFF",
      border: "#dbdbdb",
      text: "#595d6e",
      answered: "#5d78ff",
      missed: "#fd397a",
      abandoned: "#ffa709",
    };
  }
}
