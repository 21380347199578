//import { Tooltip } from "bootstrap"

const nextPadDirective = {
  resizeObserver: new ResizeObserver((entires) => {
    if (entires[0].target && entires[0].target.nextElementSibling) {
      entires[0].target.nextElementSibling.style.paddingTop =
        entires[0].target.offsetHeight + "px";
    }
  }),
  mounted(el, binding, vnode) {
    if (el.nextElementSibling) {
      el.nextElementSibling.style.paddingTop = el.offsetHeight + "px";
      nextPadDirective.resizeObserver.observe(el);
    }
  },
  unmounted(el, binding, vnode) {
    nextPadDirective.resizeObserver.unobserve(el);
  },
};

export function registerDirectives(app) {
  app
    /*
    .directive("tooltip", (el) => {
      new Tooltip(el);
    })
    */
    .directive("next-pad", nextPadDirective)

    .directive("uppercase", (el, binding) => {
      el.addEventListener("input", (e) => {
        if (
          typeof binding.value === "undefined" ||
          (binding.value && binding.value === true)
        ) {
          let position = e.target.selectionStart;
          e.target.value = e.target.value.toUpperCase();
          setTimeout(() => {
            e.target.selectionEnd = position;
          });
        }
      });
    })

    .directive("numberValue", (el) => {
      el.addEventListener("input", (e) => {
        let position = e.target.selectionStart;
        const newVal =
          (Boolean(e.target.value) || e.target.value === 0) &&
          !isNaN(e.target.value)
            ? parseFloat(e.target.value)
            : e.target.value;
        e.target.value = newVal;
        setTimeout(() => {
          e.target.selectionEnd = position;
        });
      });
    })

    .directive("numberDotsInput", (el) => {
      el.addEventListener("keypress", (e) => {
        const keyCode = e.keyCode ? e.keyCode : e.which;
        if (
          isNaN(e.key) &&
          keyCode !== 46 &&
          keyCode !== 8 &&
          keyCode !== 110 &&
          keyCode !== 190
        ) {
          e.preventDefault();
        }
      });
    })

    .directive("numberInput", (el) => {
      el.addEventListener("keypress", (e) => {
        const keyCode = e.keyCode ? e.keyCode : e.which;
        if (
          (isNaN(e.key) && !["Backspace", "Delete"].includes(e.key)) ||
          keyCode == 32
        ) {
          e.preventDefault();
        }
      });
    })

    .directive("numpadInput", (el) => {
      el.addEventListener("keypress", (e) => {
        const keyCode = e.keyCode ? e.keyCode : e.which;
        if (
          (isNaN(e.key) &&
            !["Backspace", "Delete", "#", "*"].includes(e.key)) ||
          keyCode == 32
        ) {
          e.preventDefault();
        }
      });
    })

    .directive("phoneInput", (el, binding) => {
      if (binding.value !== false) {
        el.addEventListener("keypress", (e) => {
          const keyCode = e.keyCode ? e.keyCode : e.which;
          if (
            (isNaN(e.key) && !["Backspace", "Delete", "+"].includes(e.key)) ||
            keyCode == 187
          ) {
            e.preventDefault();
          }
        });
        /*
        el.addEventListener('paste', (e) => {
          setTimeout(() => {
            e.target.value = e.target.value.replace(/\D/g, "")
          },100)
        })
        */
      }
    })

    .directive("transition", (el, binding) => {
      el.addEventListener("transitionstart", (e) => {
        if (Boolean(binding.value) && binding.value != e.propertyName) return;

        if (!el.classList.contains("transitioning")) {
          el.classList.add("transitioning");
        }
      });
      el.addEventListener("transitionend", (e) => {
        if (el.classList.contains("transitioning")) {
          setTimeout(() => {
            el.classList.remove("transitioning");
          }, 150);
        }
      });
    })

    .directive("scroll-shadow-parent", (el) => {
      function elChange(ele) {
        if (ele[0].target.scrollHeight > ele[0].target.clientHeight) {
          if (!el.parentNode.classList.contains("scroll-shadow-btm")) {
            el.parentNode.classList.add("scroll-shadow-btm");
          }
        } else {
          el.parentNode.classList.remove("scroll-shadow-btm");
        }
      }
      new ResizeObserver(elChange).observe(el);
      el.addEventListener("scroll", (e) => {
        if (el.scrollTop > 0) {
          if (!el.parentNode.classList.contains("scroll-shadow-top")) {
            el.parentNode.classList.add("scroll-shadow-top");
          }
        } else {
          el.parentNode.classList.remove("scroll-shadow-top");
        }
      });
    })

    .directive("scroll-shadow", (el) => {
      function elChange(ele) {
        if (ele[0].target.scrollHeight > ele[0].target.clientHeight) {
          if (!el.classList.contains("scroll-shadow-btm")) {
            el.classList.add("scroll-shadow-btm");
          }
        } else {
          el.classList.remove("scroll-shadow-btm");
        }
      }
      new ResizeObserver(elChange).observe(el);
      el.addEventListener("scroll", (e) => {
        if (el.scrollTop > 0) {
          if (!el.classList.contains("scroll-shadow-top")) {
            el.classList.add("scroll-shadow-top");
          }
        } else {
          el.classList.remove("scroll-shadow-top");
        }
      });
    });
}
