<template>
  <div
    class="app-card"
    :class="props.selected ? 'bg-primary-lighter' : 'bg-background'"
    @click="emit('click')"
  >
    <div
      class="app-card-header"
      v-if="props.header"
    >
      <slot name="header"></slot>
    </div>

    <div
      class="nicescroll app-card-content"
      :class="props.contentClass"
    >
      <slot></slot>
    </div>

    <div
      class="app-card-footer"
      v-if="props.footer"
    >
      <slot name="footer"></slot>
    </div>

    <slot name="root"></slot>
  </div>
</template>

<script setup>
const props = defineProps({
  header: {
    type: [Boolean, String],
    default: false,
  },
  footer: {
    type: [Boolean, String],
    default: false,
  },
  contentClass: {
    type: String,
    default: "",
  },
  selected: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["click"]);
</script>
