import AppConfig from "Constants/AppConfig";
import REQ from "Api/Requests";

import Account from "Models/settings/Account";
import NumberModel from "Models/settings/Number";
import BranchModel from "Models/settings/Branch";
import LocationsModel from "Models/settings/actions/Locations";
import PortingListItem from "Models/settings/PortingListItem";
import PortingItem from "Models/settings/PortingItem";

export default {
  accounts: {
    async get(id) {
      try {
        const path = REQ.setPath("account/" + id);
        const response = await REQ.get(path);
        return Object.assign(new Account(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async create(payload) {
      try {
        const path = REQ.setPath("account");
        const response = await REQ.put(path, payload);
        return Object.assign(new Account(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async edit(payload) {
      try {
        const path = REQ.setPath("account/" + payload.id);
        const response = await REQ.post(path, payload);
        return Object.assign(new Account(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async del(id) {
      try {
        const path = REQ.setPath("account/" + id + "/delete");
        const response = await REQ.patch(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  numbers: {
    async getDids(params) {
      try {
        const path = REQ.setPath("/number/available/" + params.country, params);
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async createTrackingNumber(payload, accountId, did) {
      try {
        const path = REQ.setPath("/number/" + accountId + "/" + did);
        const response = await REQ.put(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async getDid(did) {
      try {
        const path = REQ.setPath("number/" + did + "/extra");
        const response = await REQ.get(path);
        return Object.assign(new NumberModel(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async edit(payload) {
      try {
        const path = REQ.setPath("number/" + payload.did);
        const response = await REQ.post(path, payload);
        return Object.assign(new Account(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async del(did) {
      try {
        const path = REQ.setPath("number/" + did);
        const response = await REQ.delete(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  porting: {
    async list(params) {
      try {
        const path = REQ.setPath("number/porting", params);
        const response = await REQ.get(path);
        return response.data.map((a) =>
          Object.assign(new PortingListItem(), a),
        );
      } catch (err) {
        throw err;
      }
    },

    async get(id) {
      try {
        const path = REQ.setPath("number/porting/" + id);
        const response = await REQ.get(path);
        return Object.assign(new PortingItem(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async create(payload) {
      try {
        const path = REQ.setPath("number/porting");
        const response = await REQ.put(path, payload);
        return Object.assign(new PortingItem(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async edit(payload) {
      try {
        const path = REQ.setPath("number/porting/" + payload.id);
        const response = await REQ.post(path, payload);
        return Object.assign(new PortingItem(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async del(id) {
      try {
        const path = REQ.setPath("number/porting/" + id);
        const response = await REQ.delete(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  actions: {
    async get(accountId, action, actionId) {
      try {
        const path = REQ.setPath(
          "/routing/" + accountId + "/" + action + "/" + actionId,
        );
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async create(payload, accountId, action) {
      try {
        const path = REQ.setPath("/routing/" + accountId + "/" + action);
        const response = await REQ.put(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async edit(payload, accountId, action, actionId) {
      try {
        const path = REQ.setPath(
          "/routing/" + accountId + "/" + action + "/" + actionId,
        );
        const response = await REQ.post(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async editDid(payload, accountId, did) {
      try {
        const path = REQ.setPath("/routing/" + accountId + "/did/" + did);
        const response = await REQ.post(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async validate(payload, accountId) {
      try {
        const path = REQ.setPath("/routing/" + accountId + "/validate");
        const response = await REQ.post(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async getFlowActions(accountId, did) {
      try {
        const path = REQ.setPath("routing/" + accountId + "/recursive/" + did);
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  branches: {
    async get(accountId) {
      try {
        const path = REQ.setPath("routing/" + accountId + "/branch", {
          account: accountId,
        });
        const response = await REQ.get(path);
        return response.data.map((a) => Object.assign(new BranchModel(), a));
      } catch (err) {
        throw err;
      }
    },

    async create(payload, accountId) {
      try {
        const path = REQ.setPath("routing/" + accountId + "/branch/");
        const response = await REQ.put(path, payload);
        return Object.assign(new BranchModel(), response);
      } catch (err) {
        throw err;
      }
    },

    async edit(payload, accountId) {
      try {
        const path = REQ.setPath(
          "routing/" + accountId + "/branch/" + payload.id,
        );
        const response = await REQ.post(path, payload);
        return Object.assign(new BranchModel(), response);
      } catch (err) {
        throw err;
      }
    },

    async delete(accountId, branchId) {
      try {
        const path = REQ.setPath(
          "routing/" + accountId + "/branch/" + branchId,
        );
        const response = await REQ.delete(path);
        return true;
      } catch (err) {
        throw err;
      }
    },

    async getFlowActions(accountId, did, branchId) {
      try {
        const path = REQ.setPath(
          "routing/" + accountId + "/recursive/" + did + "/branch/" + branchId,
          { account: accountId },
        );
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  locations: {
    async get(accountId, id) {
      try {
        const path = REQ.setPath("routing/" + accountId + "/locations/" + id, {
          account: accountId,
        });
        const response = await REQ.get(path);
        return Object.assign(new LocationsModel(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async edit(payload, accountId) {
      try {
        const path = REQ.setPath(
          "routing/" + accountId + "/locations/" + payload.id,
          { account: accountId },
        );
        const response = await REQ.post(path, payload);
        return Object.assign(new LocationsModel(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async getRoutingMap(accountId, id) {
      try {
        const path = REQ.setPath(
          "routing/" + accountId + "/locations/" + id + "/map",
          { account: accountId },
        );
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  applications: {
    async get(did) {
      try {
        const path = REQ.setPath("sms/application/", { number: did });
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async create(payload) {
      try {
        const path = REQ.setPath("sms/application/");
        const response = await REQ.put(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async edit(payload) {
      try {
        const path = REQ.setPath("sms/application/" + payload.uuid);
        const response = await REQ.post(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  structure: {
    async get() {
      try {
        const path = REQ.setPath("account/tree");
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async moveAccount(childId, parentId, accountId) {
      try {
        const path = REQ.setPath("account/tree/" + childId + "/" + parentId);
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  blocked: {
    async get(accountId) {
      try {
        const path = REQ.setPath("routing/" + accountId + "/blocked");
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async block(payload, accountId) {
      try {
        const path = REQ.setPath("routing/" + accountId + "/blocked");
        const response = await REQ.post(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async unblock(accountId, number) {
      try {
        const path = REQ.setPath("routing/" + accountId + "/blocked/" + number);
        const response = await REQ.delete(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },
};
