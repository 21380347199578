import AppConfig from "./AppConfig";

export default {
  accessToken: AppConfig.services.rollbar.token,
  enabled: AppConfig.services.rollbar.enabled,
  captureUncaught: true,
  captureUnhandledRejections: true,
  uncaughtErrorLevel: "warning",
  reportLevel: "warning",
  captureIp: "anonymize",
  autoInstrument: {
    network: true,
    log: true,
    dom: false,
    navigation: true,
    connectivity: false,
    contentSecurityPolicy: true,
    errorOnContentSecurityPolicy: false,
  },
  payload: {
    environment: AppConfig.environment,
    app_version: AppConfig.version,
    session: null,
  },
  ignoredMessages: ["AUTH-FAILED","AUTH-INVALID-ACL-BLOCK"],
  checkIgnore: function (isUncaught, args, payload) {
    return (
      payload.level === "log" ||
      payload.level === "info" ||
      payload.level === "warning"
    );
  },
};
