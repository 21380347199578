<template>
  <v-list
    v-model:selected="value"
    color="primary"
    variant="tonal"
    select-strategy="single-independent"
    v-bind="$attrs"
  >
    <v-list-item
      v-for="(item, i) in items"
      :key="'item-' + i"
      :class="{ 'mt-3': i > 0 }"
      :value="props.itemValue ? item[props.itemValue] : item"
      v-bind="props.item"
    >
      <template
        #append
        v-if="slots.append"
      >
        <slot
          name="append"
          :item="item"
          :index="i"
        />
      </template>
      <template
        #prepend
        v-if="slots.prepend"
      >
        <slot
          name="prepend"
          :item="item"
          :index="i"
        />
      </template>
      <template
        #header
        v-if="slots.header"
      >
        <slot
          name="header"
          :item="item"
          :index="i"
        />
      </template>
      <template
        #subheader
        v-if="slots.subheader"
      >
        <slot
          name="subheader"
          :item="item"
          :index="i"
        />
      </template>
      <template
        #subtitle
        v-if="slots.subtitle"
      >
        <slot
          name="subtitle"
          :item="item"
          :index="i"
        />
      </template>
      <template
        #title
        v-if="slots.title"
      >
        <slot
          name="title"
          :item="item"
          :index="i"
        />
      </template>
    </v-list-item>
  </v-list>
</template>

<script setup>
const slots = useSlots();

const props = defineProps({
  modelValue: {
    type: [String, Object, Boolean],
    default: null,
  },
  items: {
    type: Array,
    default: () => [],
  },
  item: {
    type: Object,
    default: null,
  },
  itemValue: {
    type: String,
    default: null,
  },
});
const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get() {
    return [props.modelValue];
  },
  set(newVal) {
    emit("update:modelValue", newVal.length ? newVal[0] : props.items[0]);
  },
});
</script>
