import CallAttribution from "./CallAttribution";
import CallDuration from "./CallDuration";
import Insights from "./Insights";
import MissedCalls from "./MissedCalls";
import TimeAndDay from "./TimeAndDay";
import WebAttribution from "./WebAttribution";
import Ivr from "./Ivr";

export default {
  path: "/reports",
  name: "reports",
  redirect: "/calls/time",
  children: [
    TimeAndDay,
    WebAttribution,
    CallAttribution,
    CallDuration,
    MissedCalls,
    Insights,
    Ivr,
  ],
};
