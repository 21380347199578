export default class Biller {
  constructor(
    autoPay,
    clientId,
    code,
    credit,
    currency,
    mode,
    name,
    paymentGW,
    paymentId,
    type,
    xeroContact,
  ) {
    this.autoPay = autoPay;
    this.clientId = clientId;
    this.code = code;
    this.credit = credit;
    this.currency = currency;
    this.mode = mode;
    this.name = name;
    this.paymentGW = paymentGW;
    this.paymentId = paymentId;
    this.type = type;
    this.xeroContact = xeroContact;
  }
}
