import { defineStore } from "pinia";

export const useLayoutStore = defineStore("layout", {
  state: () => ({
    asideMinimize: false,
    asideMobile: false,
    headerMobile: false,
    headerMenuMobile: false,
    pagesMinimize: {
      calls: true,
      "outbound-calls": true,
      "settings-single-wizard": true,
    },
    tables: {},
    grids: {},
  }),
  getters: {
    minimized() {
      let minKey = Object.keys(this.pagesMinimize).find((a) =>
        this.$router.currentRoute.value.name.includes(a),
      );
      return minKey ? this.pagesMinimize[minKey] : this.asideMinimize;
    },
  },
  actions: {
    toggleMinimize() {
      let minKey = Object.keys(this.pagesMinimize).find((a) =>
        this.$router.currentRoute.value.name.includes(a),
      );
      if (minKey) {
        this.pagesMinimize[minKey] = !this.pagesMinimize[minKey];
      } else {
        this.asideMinimize = !this.asideMinimize;
      }
    },
  },
  persist: true,
});
