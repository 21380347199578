export default class Organization {
  constructor(
    uuid,
    tenantId,
    name,
    country,
    currency,
    xeroOAuth,
    xeroBankId,
    stripePubKey,
    ownerAccount,
    rates,
    slackHook,
    dash,
  ) {
    this.uuid = uuid;
    this.tenantId = tenantId;
    this.name = name;
    this.country = country;
    this.currency = currency;
    this.xeroOAuth = xeroOAuth;
    this.xeroBankId = xeroBankId;
    this.stripePubKey = stripePubKey;
    this.ownerAccount = ownerAccount;
    this.rates = rates;
    this.slackHook = slackHook;
    this.dash = dash;
  }
}
