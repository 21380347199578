const secondsDiff = (pastTimestamp) => {
  const now = Date.now(); // get the current timestamp in milliseconds
  const past = new Date(pastTimestamp).getTime(); // convert the past timestamp to milliseconds
  const differenceInMilliseconds = now - past; // calculate the difference in milliseconds
  const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000); // convert milliseconds to seconds

  return differenceInSeconds < 0
    ? differenceInSeconds * -1
    : differenceInSeconds;
};

const fullDuration = (seconds) => {
  const years = Math.floor(seconds / (365 * 24 * 3600)); // calculate years
  seconds %= 365 * 24 * 3600; // remaining seconds after extracting years

  const months = Math.floor(seconds / (30 * 24 * 3600)); // approximate months (assuming 30 days in a month)
  seconds %= 30 * 24 * 3600; // remaining seconds after extracting months

  const weeks = Math.floor(seconds / (7 * 24 * 3600)); // calculate weeks
  seconds %= 7 * 24 * 3600; // remaining seconds after extracting weeks

  const days = Math.floor(seconds / (24 * 3600)); // calculate days
  seconds %= 24 * 3600; // remaining seconds after extracting days

  const hours = Math.floor(seconds / 3600); // calculate hours
  seconds %= 3600; // remaining seconds after extracting hours

  const minutes = Math.floor(seconds / 60); // calculate minutes
  seconds %= 60; // remaining seconds after extracting minutes

  return {
    years,
    months,
    weeks,
    days,
    hours,
    minutes,
    seconds,
  };
};

const HrMinSecMS = (secs) => {
  const hours = Math.floor(secs / 3600); // calculate hours
  secs %= 3600; // remaining seconds after extracting hours
  const minutes = Math.floor(secs / 60); // calculate minutes
  secs %= 60; // remaining seconds after extracting minutes
  const seconds = Math.floor(secs); // extract whole seconds
  const milliseconds = Math.floor((secs - seconds) * 1000); // calculate milliseconds

  return { hours, minutes, seconds, milliseconds };
};

const MinSecMs = (secs) => {
  const minutes = Math.floor(secs / 60); // calculate minutes
  secs %= 60; // remaining seconds after extracting minutes
  const seconds = Math.floor(secs); // extract whole seconds
  const milliseconds = Math.floor((secs - seconds) * 1000); // calculate milliseconds

  return { minutes, seconds, milliseconds };
};

const MinSec = (seconds) => {
  const minutes = Math.floor(seconds / 60); // calculate minutes
  seconds %= 60; // remaining seconds after extracting minutes

  return { minutes, seconds };
};

const HrsMinSec = (seconds) => {
  const hours = Math.floor(seconds / 3600); // calculate hours
  seconds %= 3600; // remaining seconds after extracting hours
  const minutes = Math.floor(seconds / 60); // calculate minutes
  seconds %= 60; // remaining seconds after extracting minutes

  return { hours, minutes, seconds };
};

const DayHrsMinSec = (seconds) => {
  const days = Math.floor(seconds / (24 * 3600)); // calculate days
  seconds %= 24 * 3600; // remaining seconds after extracting days
  const hours = Math.floor(seconds / 3600); // calculate hours
  seconds %= 3600; // remaining seconds after extracting hours
  const minutes = Math.floor(seconds / 60); // calculate minutes
  seconds %= 60; // remaining seconds after extracting minutes

  return { days, hours, minutes, seconds };
};

const getFormatDuration = (seconds, format = "hh:mm:ss") => {
  seconds = parseInt(seconds);
  if (format == "full") {
    return fullDuration(seconds);
  }
  if (format == "hh:mm:ss:ss") {
    return HrMinSecMS(seconds);
  }
  if (format == "mm:ss:ss") {
    return MinSecMs(seconds);
  }
  if (format == "mm:ss") {
    return MinSec(seconds);
  }
  if (format == "hh:mm:ss") {
    return HrsMinSec(seconds);
  }
  if (format == "dd:hh:mm:ss") {
    return DayHrsMinSec(seconds);
  }
  return null;
};

export { secondsDiff, getFormatDuration };
