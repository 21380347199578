<template>
  <v-list
    v-model:selected="value"
    color="primary"
    variant="tonal"
    select-strategy="single-independent"
    v-bind="$attrs"
  >
    <v-virtual-scroll :items="items" :item-height="35">
      <template v-slot="{ item, index }">
        <v-divider
          v-if="item.type == 'divider'"
          :class="[{ [props.spacing]: index > 0 }, item.class]"
        />
        <div
          v-if="item.type == 'subheader'"
          v-html="item.title"
          :class="[{ [props.spacing]: index > 0 }, item.class]"
          class="v-list-subheader"
        />
        <v-list-item
          v-if="!['subheader', 'divider'].includes(item.type)"
          :key="'item-' + index"
          :class="{ [props.spacing]: index > 0 }"
          :value="props.itemValue ? item[props.itemValue] : item"
          v-bind="props.item"
        >
          <template #append v-if="slots.append">
            <slot name="append" :item="item" :index="index" />
          </template>
          <template #prepend v-if="slots.prepend">
            <slot name="prepend" :item="item" :index="index" />
          </template>
          <template #header v-if="slots.header">
            <slot name="header" :item="item" :index="index" />
          </template>
          <template #subheader v-if="slots.subheader">
            <slot name="subheader" :item="item" :index="index" />
          </template>
          <template #subtitle v-if="slots.subtitle">
            <slot name="subtitle" :item="item" :index="index" />
          </template>
          <template #title v-if="slots.title">
            <slot name="title" :item="item" :index="index" />
          </template>
        </v-list-item>
      </template>
    </v-virtual-scroll>
  </v-list>
</template>

<script setup>
const slots = useSlots();

const props = defineProps({
  modelValue: {
    type: [String, Object, Boolean],
    default: null,
  },
  items: {
    type: Array,
    default: () => [],
  },
  item: {
    type: Object,
    default: null,
  },
  itemValue: {
    type: String,
    default: null,
  },
  spacing: {
    type: String,
    default: "mt-3",
  },
});
const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get() {
    return [props.modelValue];
  },
  set(newVal) {
    emit("update:modelValue", newVal[0]);
  },
});
</script>
