import AppConfig from "Constants/AppConfig";
import REQ from "Api/Requests";

import Call from "Models/calls/Call";
import Tag from "Models/calls/Tag";

export default {
  async getCalls(params) {
    try {
      const path = REQ.setPath("call", params, ["accounts", "date", "filters"]);
      const response = await REQ.get(path);
      console.lo;
      return {
        total: response.recordsFiltered,
        items: response.data.map((a) => Object.assign(new Call(), a)),
      };
    } catch (err) {
      throw err;
    }
  },

  async getCall(id) {
    try {
      const path = REQ.setPath("call/" + id);
      const response = await REQ.get(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async getOutboundCalls(params) {
    try {
      const path = REQ.setPath("call/outbound", params, [
        "accounts",
        "date",
        "filters",
      ]);
      const response = await REQ.get(path);
      console.lo;
      return {
        total: response.recordsFiltered,
        items: response.data.map((a) => Object.assign(new Call(), a)),
      };
    } catch (err) {
      throw err;
    }
  },

  async getCallSummary(account, uuid, params) {
    try {
      const path = REQ.setPath(
        "call/summarise/" + account + "/" + uuid,
        params,
      );
      const response = await REQ.get(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async getCallDetails(uuid, type, params) {
    try {
      const typePath = type == "outbound" ? "outbound/" : "";
      const path = REQ.setPath("call/" + typePath + uuid + "/full", params, [
        "accounts",
      ]);
      const response = await REQ.get(path);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async patchTypeCall(payload, id, type, params) {
    try {
      const typePath = type == "outbound" ? "outbound/" : "";
      const path = REQ.setPath("call/" + typePath + id, params);
      const response = await REQ.patch(path, payload);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async getTags(params) {
    try {
      const path = REQ.setPath("call/tag", params, ["accounts", "date"]);
      const response = await REQ.get(path);
      let map = {};
      response.data.map((a) => {
        map[a.uuid] = Object.assign(new Tag(), a);
      });
      return map;
    } catch (err) {
      throw err;
    }
  },

  async blockAccount(id, account, params) {
    try {
      const path = REQ.setPath("routing/" + account + "/blocked", params);
      const response = await REQ.patch(path, { cdrId: id });
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  async saveActionLog(action, id, params) {
    try {
      const path = REQ.setPath("call/" + id, params);
      const response = await REQ.patch(path, { action: action });
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  history: {
    async get(did, params) {
      try {
        const path = REQ.setPath("reports/history/" + did, params, [
          "accounts",
        ]);
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },
};
