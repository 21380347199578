export default {
  VBtn: {
    variant: "flat", //['elevated','flat','tonal','outlined','text','plain']
    density: "default", //['compact','comfortable','default']
    size: "default", //['x-small','small','default','large','x-large']
    rounded: "md", //['0','xs','sm','md','lg','xl'],
    ripple: true,
  },
  VTextField: {
    variant: "outlined", //['solo','filled','outlined','plain','underlined']
    density: "comfortable", //['compact','comfortable','default']
    clearIcon: "mdi mdi-close",
  },
  VNumberInput: {
    menuIcon: "mdi mdi-chevron-down",
    clearIcon: "mdi mdi-close",
    density: "comfortable",
    variant: "outlined",
    color: "primary",
  },
  VTextarea: {
    variant: "outlined", //['solo','filled','outlined','plain','underlined']
    density: "comfortable", //['compact','comfortable','default']
    clearIcon: "mdi mdi-close",
  },
  VForm: {
    validateOn: "input",
  },
  VAutocomplete: {
    menuIcon: "mdi mdi-chevron-down",
    clearIcon: "mdi mdi-close",
    density: "comfortable",
    variant: "outlined",
    color: "primary",
  },
  VSelect: {
    menuIcon: "mdi mdi-chevron-down",
    clearIcon: "mdi mdi-close",
    density: "comfortable",
    variant: "outlined",
    color: "primary",
  },
  VCombobox: {
    menuIcon: "mdi mdi-chevron-down",
    clearIcon: "mdi mdi-close",
    density: "comfortable",
    variant: "outlined",
    color: "primary",
  },
  VDataTable: {
    density: "comfortable",
    rowProps: (data) => {
      return {
        ["data-id"]: data.item.id || data.item.uuid,
      };
    },
  },
  VAlert: {
    variant: "tonal",
  },
  VSwitch: {
    trueIcon: "mdi-check",
    falseIcon: "mdi-close",
    inset: true,
  },
};
