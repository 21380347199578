<template>
  <v-btn
    variant="text"
    class="px-0 py-0 close-btn"
    color="grey"
    v-bind="$attrs"
    iconb
    small
    @click="emit('close')"
  >
    <v-icon :size="props.size">mdi-close</v-icon>
  </v-btn>
</template>

<script setup>
const props = defineProps({
  size: {
    type: [Number, String],
    default: 28,
  },
});
const emit = defineEmits(["close"]);
</script>
