<template>
  <div
    class="expansion-panel"
    :class="{ open: value }"
  >
    <div
      class="expansion-panel-header"
      :class="[props.headerClass, { pointer: !props.disabled }]"
      @click="toggle()"
    >
      <slot name="header">
        <div
          class="expansion-panel-title"
          :class="props.titleClass"
        >
          <slot name="title"></slot>
        </div>
        <v-icon
          class="expansion-panel-icon"
          color="primary"
          size="28"
        >mdi-chevron-down</v-icon>
      </slot>
    </div>
    <v-expand-transition>
      <div v-show="value">
        <div
          class="expansion-panel-content"
          :class="props.contentClass"
        >
          <slot></slot>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script setup>
const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  headerClass: {
    type: String,
    default: "",
  },
  titleClass: {
    type: String,
    default: "",
  },
  contentClass: {
    type: String,
    default: "",
  },
});

const value = ref(props.value);

const toggle = () => {
  if (props.disabled) return;
  value.value = !value.value;
};

const close = () => {
  value.value = false;
};
</script>
