export default class Branch {
  constructor(
    id,
    account,
    address,
    autoRouting,
    code,
    country,
    forceAccount,
    lat,
    locationId,
    lon,
    name,
    nextAction,
    nextActionId,
    postcode,
    state,
  ) {
    this.id = id;
    this.account = account;
    this.address = address;
    this.autoRouting = autoRouting;
    this.code = code;
    this.country = country;
    this.forceAccount = forceAccount || "";
    this.lat = lat;
    this.locationId = locationId;
    this.lon = lon;
    this.name = name;
    this.nextAction = nextAction;
    this.nextActionId = nextActionId;
    this.postcode = postcode;
    this.state = state;
  }
}
