<template>
  <div class="ribbon">
    <span class="ribbon-inner"></span>
    <slot>{{ props.text }}</slot>
  </div>
</template>

<script setup>
const props = defineProps({
  text: {
    type: String,
    default: "",
  },
});
</script>
