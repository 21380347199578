import i18n from "Plugins/i18n";
import Messages from "Src/lang";
import { useAuthStore } from "@/stores/auth";
import { $capitalize } from "Helpers/Data";
import { $notice } from "Helpers/Dom";
import Router from "Router";
import { rollbar } from "Plugins/rollbar";

const getErrorMessage = (error) => {
  const { t } = i18n.global;

  const locale = i18n.global.locale.value;
  const errors =
    Messages[locale] && Messages[locale].error_codes
      ? Messages[locale].error_codes
      : null;
  const errorCode =
    error && error.response
      ? error.response.data.errorCode || error.response.data.error_code
      : null;
  if (errorCode == "MISSING-FIELD") {
    return (
      "[" +
      errorCode +
      "] " +
      $capitalize(
        t("error_codes." + errorCode, {
          fields: Object.keys(error.response.data.info).join(", "),
        }),
      )
    );
  }
  return errorCode && errors && Object.keys(errors).includes(errorCode)
    ? "[" + errorCode + "] " + $capitalize(t("error_codes." + errorCode))
    : null;
};

export const parseErrors = (error, bypass = false, log = true) => {
  const { t } = i18n.global;
  const { isInternalLink } = useNavigationManager();
  const authStore = useAuthStore();
  const errorCode =
    error && error.response
      ? error.response.data.errorCode || error.response.data.error_code
      : null;

  //log on rollbar
  if (log) {
    rollbar.error(errorCode, error.response?.data);
  }

  if (
    error &&
    error.response &&
    error.response.status === 401 &&
    errorCode == "INVALID-TOKEN-BT2" &&
    authStore.loggedIn &&
    !bypass
  ) {
    if (isInternalLink()) {
      $notice({
        title: `<div class="text-center w-100">${t("errors.sessionExpired")}</div>`,
        text: `<div class="text-center">${t("errors.sessionExpiredMsg")}</div>`,
        actionsPos: "center",
        noIcon: true,
        btn: t("loginAgain"),
        ok: () => {
          authStore.logout(Router.currentRoute.value.fullPath);
        },
      });
    } else {
      authStore.logout(Router.currentRoute.value.fullPath);
    }
  } else if (!error || !error.response) {
    return {
      code: 400,
      msg: "Error with API gateway",
      info: t("error_codes.GEN01"),
    };
  }
  return {
    error: error.response.data,
    code: error.response.status,
    msg: error.response.data.msg,
    info: getErrorMessage(error),
    clean: Boolean(error.response && error.response.status === 401),
  };
};

export const $catchError = (err) => {
  const { notify } = useNotification();
  const { t } = i18n.global;

  if (AppConfig.debug) {
    console.log(err);
  }

  notify({
    group: "br",
    type: "error",
    text: err.info || t("error_codes.GEN02"),
    clean: err.clean,
  });

  return err.code != 401;
};

export const dynamicTableQuery = ({ cols, page, ipp, sortBy, search }) => {
  let params = {
    length: ipp,
    start: (page - 1) * ipp,
    orderBy:
      sortBy.order.toLowerCase() == "asc" ? sortBy.key : "-" + sortBy.key,
    "order[0][column]": cols.findIndex((a) => a.key == sortBy.key),
    "order[0][dir]": sortBy.order.toLowerCase(),
    "search[value]": search,
    "search[regex]": false,
  };
  cols.map((col, i) => {
    params[`columns[${i}][data]`] = col.key;
    params[`columns[${i}][name]`] = col.key;
    params[`columns[${i}][searchable]`] = col.sortable === false ? false : true;
    params[`columns[${i}][orderable]`] = col.sortable === false ? false : true;
    params[`columns[${i}][search][value]`] = "";
    params[`columns[${i}][search][regex]`] = col.regex || false;
  });

  return params;
};
