<template>
  <v-app :class="{ animations: gpuOn }">
    <router-view v-slot="{ Component }" v-if="ready">
      <transition name="fade" mode="out-in">
        <component 
          :is="Component" 
          :key="mainKey" 
        />
      </transition>
    </router-view>

    <confirm />

    <notice />

    <back-to-top />

    <theme ref="themer" />

    <notifications 
      group="br" 
      position="bottom right" 
      class="nt-grp-br" 
    />

    <app-section-loader 
      :status="loader" 
      :message="loaderMsg"
      fixed 
    />
  </v-app>
</template>

<script setup>
//import { getGPUTier } from 'detect-gpu'
import Theme from "Components/common/Theme";
import Notice from "Components/common/Notice";
import BackToTop from "Components/common/BackToTop";
import { rollbar } from "Plugins/rollbar";
import { useHead } from "@unhead/vue";

const { t } = useI18n();

const sessionStore = useSessionStore();

const authStore = useAuthStore();
const { loggedIn } = storeToRefs(authStore);

const adminStore = useAdminStore();
const { identity } = storeToRefs(adminStore);

const mainKey = ref(1);
const loader = ref(false);
const loaderMsg = ref(null);
const ready = ref(false);
const themer = ref(null);

const gpuOn = ref(false);

if (AppConfig.services.appcues.enabled) {
  useHead({
    script: [
      // Google Analytics Setup
      { innerHTML: `window.AppcuesSettings = { enableURLDetection: false };` },
      // Load the script
      { src: AppConfig.services.appcues.script },
    ],
  });
}

//IDENTITY
watch(
  () => identity.value,
  async () => {
    if (!loggedIn.value) return;

    loaderMsg.value = t("loaders.switchingIdentity");
    loader.value = true;
    await Promise.all([
      sessionStore.getAccounts(),
      sessionStore.getNumbers(),
      sessionStore.getTags(),
    ]);
    mainKey.value++;
    loader.value = false;
    loaderMsg.value = null;
  },
  { deep: true },
);

onMounted(async () => {
  themer.value.init();
  ready.value = true;
  //const gpuTier = await getGPUTier();
  //gpuOn.value = gpuTier.tier > 2

  if (rollbar && ["local", "development"].includes(AppConfig.environment)) {
    console.log("Rollbar initalized: ", rollbar);
    if (AppConfig.environment === "local") {
      rollbar.configure({ enabled: false });
    }
  }
});
</script>

<style lang="scss">
@import "nprogress/nprogress.css";
@import "tippy.js/dist/tippy.css";
@import "tippy.js/themes/light.css";
@import "animate.css";
@import "@mdi/font/css/materialdesignicons.css";

@import "@/assets/styles/main.scss";
</style>
