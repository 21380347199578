import Numbers from "./Numbers";
import NewTrackingNumber from "./NewTrackingNumber";
import AccountStructure from "./AccountStructure";
import Customisation from "./Customisation";
import SingleAccount from "./Account";
import NewAccount from "./NewAccount";
import Porting from "./Porting";
import PortingList from "./PortingList";
import Blocks from "./Blocks";
import Wizard from "./wizard";

export default {
  path: "/settings",
  name: "settings",
  redirect: "/settings/tracking-number/new",
  children: [
    Numbers,
    NewTrackingNumber,
    AccountStructure,
    Customisation,
    SingleAccount,
    Wizard,
    NewAccount,
    Porting,
    PortingList,
    Blocks,
  ],
};
