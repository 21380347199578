import { defineStore } from "pinia";

export const useAdminStore = defineStore("admin", {
  state: () => ({
    identity: null,
  }),
  persist: {
    storage: sessionStorage,
  },
});
