<template>
  <v-select
    v-model="value"
    :items="filtered"
    :rules="props.rules"
    v-bind="$attrs"
    @update:menu="menuChange"
  >
    <template v-slot:item="data" v-if="slots.item">
      <slot
        name="item"
        :props="data.props"
        :item="data.item.raw"
        :index="data.index"
      />
    </template>

    <template v-slot:selection="data" v-if="slots.selection">
      <slot
        name="selection"
        :props="data.props"
        :item="data.item.raw"
        :index="data.index"
      />
    </template>

    <template v-slot:append-item v-if="slots.append">
      <slot name="append" />
    </template>

    <template #prepend-item>
      <div
        class="px-2 pb-0 pt-2 sticky left right bg-background z-2"
        style="top: -8px"
      >
        <search
          v-model="searchText"
          :placeholder="$t('search')"
          :label="false"
          variant="outlined"
          density="compact"
          class="border-primary no-validation w-100"
          autocomplete="off"
          data-lpignore="true"
          type="search"
          autofocus
          hide-details
          v-bind="searchAtts"
        />
        <v-divider class="mt-2 mb-0 bg-grey"></v-divider>
      </div>
    </template>
  </v-select>
</template>

<script setup>
const attrs = useAttrs();
const slots = useSlots();

const props = defineProps({
  modelValue: {
    type: [String, Object, Array],
    default: () => [],
  },
  items: {
    type: Array,
    default: () => [],
  },
  rules: {
    type: Array,
    default: () => [],
  },
  searchFields: {
    type: Array,
    default: () => [],
  },
  searchAtts: {
    type: Object,
    default: null,
  },
});
const emit = defineEmits(["update:modelValue"]);

let searchText = ref("");

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const menuChange = async (state) => {
  if (state) {
    searchText.value = "";
  }
};

const filtered = computed(() => {
  const title = attrs["item-title"] || "title";
  let items =
    props.items[0] && typeof props.items[0] !== "string"
      ? props.items
      : props.items.map((a) => {
          return {
            title: a,
            value: a,
          };
        });
  return searchText.value
    ? items.filter((a) => {
        const searchVal = props.searchFields.length
          ? $concatVals([a[title], ...props.searchFields.map((b) => a[b])])
          : a[title];
        return searchVal.toLowerCase().includes(searchText.value.toLowerCase());
      })
    : items;
});
</script>
