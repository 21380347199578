export default class Exporter {
  constructor(account, allow, auth, format, name, tags, uuid) {
    this.account = account;
    this.allow = allow || "strict";
    this.auth = auth;
    this.format = format;
    this.name = name;
    this.tags = tags || [];
    this.uuid = uuid;
  }
}
