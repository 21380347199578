export default {
  path: "/calls/time",
  name: "time-and-day",
  components: {
    mobile: () => import("Layouts/menus/mobile/Default"),
    sidebar: () => import("Layouts/menus/sidebar/Default"),
    header: () => import("Components/headers/Default.vue"),
    contentHeader: () => import("Components/contentHeaders/Filters.vue"),
    footer: () => import("Components/footers/Default.vue"),
    main: () => import("Views/reports/TimeAndDay.vue"),
  },
  meta: {
    filters: true,
    auth: true,
    editable: [],
    readable: [],
    guarded: false,
  },
};
