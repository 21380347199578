export default class User {
  constructor(
    id,
    account,
    acl,
    allow,
    block,
    email,
    firstName,
    lastName,
    level,
    mobile,
    sip,
    status,
    timezone,
    username,
  ) {
    this.id = id;
    this.account = account || "";
    this.acl = acl || [];
    this.allow = allow || [];
    this.block = block;
    this.email = email || "";
    this.firstName = firstName || "";
    this.lastName = lastName || "";
    this.level = level || 0;
    this.mobile = mobile || "";
    this.sip = sip || "";
    this.status = status;
    this.timezone = timezone;
    this.username = username || "";
  }

  getFullName() {
    return this.firstName + (this.lastName ? " " + this.lastName : "");
  }
}
