import AppConfig from "Constants/AppConfig";
import REQ from "Api/Requests";

import User from "Models/users/User";
import Profile from "Models/users/Profile";

export default {
  async getMyProfile() {
    try {
      const response = await REQ.get("user/me");
      return Object.assign(new Profile(), response.data);
    } catch (err) {
      throw err;
    }
  },

  async editMyProfile(payload) {
    try {
      const response = await REQ.post("user/me", payload);
      return Object.assign(new Profile(), response.data);
    } catch (err) {
      throw err;
    }
  },

  async getUsers(params) {
    try {
      const path = REQ.setPath("user", params, ["accounts"]);
      const response = await REQ.get(path);
      return response.data.map((a) => Object.assign(new User(), a));
    } catch (err) {
      throw err;
    }
  },

  user: {
    async get(email) {
      try {
        const path = REQ.setPath("user/" + email);
        const response = await REQ.get(path);
        return Object.assign(new User(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async create(payload) {
      try {
        const path = REQ.setPath("user");
        const response = await REQ.put(path, payload);
        return Object.assign(new User(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async edit(payload) {
      try {
        const path = REQ.setPath("user/" + payload.email);
        const response = await REQ.post(path, payload);
        return Object.assign(new User(), response.data);
      } catch (err) {
        throw err;
      }
    },

    async patch(payload, email) {
      try {
        const path = REQ.setPath("user/" + email);
        const response = await REQ.patch(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async delete(email) {
      try {
        const path = REQ.setPath("user/" + email);
        const response = await REQ.delete(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },

  reports: {
    async logins(params) {
      try {
        const path = REQ.setPath("report/login", params);
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async scheduled(params) {
      try {
        const path = REQ.setPath("report/scheduled", null, ["accounts"]);
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async schedule(id) {
      try {
        const path = REQ.setPath("report/scheduled/" + id);
        const response = await REQ.get(path);
        return response.data;
      } catch (err) {
        throw err;
      }
    },

    async editSchedule(payload) {
      try {
        const path = REQ.setPath("report/scheduled/" + payload.account);
        const response = await REQ.post(path, payload);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  },
};
