import AppConfig from "Constants/AppConfig";
import REQ from "Api/Requests";

import Account from "Models/Account";

export default {
  async getAccounts() {
    try {
      const path = REQ.setPath("account/tree");
      const response = await REQ.get(path);
      return response.data.map((a) => Object.assign(new Account(), a));
    } catch (err) {
      throw err;
    }
  },
};
